import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import firebase from "../../config"
import ItemList from "./itemlist";
import AddItemForm from "./additemform";
import UpdateItem from "./updateitem";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserVneck } from '@fortawesome/pro-solid-svg-icons'

export default () => {

  // document.body.classList.remove('web-app');
  
  if (firebase.auth().currentUser === null) {
    return <Redirect to="/" />;
  }
  
  const initialItemState = [
    {  }
  ];

  const [editing, setEditing] = useState(false);
  const [editingWeight, setWeight] = useState(false);
  const [currentItem, setCurrentItem] = useState(initialItemState);

  const editWeight = (item) => {
    setWeight(true);
    setEditing(false);
    setCurrentItem({
      id: item.id,
      Travellers : item.Travellers,
    });
  }

  const editItem = item => {
    setEditing(true);
    setWeight(false);
    setCurrentItem({
      id: item.id,
      Travellers : item.Travellers,
      WeightPref : item.WeightPref,
    });
  };

  const updateItem = ({ currentItem }, updatedItem) => {
    setEditing(false);
    firebase
      .firestore()
      .collection("user")
      .doc(currentItem.id)
      .update(updatedItem);
  };

  const user = firebase.auth().currentUser;

  const [dirty, setDirty] = useState(false);

  return (
    <>
    <div className="update-title-bar update-title-bar-nw">
      <a href="/user/index"><h1><FontAwesomeIcon style={{color:'#fff'}} icon={faUserVneck} size="lg" />PROFILE</h1></a>
      <span className="goright">VERSION 1.45</span>
    </div>
    <div className="wrapper">
      {editing || editingWeight ? (
        <UpdateItem
          setEditing={setEditing}
          setWeight={setWeight}
          editing={editing}
          editingWeight={editingWeight}
          currentItem={currentItem}
          updateItem={updateItem}
          dirty={dirty}
          setDirty={setDirty}
        />
      ) : (
        <div className="trip-add">
        <AddItemForm setDirty={setDirty} dirty={dirty} />
        </div>
      )}
    </div>
    </>
  );
};