import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import phone from '../app_phone_home.png';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { Purchases, LOG_LEVEL } from '@revenuecat/purchases-capacitor';

import subimage from '../drone.jpg';

const ANDROID: React.FC = () => {

  let history = useHistory();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.body.className = 'rc-subscribe';
  }, []);

  const [productselect, setProductSelect] = useState(null);

  const purchaseStoreProductA = async () => {
    const offerings = await Purchases.getOfferings();
    const productToBuy = offerings.current
      && offerings.current.availablePackages
      && offerings.current.availablePackages[0].product;
      
      if (productToBuy == null) {
        alert('No product');
        return
      };

      setProductSelect(productToBuy);
  }

  const purchaseStoreProductB = async () => {
    const offerings = await Purchases.getOfferings();
    const productToBuy = offerings.current
      && offerings.current.availablePackages
      && offerings.current.availablePackages[2].product;
      
      if (productToBuy == null) {
        alert('No product');
        return
      };

      setProductSelect(productToBuy);
  }

  const purchasebutton = async () => {
    setLoading(true);
    try {
      const purchaseResult = await Purchases.purchaseStoreProduct({
        product: productselect,
        });
        if (typeof purchaseResult.customerInfo.entitlements.active['subscriber'] !== "undefined") {
          alert('Thank you for subscribing. You will now be redirected to set your email and password.')
          if (productselect?.identifier == 'tpmonthly') {
            history.push("/android-signup/?duration=monthly")
          }
          if (productselect?.identifier == 'tpannual') {
            history.push("/android-signup/?duration=annually")
          }
          
        }
        // purchaseResult ? alert('Thank you for subscribing. You will now be redirected to set your email and password.') + history.push("/ios-signup/?duration=monthly") : setLoading(false);
    }
    catch (error) {
        setLoading(false);
    }
  }

  

  const purchaseStoreProductC = async () => {
    setLoading(true);
    const offerings = await Purchases.getOfferings();
    const productToBuy = offerings.current
      && offerings.current.availablePackages
      && offerings.current.availablePackages[2].product;
      if (productToBuy == null) {
        alert('No product');
        return
      };

      try {
        const purchaseResult = await Purchases.purchaseStoreProduct({
          product: productToBuy,
          });
          if (typeof purchaseResult.customerInfo.entitlements.active['subscriber'] !== "undefined") {
            alert('Thank you for subscribing. You will now be redirected to set your email and password.')
            history.push("/ios-signup/?duration=annually")
          }
          // purchaseResult ? alert('Thank you for subscribing. You will now be redirected to set your email and password.') + history.push("/ios-signup/?duration=monthly") : setLoading(false);
      }
      catch (error) {
          setLoading(false);
      }
  }


  return (
  <>
  <div className="subscribe-wrapper new-subscribe-wrapper">
  <div className="wrapper">
  <img style={{height: '50vw', objectFit: 'cover'}} src={subimage} />
  <h2 style={{marginTop:'10px', marginBottom:'10px', display:'block', fontSize:'7vw'}}>Plan unforgettable trips!</h2>
  <p>Prepare your gear, document your bucket list, simplify packing lists and plan to make every one of your trips a success.</p>
  <FormControl>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue="female"
        name="radio-buttons-group"
      >
        <FormControlLabel onClick={purchaseStoreProductA} value="monthly" control={<Radio />} label="Monthly $4.99" />
        <FormControlLabel onClick={purchaseStoreProductB} value="annually" control={<Radio />} label="Annually $39.99"></FormControlLabel>
        <span className="save">SAVE 33%</span>
      </RadioGroup>
    </FormControl>
    <Button size="small" className="loginlink" href="/login" style={{color:'#fff',borderColor:'#fff', justifyContent:'flex-start'}}>Existing subscriber login</Button>
  </div></div>
  <div className="sub-fixed-btn">
  {!loading ? <>
  <Button onClick={purchasebutton} size="large" className="subscribebtn">Start a 14-day free trial</Button>
  </> : <> <Button disabled size="large" className="subscribebtn"><CircularProgress style={{width:'15px',height:'15px'}} color="success" /> Please wait</Button></>}
  <a href="https://adventurecurated.com/policies/terms-of-service">Terms</a> • <a href="https://adventurecurated.com/policies/privacy-policy">Privacy</a>
  </div>
  </>
  )
};

export default ANDROID;