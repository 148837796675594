import React, { useMemo, useState, useEffect, useRef, Fragment } from "react";
import Select, { components } from 'react-select';
import firebase from "../../config"
import { storage } from "../../config";
import Linkify from 'react-linkify';
/* import PDF from '../pdf/index' */
import { format } from 'date-fns'
import data_meals from '../../trip_cats.json';
import Weights from './weights'
import FWeight from './Fweight'
import HWeight from './Hweight'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';

import TextField from '@material-ui/core/TextField';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TextFields from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SaveAltTwoToneIcon from '@material-ui/icons/SaveAltTwoTone';
import AddCircle from '@material-ui/icons/AddCircle';
import NotListedLocationIcon from '@material-ui/icons/Help';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import TextareaAutosize from '@mui/base/TextareaAutosize';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import DatePicker from '../DatePicker/DatePicker';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import jsPDF from "jspdf";
import autoTable from 'jspdf-autotable';

import { isPlatform } from "@ionic/react";
import { FileOpener } from '@capacitor-community/file-opener';
import { File } from '@awesome-cordova-plugins/file';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { confirm } from "react-confirm-box";

import { buttonBaseClasses } from "@mui/material";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUtensils } from '@fortawesome/free-solid-svg-icons'
import { faAppleAlt } from '@fortawesome/free-solid-svg-icons'
import { faRoute } from '@fortawesome/free-solid-svg-icons'
import { faCampground } from '@fortawesome/free-solid-svg-icons'
import { faTruckMonster, faGauge, faListCheck } from '@fortawesome/pro-solid-svg-icons'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faSuitcase, faPencil } from '@fortawesome/pro-solid-svg-icons'
import { faSackDollar } from '@fortawesome/pro-solid-svg-icons'
import { faNotebook } from '@fortawesome/pro-solid-svg-icons'
import { faWeightScale } from '@fortawesome/pro-solid-svg-icons'
import { faGaugeCircleBolt } from '@fortawesome/pro-solid-svg-icons'
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons'

import Zoom from '@mui/material/Zoom';

const useItemsUser = () => {
  const user = firebase.auth().currentUser;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("user") //access "items" collection
      .where('id', '==', `${user.uid}`)
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItemsUsers = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          ...doc.data() //spread operator merges data to id.
        }));
        setItems(listItemsUsers); //items is equal to listItems
      });
    return () => unsubscribe();
  }, []);
  return items;
};



const useItems = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("gear") //access "items" collection
      .where("User", "==", user1)
      .orderBy("SubCategory", "asc")
      .orderBy("Brand", "asc")
      .orderBy("Name", "asc")
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItems = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          Name: doc.data().Name,
          Weight: doc.data().Weight,
          Category: doc.data().Category,
          CategoryId: doc.data().CategoryId,
          SubCategory: doc.data().SubCategory,
          SubCategoryId: doc.data().SubCategoryId,
          Brand: doc.data().Brand,
          value: doc.data().Name.replace(/( )/g, ''),
          ...doc.data()
        }));
        setItems(listItems); //items is equal to listItems
      });
  }, []);
  return items;
};

const useItemsFood = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("food") //access "items" collection
      .where("User", "==", user1)
      .orderBy("Name", "asc")
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItems = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          Name: doc.data().Name,
          Weight: doc.data().Weight,
          Category: doc.data().Category,
          value: doc.data().Name.replace(/( )/g, '')
        }));
        setItems(listItems); //items is equal to listItems
      });
  }, []);
  return items;
};

const useItemsMeals = () => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  const [items, setItems] = useState([]); //useState() hook, sets initial state to an empty array
  useEffect(() => {
    const unsubscribe = firebase
      .firestore() //access firestore
      .collection("meals") //access "items" collection
      .where("User", "==", user1)
      .orderBy("CategoryValue", "asc")
      .orderBy("Name", "asc")
      .onSnapshot(snapshot => {
        //You can "listen" to a document with the onSnapshot() method.
        const listItems = snapshot.docs.map(doc => ({
          //map each document into snapshot
          id: doc.id, //id and data pushed into items array
          Name: doc.data().Name,
          Weight: doc.data().Weight,
          Category: doc.data().Category,
          Quantity: doc.data().Quantity,
          value: doc.data().Name.replace(/( )/g, ''),
          ...doc.data()
        }));
        setItems(listItems); //items is equal to listItems
      });
  }, []);
  return items;
};

function usePersistentObject(init, deps) {
  const obj = useRef(null);
  const prevDeps = useRef(deps || []);

  if (obj.current === null || !(prevDeps.current.length === deps.length && prevDeps.current.every((v, i) => deps[i] === v))) { // rough shallow equals, could use a library
    // either first render or deps array values changed
    obj.current = init();    // compute
    prevDeps.current = deps; // save dependency state
  }
  return obj.current;


}

const AddItemFormTrips = ({ setAddItem, addItem, expanded, dirty, setDirty }) => {
  const user = firebase.auth().currentUser;
  const user1 = user.uid;
  
  //useState() hook captures the value from the input value
  const [name, setName] = useState("")
  const [addtraivler, setaddTraverler] = useState([])
  const [datefrom, setDateFrom] = useState(null)
  const [dateto, setDateTo] = useState(null)
  const [notes, setNotes] = useState("")
  const [document, setDocument] = useState([])
  const [budgetkm, setBudgetKM] = useState("0")
  const [noofdays, setnoofdays] = useState(0)
  const [budgetl100, setBudgetL100KM] = useState("0")
  const [budgetmpg, setBudgetMPG] = useState("0")
  const [budgetfuel, setBudgetFuel] = useState("0")
  const [budgetcamp, setBudgetCamp] = useState("0")
  const [budgetaccommodation, setBudgetAccommodation] = useState("0")
  const [budgetfood, setBudgetFood] = useState("0")
  const [budgeteatingout, setBudgetEatingOut] = useState("0")
  const [budgettours, setBudgetTours] = useState("0")
  const [budgetadditional, setBudgetAdditional] = useState("0")
  const [budgetairfares, setBudgetAirfares] = useState("0")
  const [budgethirecar, setBudgetHireCar] = useState("0")
  const [gearcat, setGearCat] = useState("")
  const [gearsubcat, setGearSubCat] = useState("")
  const [country, setCountry] = useState(null);
  const [togle, settogle] = useState(false)
  const [lang, setLang] = useState(null);
  const [langList, setLangList] = useState([]);
  const [addedfood, setAddFood] = useState([]);
  const [addedmeals, setAddMeals] = useState([]);
  const [SearchOwner, setSearchOwner] = useState({ Name: "ANY" })
  const [allImages, setImages] = useState([]);
  const [inputFields, setInputFields] = useState([
    { Date: '', From: '', To: '', Distance: '', Stay: '', Notes: '', Document: [] }
  ]);


  const dataT = useItemsUser()
  const newata = dataT[0]
  const getdataT = dataT.length ? dataT[0] : []

  useEffect(() => {
    setImages(allImages?.reduce((acc, current) => {
      const x = acc.find(item => item.Test2 === current.Test2);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []))
  }, [allImages.length])
  const [url, seturl] = useState(null)
  const [togles, setTogles] = useState(false)
  const openImage = (image) => {
    seturl(image)
    setTogles(!togles)
  }

  const ref = usePersistentObject(
    () => firebase.firestore().collection('trips').doc(),
    [name] // no dependencies, don't recompute
  );

  const tripid = ref.id;

  // handle change event of the country dropdown
  const handleCountryChange = (obj) => {
    setDirty(true);
    // bottomnavtarget.classList.add('cf');
    setCountry(obj);
    setLangList(obj.subCategory);
    setLang(null);
    setGearCat(obj.label);
  };
  const [typeValue, setTypeValue] = useState([])
  // handle change event of the language dropdown
  const handleLanguageChange = (obj) => {
    setLang(obj);
    setGearSubCat(obj.label);
  };

  /* The onSubmit function we takes the 'e' 
    or event and submits it to Firebase
    */
  const onSubmit = e => {
    /* 
    preventDefault is important because it 
    prevents the whole page from reloading
  
    */
    e.preventDefault();
    const date = format(new Date(), 'MM/dd/yyyy')
    const fooddata = foodValue.map((el, i) => {
      if (FoodWeight[i].id == el.id)
        return {
          ...el,
          Quantity: FoodWeight[i].Quantity
        }
    })

    firebase
      .firestore()
      .collection("trips")
      .doc(tripid)
      .set({
        Name: name,
        id: tripid,
        noofdays,
        Bucket: bucket,
        DateFrom: firebase.firestore.Timestamp.fromDate(new Date(datefrom)),
        DateTo: firebase.firestore.Timestamp.fromDate(new Date(dateto)),
        Category: gearcat,
        Notes: notes,
        Document: allImages,
        Budget_KM: budgetkm,
        Budget_L100KM: budgetl100,
        Budget_MPG: budgetmpg,
        Budget_FuelPrice: budgetfuel,
        Budget_CampingFees: budgetcamp,
        Budget_Accommodation: budgetaccommodation,
        Budget_FoodShopping: budgetfood,
        Budget_EatingOut: budgeteatingout,
        Travelers: addtraivler,
        Budget_Tours: budgettours,
        Budget_Additional: budgetadditional,
        Budget_Airfares: budgetairfares,
        Budget_HireCar: budgethirecar,
        Itinerary: inputFields,
        Gear: typeValue,
        GearWeight: totalAmount,
        Food: foodValue,
        FoodWeight: totalAmountF,
        Meals: mealsValue,
        MealWeight: totalAmountM,
        User: user1,
      })
      //.then will reset the form to nothing
      .then(() => setAddItem(!addItem), setName(""), setCountry(null), setTypeValue([]), setLang(null))
      .finally(() => window.location.reload())
      setAddItem(!addItem)
  };

  const style = {
    control: base => ({
      ...base,
      border: 0,
      borderRadius: 0,
      // This line disable the blue border
      boxShadow: "none",
      margin: 0,
      padding: 0,
      zIndex: 9999,
    }),
    valueContainer: (provided, state) => ({
      padding: 0,

    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
  }


  const state = {
    options: [],
  }



  const { options } = state;
  const values = state;

  const [selectedTab, setSelectedTab] = useState(0);

  // Function called anytime the tab changes
  function handleTabChange(event, value) {
    setSelectedTab(value);
  }


  const getFromFirebase = () => {
    setImages((allImages) => []);
    //1.
    let storageRef = storage.ref(`${user1}/${tripid}/documents`);
    //2.
    storageRef.listAll().then(function (res) {
      //3.
      res.items.forEach((imageRef) => {
        imageRef.getDownloadURL().then((url) => {
          //4.
          if (allImages.indexOf(url) === -1) {
            setImages((allImages) => [...allImages, ({ Test: url, Test2: imageRef.name })]);
          }
        });
      });

    })
      .catch(function (error) {
        console.log(error);
      });
  };
  
  const deleteFromFirebase = (url) => {
    //1.
    let pictureRef = storage.refFromURL(url);
    //2.
    pictureRef.delete()
      .then(() => {
        //3.
        setImages(allImages.filter((image) => image.Test !== url));
        alert("File has been removed");
        getFromFirebase()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [image, setImage] = useState(null);
  const onImageChange = (e) => {
    const reader = new FileReader();
    let file = e.target.files[0]; // get the supplied file
    // if there is a file, set image to that file
    if (file) {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImage(file);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
      // if there is no file, set image back to null
    } else {
      setImage(null);
    }
  };

  const [uploadingImage, setUploadingImage] = useState(true);

  const uploadToFirebase = e => {
    e.preventDefault();
    //1.
    if (image) {
      //2.
      const storageRef = storage.ref(`${user1}/${tripid}/documents`);
      //3.
      const imageRef = storageRef.child(image.name);
      //4.
      setUploadingImage(true)
      imageRef.put(image)
        //5.
        .then((url) => {
          alert("Successfully uploaded.");
          getFromFirebase();
          setUploadingImage(false)
        })
        .catch(e => setUploadingImage(false));
    } else {
      alert("Please upload an image first.");
    }
  };

  

  const handleAddFields = () => {
    const values = [...inputFields];
    values.push({ Date: '', From: '', To: '', Distance: '', Stay: '', Notes: '', Activities: '', Document: [] });
    setInputFields(values);
  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
  };

  const handleInputChange = (index, event, newValue) => {
    const values = [...inputFields];
    if (newValue) {
      values[index].Date = format(newValue, 'MM/dd/yyyy');
    }
    if (event.target.name === "From") {
      values[index].From = event.target.value;
    }
    if (event.target.name === "To") {
      values[index].To = event.target.value;
    }
    if (event.target.name === "Distance") {
      values[index].Distance = event.target.value;
    }
    if (event.target.name === "Stay") {
      values[index].Stay = event.target.value;
    }
    if (event.target.name === "Notes") {
      values[index].Notes = event.target.value;
    }
    if (event.target.name === "Activities") {
      values[index].Activities = event.target.value;
    }
    if (event.target.name === "Document") {
      var file = event.target.files[0]; // get the supplied file
      const handleFireBaseUpload = () => {
        // async magic goes here...
        if (file === null) {
          console.error(`not an image, the image file is a`)
        }
        const uploadTask = storage.ref(`${user1}/${inputFields?.Date}/${file.name}`).put(file)
        setUploadingImage(false)
        //initiates the firebase side uploading 
        uploadTask.on('state_changed',
          (snapShot) => {
            //takes a snap shot of the process as it is happening
          }, (err) => {
            //catches the errors
            console.log(err)
          }, () => {
            console.log(uploadingImage)
            // gets the functions from storage refences the image storage in firebase by the children
            // gets the download url then sets the image from firebase as the value for the imgUrl key:
            storage.ref(`${user1}/${inputFields.Date}`).child(file.name).getDownloadURL()
              .then(fireBaseUrl => {
                values[index].Document.push({
                  url: fireBaseUrl,
                  name: file.name
                });
                setUploadingImage(true)
              })
          })
      }
      handleFireBaseUpload()
    }
    setInputFields(values);
  };

  
  const [totalAmount, setTotalAmount] = useState(0)

  const [totalAmountF, setTotalAmountF] = useState(0)

  const [totalAmountM, setTotalAmountM] = useState(0)

  const handleCheckboxFoodChange = (index, event) => {
    const addedfood1 = [...addedfood];
    addedfood1.Name = event.target.name;
    addedfood1.Weight = event.target.value;
    addedfood1.push({ Category: event.target.id, Name: event.target.name, Weight: event.target.value });
    setAddFood(addedfood1);
    setTotalAmountF((totalAmountF) => {
      if (event.target.checked) {
        totalAmountF = totalAmountF + parseFloat(event.target.value);
        return totalAmountF
      } else {
        totalAmountF = totalAmountF - parseFloat(event.target.value);
        return totalAmountF
      }
    });
  }

  const handleCheckboxMealsChange = (index, event) => {
    const addedmeals1 = [...addedmeals];
    addedmeals1.Name = event.target.name;
    addedmeals1.push({ Category: event.target.id, Name: event.target.name });
    setAddMeals(addedmeals1);
  }

  const listItem = useItems();

  const listItemFood = useItemsFood();

  const listItemMeals = useItemsMeals();

  const [checkedCheckboxes, setCheckedCheckboxes] = useState([]);

  const handleCheckboxChangeM = (checkbox, index) => {
    const addedmeals1 = [...addedmeals];
    addedmeals.Name = checkbox.Name;
    const isChecked = checkedCheckboxes.some(checkedCheckbox => checkedCheckbox.value === checkbox.value)
    if (isChecked) {
      setCheckedCheckboxes(
        checkedCheckboxes.filter(
          (checkedCheckbox) => checkedCheckbox.value !== checkbox.value
        )
      );
    } else {
      setCheckedCheckboxes(checkedCheckboxes.concat(checkbox));
      addedmeals1.push({ Category: checkbox.Category, Name: checkbox.Name });
    }
    setAddMeals(addedmeals1);
  };

  const [bucket, setBucket] = useState(false);
  const [packeditem, setpackeditem] = useState(false);
  const [packeditem2, setpackeditem2] = useState(false);
  const [packeditem3, setpackeditem3] = useState(false);

  const handleChange = (event) => {
    setBucket(event.target.checked);
  };
  const handleChange2 = (event) => {
    setpackeditem(event.target.checked);
  };
  const handleChange21 = (event) => {
    setpackeditem2(event.target.checked);
  };
  const handleChange22 = (event) => {
    setpackeditem3(event.target.checked);
  };

  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, child =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };


  const handleChangeA = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setAddItem(!addItem);
  };

  var getDaysArray = function (s, e) { for (var a = [], d = new Date(s); d <= e; d.setDate(d.getDate() + 1)) { a.push(new Date(d)); } return a; };
  
  var daylist = getDaysArray(new Date(datefrom), new Date(dateto));

  const [saveState, setSaveState] = useState(false)

  const optionsWithLabelChange = {
    closeOnOverlayClick: false,
    labels: {
      confirmable: "Automatic",
      cancellable: "Manual"
    }
  };

  const populateItinerary = async (options) => {
    { /* const result = await confirm("If choosing Automatic the itinerary will be recreated to suit your new dates and all existing data will be cleared. When selecting Manual, data will be retained but all dates will need to be adjusted manually.", optionsWithLabelChange); */ }
    { /* if (result) { */ }
      const thesedays = daylist.map(v => new Date(Date.parse(new Intl.DateTimeFormat("fr-CA", {year: "numeric", month: "2-digit", day: "2-digit"}).format(v))).toISOString().slice(0,10));
      const values = [];
      for (var i = 0; i < thesedays.length; i++) {
        values.push({ Date: thesedays[i], From: '', To: '', Distance: '', Stay: '', Notes: '', Activities: '', Document: [] });
      }
      setInputFields(values);
  }
  const [che, setche] = useState(0)

  const CombineeItinerary = () => {
    if (togle == false) {
      const thesedays = daylist.map(v => new Date(Date.parse(new Intl.DateTimeFormat("fr-CA", {year: "numeric", month: "2-digit", day: "2-digit"}).format(v))).toISOString().slice(0,10));
      if (saveState == false) {
        const values = [];
        for (var i = 0; i < thesedays.length; i++) {
          values.push({ Date: thesedays[i], From: '', To: '', Distance: '', Stay: '', Notes: '', Activities: '', Document: [] });
        }
        setInputFields(values);
      }
    } else {
      console.log("null")
    }
  }

  useEffect(() => {
    CombineeItinerary()
    setche(che + 1)
    if (che > 2) {
      populateItinerary()
    }
  }, [dateto,datefrom])

  var date = new Date();

  useEffect(() => {
    setDateFrom(date.setDate(date.getDate() + 1))
    setDateTo(date.setDate(date.getDate() + 6))
  }, [])

  const [prevState, setTypeCheckboxCount] = useState();
  const [foodValue, setFoodValue] = useState([])
  const [mealsValue, setMealsValue] = useState([])

  const [getid, setid] = useState([])

  const [meaweig, setmealweightsss] = useState([])

  const gecheckid = (ev, id, item) => {
    const getids = [...getid]
    const newdataput = [...meaweig]
    if (ev.target.checked) {
      const filter = mealsValue.filter(el => el.id == id ? true : false)
      const filtersaa = mealsValue.filter(el => el.id != id ? true : false)
      let olddata;
      filter.map(el => {
        olddata = el
      })
      if (filter.length) {
        setMealsValue([...filtersaa, { id, ...item, Quantity: "1", Weight: "0" }])
      } else {
        setMealsValue([...filtersaa, { id, ...item, Quantity: "1", Weight: "0" }])
      }

      getids.push(id)
      let unique = [...new Set(getids)];
      setid(unique)
      newdataput.push({ id: item.id, Weight: item.Foods, Quantity: '1', Name: item.Name })
      let uniques = [...new Set(newdataput)];
      setmealweightsss(uniques)
    } else {
      const filterweoght = meaweig.filter(el => el.id != id ? true : false)
      setmealweightsss(filterweoght)
      const filter = getids.filter(el => el != id ? true : false)
      setid(filter)
      const mealfil = mealsValue.filter(el => el.id != id)
      setMealsValue(mealfil)
    }
  }


  const [selectGearCategory, setSelectGearCategory] = useState({ Category: "ANY" })
  const handleSelectChange = (e) => {
    setSelectGearCategory(e)
  }
  const [Foodpacking, setfoodpacking] = useState({ status: "ALL" })
  const [Foodpackings, setfoodpackings] = useState({ status: "ALL" })
  const [Foodpackingss, setfoodpackingss] = useState({ status: "ALL" })
  const foodpackingLocationtwoingTower = [
    { status: "ALL" },
    { status: "4x4" },
    { status: "Boat" }
  ]
  const foodpackingLocationtwoingTower2 = [
    { status: "ALL" },
    { status: "Base Weight" },
    { status: "Consumables" },
    { status: "Wear" },
    { status: "Travel Bag" }
  ]
  const foodpackingLocationtwoingTower3 = [
    { status: "ALL" },
    { status: "4x4" },
    { status: "Camper" }
  ]
  const foodpackingLocationtwoingTower4 = [
    { status: "ALL" },
    { status: "4x4" },
    { status: "Caravan" }
  ]
  const foodpackingLocationtwoingTower5 = [
    { status: "ALL" },
    { status: "Carry On" },
    { status: "Wear" },
    { status: "Bag 1" },
    { status: "Bag 2" },
    { status: "Bag 3" },
    { status: "Bag 4" },
    // { status: "Bag 5" },
  ]
  const slectdata = country?.label == "4x4 TOWING BOAT" ? foodpackingLocationtwoingTower : country?.label == "HIKING" || country?.label == "BIKEPACKING" ? foodpackingLocationtwoingTower2 : country?.label == "4x4 TOWING CAMPER" ? foodpackingLocationtwoingTower3 : country?.label == "4x4 TOWING CARAVAN" ? foodpackingLocationtwoingTower4 : country?.label == "AIR +" ? foodpackingLocationtwoingTower5 : [];
  // Meal Gear Component
  const listItemUser = useItemsUser();
  const tr = addtraivler

  const newarrtr = [...tr];
  newarrtr.push({ Name: "ANY" })
  const awawa = listItem.filter(el => {
    if(el.Owner == SearchOwner.Name || SearchOwner.Name == "ANY" ){
      return true
    }else{
      return false
    }
  })
  const newdataarr = []
 listItem.filter(el => {
    return typeValue.map(e => {
      if(e.id == el.id &&  Foodpackings.status == e.packingLocation){
        newdataarr.push(el)
        return el
      }else if(el.Owner == SearchOwner.Name){
        newdataarr.push(el)
        return el
      }
    })
  })
  const fooaw = Foodpackings.status == "ALL" ? awawa : newdataarr;
  const [owne, setowne] = useState({ Name: "ANY" })

  const newdataarr2 = []
  listItem.filter(el => {
    return typeValue.map(e => {
      e?.gearHiking?.map(item => {
        if(e.id == el.id &&  Foodpackings.status == item.packingLocation){
          newdataarr2.push(el)
          return el
        }else if(el.Owner == SearchOwner.Name){
          newdataarr2.push(el)
          return el
        }else if(e.id == el.id &&  owne.Name == item.Name){
          newdataarr2.push(el)
        }
      })
    })
  })
  const fooaw2 = Foodpackings.status == "ALL" && owne.Name == "ANY"  ? awawa : newdataarr2;

  const awawameals = listItemMeals.filter(el => {
    if(el.Owner == SearchOwner.Name || SearchOwner.Name == "ANY" ){
      return true
    }else{
      return false
    }
  })

  const categorizedDataMeals = awawameals.reduce((acc, curr) => {
    const { CategoryValue, id, Category, Name, Foods } = curr;
    if (!acc[CategoryValue]) {
      acc[CategoryValue] = {
        items: [],
      };
    }
    acc[CategoryValue].items.push({ CategoryValue, Category, id, Name, Foods });
    return acc;
  }, {});

  /* FOOD */

  const awawafoods = listItemFood.filter(el => {
    if(el.Owner == SearchOwner.Name || SearchOwner.Name == "ANY" ){
      return true
    }else{
      return false
    }
  })

  const categorizedDataFoods = awawafoods.reduce((acc, curr) => {
    const { id, Category, Name, Quantity, Weight, packingLocation, bought, packed } = curr;
    if (!acc[Category]) {
      acc[Category] = {
        items: [],
      };
    }
    acc[Category].items.push({ Category, Name, Quantity, Weight, packingLocation, bought, packed, id });
    return acc;
  }, {});


  const getbreakfast = awawafoods.filter(el => el.Category == "BREAKFAST" ? true : false)
  const getlinch = awawafoods.filter(el => el.Category == "LUNCH" ? true : false)
  const getDinner = awawafoods.filter(el => el.Category == "DINNER" ? true : false)
  const getSnack = awawafoods.filter(el => el.Category == "SNACKS" ? true : false)
  const getpantry = awawafoods.filter(el => el.Category == "PANTRY" ? true : false)
  const getDrinks = awawafoods.filter(el => el.Category == "COOL DRINKS" ? true : false)
  const gethot = awawafoods.filter(el => el.Category == "HOT DRINKS" ? true : false)
  const getalcholo = awawafoods.filter(el => el.Category == "ALCOHOL" ? true : false)
  const getblank = awawafoods.filter(el => el.Category == "" | el.Category == null ? true : false)

  const categorizedDatasG2 = typeValue.reduce((acc, curr) => {
    const { id, Category, CategoryId, Quantity, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, packingLocation } = curr;
    if (!acc[packingLocation]) {
      acc[packingLocation] = {
        items: [],
      };
    }
    acc[packingLocation].items.push({ Category, CategoryId, Quantity, packingLocation, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  const categorizedDataFoods1 = foodValue.reduce((acc, curr) => {
    const { id, Category, Name, Quantity, Weight, packingLocation, bought, packed } = curr;
    if (!acc[Category]) {
      acc[Category] = {
        items: [],
      };
    }
    acc[Category].items.push({ Category, Name, Quantity, Weight, packingLocation, bought, packed, id });
    return acc;
  }, {});

  const categorizedDataFoods2 = foodValue.reduce((acc, curr) => {
    const { id, Category, Name, Quantity, Weight, packingLocation, bought, packed } = curr;
    if (!acc[packingLocation]) {
      acc[packingLocation] = {
        items: [],
      };
    }
    acc[packingLocation].items.push({ Category, Name, Quantity, Weight, packingLocation, bought, packed, id });
    return acc;
  }, {});
 
  const categorizedData = awawa.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});


  const getCategory = (key) => {
    const foundCategorykey = categorizedData[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

const getCategorySort = (key) => {
  const foundCategorykey = categorizedData[key].items[0].CategoryId
  const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
  if (foundCategory) {
  return foundCategory.catid
  } else {
    return '-'
  }
}

  const categorizedDatadatwa = fooaw?.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

    const getCategoryAlt2 = (key) => {
    const foundCategorykey = categorizedDatadatwa[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

  const categorizedDatadatwa2 = fooaw2?.reduce((acc, curr) => {
    const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  const getCategoryAlt = (key) => {
    const foundCategorykey = categorizedDatadatwa2[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

  const checkboxGearFunctionRender = () => {
    if (country?.label == "HIKING" || country?.label == "BIKEPACKING") {
      return (
        <>
          {Object.keys(categorizedDatadatwa2).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa2[key].items.map((checkbox, index) => {
                      const category = typeValue.filter(el => el.id == checkbox.id ? true : false)
                      const boat = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Base Weight" ? true : false)
                      const da4x4 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Consumables" ? true : false)
                      const da4x4x = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Wear" ? true : false)
                      const da4x4xx = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Travel Bag" ? true : false)
                      const travelers = typeValue.filter(el => el.id == checkbox.id && el?.gearHiking?.length && el?.gearHiking[0]?.Name == owne.Name ? true : false)
                      const travelers2 = typeValue.filter(el => el.id == checkbox.id && el?.gearHiking?.length && el?.gearHiking[1]?.Name == owne.Name ? true : false)
                      const boat2 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Base Weight" && el.Owner == owne.Name ? true : false)
                      const da4x42 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Consumables" && el.Owner == owne.Name ? true : false)
                      const da4x4x2 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Wear" && el.Owner == owne.Name ? true : false)
                      const da4x4xx2 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Travel Bag" && el.Owner == owne.Name ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == checkbox.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index + checkbox.Name} className=""
                                control={
                                  <Checkbox
                                    key={`cb-${index}`}
                                    name={(checkbox.Brand) + ' ' + (checkbox.Name)}
                                    value={checkbox.Category}

                                    checked={category.length ? true : false}
                                    id={checkbox.Category}
                                    color="primary"
                                    onChange={(e) => handleTypeCheckboxChangeGear(e, checkbox.id, checkbox)}
                                    inputProps={{ "data-weight": checkbox.Weight }}
                                  />
                                }
                                label={
                                  <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{checkbox.SubCategory} <span className="jright">{checkbox.Owner}</span></b><br/>
                                    <span style={{ fontWeight: 'normal' }}>
                                      {checkbox.Brand == "- NO BRAND" || checkbox.Brand == "" || checkbox.Brand == "-"  ? '-' : checkbox.Brand} {" "}
                                    </span>
                                    <span style={{ fontWeight: 'normal' }}>
                                     {checkbox.Name}
                                    </span>
                                  </span>
                                }
                              />
                              <div className="selection-second">
                                {tr?.length >= 1 ? <>
                                
                                </> : <>
                                <span style={{fontSize: '12px'}}>&nbsp;&nbsp;Please choose at least one Traveller</span>
                                </>}
                                {typeValue.map(el => {
                                  if (el.id == checkbox.id) {
                                    const getdear = typeValue.filter(el => el.id == checkbox.id ? true : false)
                                    const getdear2 = typeValue.filter(el => el.id != checkbox.id ? true : false)
                                    return (
                                      <>
                                      <div className="selection-third">
                                      {tr?.map((item, i) => {
                                            const checka = el?.gearHiking?.filter(a => a.Name == item.Name && a.id == checkbox.id ? true : false)
                                            return (
                                              <FormControlLabel key={i} className="sub-select"
                                                control={
                                                  <Checkbox
                                                    key={`cb-${i}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    checked={checka?.length ? true : false}
                                                    color="primary"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        const getd = getdear.map(els => {
                                                          const getoldda = els?.gearHiking || [];
                                                          const newitem = { ...item }
                                                          newitem.Quantity = 1;
                                                          newitem.id = els.id
                                                          newitem.Weight = els.Weight
                                                          newitem.packingLocation = "Base Weight"
                                                          getoldda.push(newitem)
                                                          return {
                                                            ...els,
                                                            Owner: item.Name,
                                                            gearHiking: getoldda
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setTypeValue(ar)
                                                      } else {
                                                        const getd = getdear.map(els => {
                                                          const fil = els?.gearHiking.filter(u => u.Name != item.Name)
                                                          return {
                                                            ...els,
                                                            gearHiking: fil
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setTypeValue(ar)
                                                      }
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <span style={{ fontWeight: 'bold' }}>
                                                   {item.Name}
                                                  </span>
                                                  }
                                              />
                                            )
                                          })}
                                          </div>  
                                        {el?.gearHiking?.map(it => {
                                          const numbers = it.id == checkbox.id && it.Quantity;
                                          return (
                                            <>
                                            <div className="selection-wrapper">
                                              <span className="selection-wrapper-traveller">{it.Name}</span>
                                                <TextField value={numbers} InputLabelProps={{ shrink: true, }}
                                                  id="standard-basic"
                                                  label={'Qty'}
                                                  name="quantity" onWheel={(e) => e.target.blur()}
                                                  className="second-selection-qty"
                                                  onChange={e => {
                                                    const getd = getdear.map(els => {
                                                      const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                      if (getsingledata?.length) {
                                                        getsingledata[0].Quantity = e.target.value;
                                                      }
                                                      return {
                                                        ...els,
                                                        Quantity: e.target.value
                                                      }
                                                    })
                                                    const ar = [...getdear2, ...getd]
                                                    setTypeValue(ar)
                                                  }}
                                                  type="Number"
                                                  variant="outlined"
                                                />

                                                <RadioGroup value={it.packingLocation ? it.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                                  const val = e.target.value
                                                  const getd = getdear.map(els => {
                                                    const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == it.id ? true : false)
                                                    getsingledata[0].packingLocation = val;
                                                    return {
                                                      ...els,
                                                      packingLocation: val
                                                    }
                                                  })
                                                  const ar = [...getdear2, ...getd]
                                                  setTypeValue(ar)
                                                }}>
                                                  <FormControlLabel value="Base Weight" control={<Radio color="primary" />} label="Base" />
                                                  <FormControlLabel value="Wear" control={<Radio color="primary" />} label="Wear" />
</RadioGroup></div>           
                                                </>
                                          )})}                                        
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key && owne.Name == "ANY" && Foodpackings.status == "ALL") {
                        return (

                          checkFunc()

                        );
                      } else if (selectGearCategory?.Category == key && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == key && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Base Weight" && boat2.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Consumables" && da4x42.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Travel Bag" && da4x4xx2.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Wear" && da4x4x2.length
                      ) {
                        return (
                          <>
                            {checkFunc()}
                          </>
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Base Weight" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Consumables" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Travel Bag" && da4x4xx.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Wear" && da4x4x.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL" && travelers.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL" && owne.Name == "ANY"
                      ) {
                        return (
                          <>
                            {checkFunc()}
                          </>
                        );
                      }
                    })
                  }
                  </div>
                      </AccordionDetails></Accordion>
                
              </>
            )
          })}

        </>
      )
    }
    else if (country?.label == "4x4 TOWING CAMPER") {
      return (
        <>
          {Object.keys(categorizedDatadatwa).sort().map((key, index) => {
            return (
              <>
                      <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa[key].items.map((checkbox, index) => {
                      const category = typeValue.filter(el => el.id == checkbox.id ? true : false)
                      const boat = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Camper" ? true : false)
                      const da4x4 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == checkbox.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index + checkbox.Name}
                                control={
                                  <Checkbox
                                    key={`cb-${index}`}
                                    name={(checkbox.Brand) + ' ' + (checkbox.Name)}
                                    value={checkbox.Category}

                                    checked={category.length ? true : false}
                                    id={checkbox.Category}
                                    color="primary"
                                    onChange={(e) => handleTypeCheckboxChangeGear(e, checkbox.id, checkbox)}
                                    inputProps={{ "data-weight": checkbox.Weight }}
                                  />
                                }
                                label={
                                  <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{checkbox.SubCategory} <span className="jright">{checkbox.Owner}</span></b><br/>
                                    <span style={{ fontWeight: 'normal' }}>
                                      {checkbox.Brand == "- NO BRAND" || checkbox.Brand == "" || checkbox.Brand == "-"  ? '-' : checkbox.Brand} {" "}
                                    </span>
                                    <span style={{ fontWeight: 'normal' }}>
                                     {checkbox.Name}
                                    </span>
                                  </span>
                                }
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {typeValue.map(el => {
                                  if (el.id == checkbox.id) {
                                    const number = el.Quantity
                                    const getdear = typeValue.filter(el => el.id == checkbox.id ? true : false)
                                    const getdear2 = typeValue.filter(el => el.id != checkbox.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setTypeValue(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
<RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                            const val = e.target.value
                                            const getd = getdear.map(els => {
                                              return {
                                                ...els,
                                                packingLocation: val
                                              }
                                            })
                                            const ar = [...getdear2, ...getd]
                                            setTypeValue(ar)
                                          }}>  
                                          { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }                                         
                                          <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                          <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                                        </RadioGroup>

                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key) {
                        return (
                          checkFunc()
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Camper" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "4x4" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL"
                      ) {
                        return (
                          checkFunc()
                        );
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>
              </>
            )
          })}
        </>
      )
    } else if (country?.label == "4x4 TOWING CARAVAN") {
      return (
        <>
          {Object.keys(categorizedDatadatwa).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt2(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa[key].items.map((checkbox, index) => {
                      const category = typeValue.filter(el => el.id == checkbox.id ? true : false)
                      const boat = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Caravan" ? true : false)
                      const da4x4 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == checkbox.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index + checkbox.Name} className=""
                                control={
                                  <Checkbox
                                    key={`cb-${index}`}
                                    name={(checkbox.Brand) + ' ' + (checkbox.Name)}
                                    value={checkbox.Category}

                                    checked={category.length ? true : false}
                                    id={checkbox.Category}
                                    color="primary"
                                    onChange={(e) => handleTypeCheckboxChangeGear(e, checkbox.id, checkbox)}
                                    inputProps={{ "data-weight": checkbox.Weight }}
                                  />
                                }
                                label={
                                  <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{checkbox.SubCategory} <span className="jright">{checkbox.Owner}</span></b><br/>
                                    <span style={{ fontWeight: 'normal' }}>
                                      {checkbox.Brand == "- NO BRAND" || checkbox.Brand == "" || checkbox.Brand == "-"  ? '-' : checkbox.Brand} {" "}
                                    </span>
                                    <span style={{ fontWeight: 'normal' }}>
                                     {checkbox.Name}
                                    </span>
                                  </span>
                                }
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {typeValue.map(el => {
                                  if (el.id == checkbox.id) {
                                    const number = el.Quantity
                                    const getdear = typeValue.filter(el => el.id == checkbox.id ? true : false)
                                    const getdear2 = typeValue.filter(el => el.id != checkbox.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setTypeValue(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
                                          <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setTypeValue(ar)
                }}>  
                { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }                                         
                  <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                  <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
              </RadioGroup>
                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key) {
                        return (
                          checkFunc()
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Caravan" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "4x4" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL"
                      ) {
                        return (
                          checkFunc()
                        );
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>                                    
              </>
            )
          })}
        </>
      )
    }
    else if (country?.label == "4x4 TOWING BOAT") {
      return (
        <>
          {Object.keys(categorizedDatadatwa).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt2(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa[key].items.map((checkbox, index) => {
                      const category = typeValue.filter(el => el.id == checkbox.id ? true : false)
                      const boat = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Boat" ? true : false)
                      const da4x4 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == checkbox.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index + checkbox.Name} className=""
                                control={
                                  <Checkbox
                                    key={`cb-${index}`}
                                    name={(checkbox.Brand) + ' ' + (checkbox.Name)}
                                    value={checkbox.Category}

                                    checked={category.length ? true : false}
                                    id={checkbox.Category}
                                    color="primary"
                                    onChange={(e) => handleTypeCheckboxChangeGear(e, checkbox.id, checkbox)}
                                    inputProps={{ "data-weight": checkbox.Weight }}
                                  />
                                }
                                label={
                                  <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{checkbox.SubCategory} <span className="jright">{checkbox.Owner}</span></b><br/>
                                    <span style={{ fontWeight: 'normal' }}>
                                      {checkbox.Brand == "- NO BRAND" || checkbox.Brand == "" || checkbox.Brand == "-"  ? '-' : checkbox.Brand} {" "}
                                    </span>
                                    <span style={{ fontWeight: 'normal' }}>
                                     {checkbox.Name}
                                    </span>
                                  </span>
                                }
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {typeValue.map(el => {
                                  if (el.id == checkbox.id) {
                                    const number = el.Quantity
                                    const getdear = typeValue.filter(el => el.id == checkbox.id ? true : false)
                                    const getdear2 = typeValue.filter(el => el.id != checkbox.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setTypeValue(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
                                          <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                  const val = e.target.value
                  const getd = getdear.map(els => {
                    return {
                      ...els,
                      packingLocation: val
                    }
                  })
                  const ar = [...getdear2, ...getd]
                  setTypeValue(ar)
                }}>  
                { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }                                         
                  <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                  <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
              </RadioGroup>
                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key) {
                        return (
                          checkFunc()
                        );
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Boat" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "4x4" && da4x4.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL"
                      ) {
                        return (
                          checkFunc()
                        );
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>
              </>
            )
          })}

        </>
      )
    }
    else if (country?.label == "AIR +") {
      return (
        <>
          {Object.keys(categorizedDatadatwa2).sort().map((key, index) => {
            return (
              <>
              <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategoryAlt(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                  {
                    categorizedDatadatwa2[key].items.map((checkbox, index) => {
                      const category = typeValue.filter(el => el.id == checkbox.id ? true : false)
                      const boat = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Carry On" ? true : false)
                      const da4x4 = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Wear" ? true : false)
                      const ddasa4x4x = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 1" ? true : false)
                      const da4xdas4xx = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 2" ? true : false)
                      const da4awdx4xx = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 3" ? true : false)
                      const da4adsax4xx = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 4" ? true : false)
                      const da4dasdx4xx = typeValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 5" ? true : false)

                      // const travelers = typeValue.filter(el => el.id == checkbox.id && el.Owner == owne.Name ? true : false)
                      const travelers = typeValue.filter(el => el.id == checkbox.id && el?.gearHiking?.length && el?.gearHiking[0]?.Name == owne.Name ? true : false)
                      const travelers2 = typeValue.filter(el => el.id == checkbox.id && el?.gearHiking?.length && el?.gearHiking[1]?.Name == owne.Name ? true : false)

                      const checkFunc = () => (
                        <>
                          {SearchOwner.Name == checkbox.Owner || SearchOwner.Name == "ANY" ? (
                            <div className="avoid selection-first">
                              <FormControlLabel key={index + checkbox.Name} className=""
                                control={
                                  <Checkbox
                                    key={`cb-${index}`}
                                    name={(checkbox.Brand) + ' ' + (checkbox.Name)}
                                    value={checkbox.Category}

                                    checked={category.length ? true : false}
                                    id={checkbox.Category}
                                    color="primary"
                                    onChange={(e) => handleTypeCheckboxChangeGear(e, checkbox.id, checkbox)}
                                    inputProps={{ "data-weight": checkbox.Weight }}
                                  />
                                }
                                label={
                                  <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{checkbox.SubCategory} <span className="jright">{checkbox.Owner}</span></b><br/>
                                    <span style={{ fontWeight: 'normal' }}>
                                      {checkbox.Brand == "- NO BRAND" || checkbox.Brand == "" || checkbox.Brand == "-"  ? '-' : checkbox.Brand} {" "}
                                    </span>
                                    <span style={{ fontWeight: 'normal' }}>
                                     {checkbox.Name}
                                    </span>
                                  </span>
                                }
                              />
                              <div className="selection-second">
                               {tr?.length >= 1 ? <>
                                
                                </> : <>
                                <span style={{fontSize: '12px'}}>&nbsp;&nbsp;Please choose at least one Traveller</span>
                                </>}
                                {typeValue.map(el => {
                                  if (el.id == checkbox.id) {
                                    const number = el.Quantity
                                    const ids = el.id
                                    const getdear = typeValue.filter(el => el.id == checkbox.id ? true : false)
                                    const getdear2 = typeValue.filter(el => el.id != checkbox.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-third">
                                          {tr?.map((item, i) => {
                                            const checka = el?.gearHiking?.filter(a => a.Name == item.Name && a.id == checkbox.id ? true : false)
                                            return (
                                              <FormControlLabel key={i} className="sub-select"
                                                control={
                                                  <Checkbox
                                                    key={`cb-${i}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    checked={checka?.length ? true : false}
                                                    color="primary"
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        const getd = getdear.map(els => {
                                                          const getoldda = els?.gearHiking || [];
                                                          const newitem = { ...item }
                                                          newitem.Quantity = 1;
                                                          newitem.id = els.id;
                                                          newitem.packingLocation = 'BAG1 - ' + tr[0]?.Name;
                                                          newitem.Weight = els.Weight;
                                                          getoldda.push(newitem)
                                                          return {
                                                            ...els,
                                                            Owner: item.Name,
                                                            gearHiking: getoldda
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setTypeValue(ar)
                                                      } else {
                                                        const getd = getdear.map(els => {
                                                          const fil = els?.gearHiking.filter(u => u.Name != item.Name)
                                                          return {
                                                            ...els,
                                                            gearHiking: fil
                                                          }
                                                        })
                                                        const ar = [...getdear2, ...getd]
                                                        setTypeValue(ar)
                                                      }
                                                    }}
                                                  />
                                                }
                                                label={
                                                  <span style={{ fontWeight: 'bold' }}>
                                                    {item.Name}
                                                  </span>}
                                              />
                                            )
                                          })}
                                        </div>
                                        {el?.gearHiking?.map(it => {
                                          const numbers = it.id == checkbox.id && it.Quantity;
                                          return (
                                            <><div className="selection-wrapper">
                                              <span className="selection-wrapper-traveller">{it.Name}</span>
                                                <TextField value={numbers} InputLabelProps={{ shrink: true, }}
                                                  id="standard-basic"
                                                  label="Qty"
                                                  name="quantity" onWheel={(e) => e.target.blur()}
                                                  className="second-selection-qty"
                                                  InputProps={{
                                                    inputProps: { min: 1, inputMode: 'numeric' }
                                                  }}
                                                  onKeyPress={(event) => {
                                                    if (event?.key === '-' || event?.key === '+') {
                                                      event.preventDefault();
                                                    }
                                                  }}
                                                  onChange={e => {
                                                    const getd = getdear.map(els => {
                                                      const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                      if (getsingledata?.length) {
                                                        getsingledata[0].Quantity = e.target.value;
                                                      }
                                                      return {
                                                        ...els,
                                                        Quantity: e.target.value
                                                      }
                                                    })
                                                    const ar = [...getdear2, ...getd]
                                                    setTypeValue(ar)
                                                  }}
                                                  type="number"
                                                  variant="outlined"
                                                />

<RadioGroup value={it.packingLocation ? it.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                                  const val = e.target.value
                                                  const getd = getdear.map(els => {
                                                    const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == it.id ? true : false)
                                                    getsingledata[0].packingLocation = val;
                                                    return {
                                                      ...els,
                                                      packingLocation: val
                                                    }
                                                  })
                                                  const ar = [...getdear2, ...getd]
                                                  setTypeValue(ar)
                                                }}>
                                                  <FormControlLabel value={"CARRY - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Carry On"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG1 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 1"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG2 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 2"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG3 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 3"}
                                                  
                                              />
                                              <FormControlLabel value={"WEAR - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Wear"}
                                                  
                                              />
                                              </RadioGroup>
                                            </div></>
                                          )
                                        })}
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          ) : null}
                        </>
                      )
                      if (selectGearCategory?.Category == key && Foodpackings.status == "ALL" && owne.Name == "ANY") {
                        return (
                          checkFunc()
                        );
                      }
                      else if (selectGearCategory?.Category == key && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == key && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (selectGearCategory?.Category == "ANY" && travelers2.length && Foodpackings.status == "ALL") {
                        return (
                          checkFunc()
                        )
                      }
                      else if (
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Carry On" && boat.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "Wear" && da4x4.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 1" && ddasa4x4x.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 2" && da4xdas4xx.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 3" && da4awdx4xx.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 4" && da4adsax4xx.length ||
                        selectFoodCategory.Category == "ANY" && Foodpackings.status == "Bag 5" && da4dasdx4xx.length ||
                        selectGearCategory.Category == "ANY" && Foodpackings.status == "ALL" && owne.Name == "ANY"
                      ) {
                        return (
                          checkFunc()
                        );
                      } else {
                        return null
                      }
                    })
                  }
                </div>
                      </AccordionDetails>
                      </Accordion>
              </>
            )
          })}

        </>
      )
    }
    else {
      return (
        <>
          {Object.keys(categorizedData).sort().map((key, index) => {
              return (
                <>
                <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion" style={{order:getCategorySort(key), margin: '3px 0'}}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? getCategory(key) : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
                    {categorizedData[key].items.map((checkbox, index) => {
                      if (SearchOwner.Name == checkbox.Owner || SearchOwner.Name == "ANY") {
                        const category = typeValue.filter(el => el.id == checkbox.id ? true : false)
                        return (
                          <>
                            <div className="avoid selection-first">
                              <FormControlLabel key={index + checkbox.Name} className=""
                                control={
                                  <Checkbox
                                    key={`cb-${index}`}
                                    name={(checkbox.Brand) + ' ' + (checkbox.Name)}
                                    value={checkbox.Category}

                                    checked={category.length ? true : false}
                                    id={checkbox.Category}
                                    color="primary"
                                    onChange={(e) => handleTypeCheckboxChangeGear(e, checkbox.id, checkbox)}
                                    inputProps={{ "data-weight": checkbox.Weight }}
                                  />
                                }
                                label={
                                  <span style={{ fontWeight: 'bold' }}>
                                    <b className="subcat-label">{checkbox.SubCategory} <span className="jright">{checkbox.Owner}</span></b><br/>
                                    <span style={{ fontWeight: 'normal' }}>
                                      {checkbox.Brand == "- NO BRAND" || checkbox.Brand == "" || checkbox.Brand == "-"  ? '-' : checkbox.Brand} {" "}
                                    </span>
                                    <span style={{ fontWeight: 'normal' }}>
                                     {checkbox.Name}
                                    </span>
                                  </span>
                                }
                              />
                              <div className="selection-second" style={{borderLeft: '0'}}>
                                {typeValue.map(el => {
                                  if (el.id == checkbox.id) {
                                    const number = el.Quantity
                                    const getdear = typeValue.filter(el => el.id == checkbox.id ? true : false)
                                    const getdear2 = typeValue.filter(el => el.id != checkbox.id ? true : false)
                                    console.log(typeValue)
                                    return (
                                      <>
                                        <div className="selection-wrapper">
                                          <TextField value={number} InputLabelProps={{ shrink: true, }}
                                            id="standard-basic"
                                            label="Qty"
                                            name="quantity" onWheel={(e) => e.target.blur()}
                                            className="second-selection-qty"
                                            InputProps={{
                                              inputProps: { min: 1, inputMode: 'numeric' }
                                            }}
                                            onKeyPress={(event) => {
                                              if (event?.key === '-' || event?.key === '+') {
                                                event.preventDefault();
                                              }
                                            }}
                                            style={{ marginLeft: '10px' }}
                                            onChange={e => {
                                              const getd = getdear.map(els => {
                                                return {
                                                  ...els,
                                                  Quantity: e.target.value,
                                                }
                                              })
                                              const ar = [...getdear2, ...getd]
                                              setTypeValue(ar)
                                            }}
                                            type="number"
                                            variant="outlined"
                                          />
                                        </div>
                                      </>
                                    )
                                  }
                                })}
                              </div>
                            </div>
                          </>
                        )
                      }
                    })}
                  </div>
                      </AccordionDetails>
                      </Accordion>
                </>
              )
            
          })}
        </>
      )
    }
  }

  // DEFAULT VALUES FOR RADIO
  
  const handleTypeCheckboxChangeGear = (event, id, data) => {
    if (event.target.checked) {
      setTypeCheckboxCount(prevState => prevState + 1)
      if (country?.label == "MOTORBIKE") { setTypeValue([...typeValue, { ...data, Quantity: 1, packingLocation: "Motorbike",LoanedTo:data.LoanedTo == undefined ? "" : data.LoanedTo }]) }
      if (country?.label == "4x4 TOWING CAMPER") { setTypeValue([...typeValue, { ...data, Quantity: 1, packingLocation: "Camper",LoanedTo:data.LoanedTo == undefined ? "" : data.LoanedTo }]) }
      if (country?.label == "4x4 TOWING CARAVAN") { setTypeValue([...typeValue, { ...data, Quantity: 1, packingLocation: "Caravan",LoanedTo:data.LoanedTo == undefined ? "" : data.LoanedTo }]) }
      if (country?.label == "4x4 TOWING BOAT") { setTypeValue([...typeValue, { ...data, Quantity: 1, packingLocation: "4x4",LoanedTo:data.LoanedTo == undefined ? "" : data.LoanedTo }]) }
      if (country?.label == "PADDLING") { setTypeValue([...typeValue, { ...data, Quantity: 1, packingLocation: "4x4",LoanedTo:data.LoanedTo == undefined ? "" : data.LoanedTo }]) }
      if (country?.label == "HIKING") { setTypeValue([...typeValue, { ...data, Quantity: 1, packingLocation: "Base Weight",LoanedTo:data.LoanedTo == undefined ? "" : data.LoanedTo }]) }
      if (country?.label == "AIR +") { setTypeValue([...typeValue, { ...data, Quantity: 1, packingLocation: "BAG1 - " + tr[0].Name,LoanedTo:data.LoanedTo == undefined ? "" : data.LoanedTo }]) }
    } else {
      setTypeCheckboxCount(prevState => prevState - 1)
      setTypeValue(
        typeValue.filter(v => v.id !== id)
      )
    }


    setTotalAmount((totalAmount) => {
      if (event.target.checked) {
        totalAmount = totalAmount + parseFloat(event.target.getAttribute('data-weight'));
        return totalAmount
      } else {
        totalAmount = totalAmount - parseFloat(event.target.getAttribute('data-weight'));
        return totalAmount
      }
    });
  }

  const [selectFoodCategory, setSelectFoodCategory] = useState({ Category: "ANY" })
  const handleFoodChange = (e) => {
    setSelectFoodCategory(e)
  }

  const [getidFood, setidFood] = useState([])

  const [FoodWeight, setFoodWeight] = useState([])

  const geFoodID = (ev, id, item) => {
    const filter = foodValue.filter(el => el.id != id ? true : false)
    {country?.label == "MOTORBIKE" ?
    setFoodValue((state) => [...filter, { id: id, ...item, bought: false, packed: false, packingLocation: "Motorbike", Quantity: '1' }])
    :
    country?.label == "AIR +" ?
    setFoodValue((state) => [...filter, { id: id, ...item, bought: false, packed: false, packingLocation: 'BAG1 - ' + tr[0]?.Name, Quantity: '1' }])
    :
    country?.label == "BIKEPACKING" ?
    setFoodValue((state) => [...filter, { id: id, ...item, bought: false, packed: false, packingLocation: 'PACK - ' + tr[0]?.Name, Quantity: '1' }])
    :
    country?.label == "4x4 TOWING CAMPER" ?
    setFoodValue((state) => [...filter, { id: id, ...item, bought: false, packed: false, packingLocation: "Camper", Quantity: '1' }])
    :
    country?.label == "4x4 TOWING CARAVAN" ?
    setFoodValue((state) => [...filter, { id: id, ...item, bought: false, packed: false, packingLocation: "Caravan", Quantity: '1' }])
    :
    setFoodValue((state) => [...filter, { id: id, ...item, bought: false, packed: false, packingLocation: "4x4", Quantity: '1' }])
    }
    const getids = [...getidFood]
    const newdataput = [...FoodWeight]
    if (ev.target.checked) {
      getids.push(id)
      let unique = [...new Set(getids)];
      setidFood(unique)
      newdataput.push({ id: item.id, Weight: item.Weight, Quantity: '1' })
      let uniques = [...new Set(newdataput)];
      setFoodWeight(uniques)
    } else {
      const filterweoght = FoodWeight.filter(el => el.id != id ? true : false)
      setFoodWeight(filterweoght)
      const filter = getidFood.filter(el => el != id ? true : false)
      setidFood(filter)
      const mealfil = foodValue.filter(el => el.id != id)
      setFoodValue(mealfil)
    }
  }

  const quantityfunctionFood = (number, id, item) => {
    const getselecteddata = foodValue.filter(el => el.id == id ? true : false)
    const getalldata = foodValue.filter(el => el.id != id ? true : false)
    const getd = getselecteddata.map(els => {
      return {
        ...els,
        Quantity: number,
      }
    })
    const ar = [...getalldata, ...getd]
    setFoodValue(ar)
    const awaaaa = FoodWeight.map(el => {
      if (el.id == id) {
        return {
          ...el,
          Quantity: number
        }
      } else {
        return {
          ...el
        }
      }
    })
    setFoodWeight(awaaaa)
  }


  const handleTypeCheckboxChangeFood = (event, id, item, bought, packed, name) => {
    if (event.target.checked) {
      setTypeCheckboxCount(prevState => prevState + 1)
      const filter = foodValue.filter(el => el.id == id ? true : false)
      const filtersaa = foodValue.filter(el => el.id != id ? true : false)
      let olddata;
      filter.map(el => {
        olddata = el
      })
      if (filter.length) {
        if (name == "bought") {
          setFoodValue([...filtersaa, { id, ...olddata, bought: true }])
        } else {
          setFoodValue([...filtersaa, { id, ...olddata, packed: true }])
        }
      } else {
        setFoodValue([...filtersaa, { id, ...item, bought, packed, Quantity: '1' }])
      }
    } else {
      setTypeCheckboxCount(prevState => prevState - 1)
      const filtersaa = foodValue.filter(el => el.id == id && el.bought == true && el.packed == true ? true : false)
      const filter = foodValue.filter(el => el.id == id ? true : false)
      let olddata;
      filter.map(el => {
        olddata = el
      })
      const filla = foodValue.filter(v => v.id !== id)
      if (name == "bought") {
        setFoodValue([...filla, { id, ...olddata, bought: false }])
      } else {
        setFoodValue([...filla, { id, ...olddata, packed: false }])
      }
    }
    

    setTotalAmountF((totalAmountF) => {
      if (event.target.checked) {
        totalAmountF = totalAmountF + parseFloat(event.target.getAttribute('data-weight'));
        return totalAmountF
      } else {
        totalAmountF = totalAmountF - parseFloat(event.target.getAttribute('data-weight'));
        return totalAmountF
      }
    });
  }

  const handleTypeCheckboxChangeFood1 = (event, id, item, bought, packed, name) => {
    if (event.target.checked) {
      setTypeCheckboxCount(prevState => prevState + 1)
      const filter = categorizedDataFoods1.filter(el => el.id == id ? true : false)
      const filtersaa = categorizedDataFoods1.filter(el => el.id != id ? true : false)
      let olddata;
      filter.map(el => {
        olddata = el
      })
      if (filter.length) {
        if (name == "bought") {
          setFoodValue([...filtersaa, { id, ...olddata, bought: true }])
        } else {
          setFoodValue([...filtersaa, { id, ...olddata, packed: true }])
        }
      } else {
        setFoodValue([...filtersaa, { id, ...item, bought, packed, Quantity: '1' }])
      }
    } else {
      setTypeCheckboxCount(prevState => prevState - 1)
      const filtersaa = categorizedDataFoods1.filter(el => el.id == id && el.bought == true && el.packed == true ? true : false)
      const filter = categorizedDataFoods1.filter(el => el.id == id ? true : false)
      let olddata;
      filter.map(el => {
        olddata = el
      })
      const filla = categorizedDataFoods1.filter(v => v.id !== id)
      if (name == "bought") {
        setFoodValue([...filla, { id, ...olddata, bought: false }])
      } else {
        setFoodValue([...filla, { id, ...olddata, packed: false }])
      }
    }
    

    setTotalAmountF((totalAmountF) => {
      if (event.target.checked) {
        totalAmountF = totalAmountF + parseFloat(event.target.getAttribute('data-weight'));
        return totalAmountF
      } else {
        totalAmountF = totalAmountF - parseFloat(event.target.getAttribute('data-weight'));
        return totalAmountF
      }
    });
  }

  const [FoodStatusCheck, setFoodStatusCheck] = useState({ status: "ALL" })

  const stat = [
    { status: "ALL" },
    { status: "SHOPPING LIST" },
    { status: "BOUGHT" },
    { status: "PACKED" }
  ];

  function descendingComparator(a, b, orderBy) {
    // console.log('a',a)
    // console.log('b',b)
    // console.log('orderBy',orderBy)
    if (b[orderBy] < a[orderBy]) {
      // console.log('-1')
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      // console.log('1')
      return 1;
    }
    // console.log('0')
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy)
  }

  const sortedRowInformation = (rowArray, comparator) => {
    const stabilizedRowArray = rowArray.map((el, index) => [el, index])
    stabilizedRowArray.sort((a, b) => {
      const order = comparator(a[0], b[0])
      if (order !== 0) return order;
      return a[1] - b[1];
    })
    return stabilizedRowArray.map((el) => el[0])
  }

  const [orderDirection, setorderDirection] = useState("asc")
  const [valueToOrderBy, setvalueToOrderBy] = useState("Name")

  const food_sorted = ['BREAKFAST', 'LUNCH', 'DINNER', 'SNACKS', 'PANTRY', 'COOL DRINKS', 'HOT DRINKS', 'ALCOHOL'];
  
  const meal_sorted = ['BREAKFAST', 'LUNCH', 'DINNER']

  awawafoods?.sort(function (a, b) {
    if (a.Category < b.Category) { return -1; }
    if (a.Category > b.Category) { return 1; }
    return 0;
  })

  const [visibleDivIndices, setVisibleDivIndices] = useState({});
  const [ftraveller, setFTraveller] = useState(null)

  const handleCheckboxFChange = (e) => {
    setFTraveller(e.target.value)
    console.log(ftraveller)
  }
  // Function to handle checkbox selection
  const handleCheckboxChange = (elId, i) => {
    console.log(elId);
    setVisibleDivIndices((prevIndices) => {
      const currentIndices = prevIndices[elId] || [];
      const updatedIndices = currentIndices.includes(i)
        ? currentIndices.filter(index => index !== i)
        : [...currentIndices, i];
      
      return {
        ...prevIndices,
        [elId]: updatedIndices
      };
    });
  };
  
  // Meal Food Component
  const checkboxFoodFunctionRender = () => {
    if (country?.label == "4x4 TOWING BOAT") {
      return (
        <>
          {food_sorted.map((key, index) => {
        return ( <>
          <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
        {categorizedDataFoods[key]?.items.map((checkbox, index) => {
            const getselecteddata = foodValue.filter(el => el.id == checkbox.id ? true : false)
            const getalldata = foodValue.filter(el => el.id != checkbox.id ? true : false)
            const category = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packed == false ? true : false)
            const packed = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == false ? true : false)
            const under = getidFood.filter(el => el == checkbox.id ? true : false)
            const both = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == true ? true : false)
            const gaag = foodValue.filter(elaa => elaa.id == checkbox.id ? true : false)
            const boat = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Boat" ? true : false)
            const da4x4 = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
            const da4x42 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
            const da4x42x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
            const da4x42xx = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
            const da4x42xxx = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
            const checkfunction = () => (
              <div className="avoid selection-first">
              <FormControlLabel key={index + checkbox.Name}
                control={
                  <Checkbox
                    key={`cb-${index}`}
                    name={checkbox.Name}
                    value={checkbox.Name}
                    id={checkbox.Category}
                    checked={under?.length ? true : false}
                    color="primary"
                    onChange={(e) => geFoodID(e, checkbox.id, checkbox)}
                    inputProps={{ "data-weight": checkbox.Weight }}
                  />
                }
                label={
                  <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {checkbox.Name}
                    </span>
                  </span>}
              />
              <div className="selection-second" style={{borderLeft: '0'}}>
                    {getidFood.map(el => {
                      if (el == checkbox.id) {
                        return (
                          <>
                              {FoodWeight.map(el => {
                                if (el.id == checkbox.id) {
                                  let number = el.Quantity;
                                  return (
                                    <div className="selection-wrapper">
                                      <TextField value={number} InputLabelProps={{ shrink: true, }}
                                        id="standard-basic"
                                        label="Qty"
                                        name="quantity" onWheel={(e) => e.target.blur()}
                                        className="second-selection-qty"
                                        onChange={e => {
                                          quantityfunctionFood(e.currentTarget.value, checkbox.id, checkbox)
                                        }}
                                        type="Number"
                                        variant="outlined"
                                      />
                                      {getselecteddata.map(els => {
                                        return (
                                        <>
                                      <RadioGroup value={els.packingLocation ? els.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={e => {
                                      const val = e.target.value
                                      const getd = getselecteddata.map(els => {
                                        return {
                                          ...els,
                                          packingLocation: val,
                                        }
                                      })
                                      const ar = [...getalldata, ...getd]
                                      setFoodValue(ar)
                                      }}>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                      </RadioGroup>
                                      </>
                                        )
                                      })}
                                    </div>
                                  )
                                }
                              })}
                                       </>
                        )
                      }
                    })}
                    </div>
              </div>
            )

            if (
              selectFoodCategory?.Category == checkbox.Category &&
              FoodStatusCheck.status == "ALL"
            ) {
              return (
                checkfunction()
              );
            } else if (
              category.length && FoodStatusCheck.status == "BOUGHT" &&
              Foodpacking.status == "ALL" &&
              selectFoodCategory?.Category == checkbox.Category ||
              Foodpacking.status == "ALL" && packed.length &&
              FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == checkbox.Category || under.length &&
              Foodpacking.status == "ALL" &&
              FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
              return (
                checkfunction()
              )
            } else if (
              category.length &&
              FoodStatusCheck.status == "BOUGHT" &&
              selectFoodCategory.Category == "ANY" &&
              Foodpacking.status == "ALL" ||
              packed.length &&
              FoodStatusCheck.status == "PACKED" &&
              Foodpacking.status == "ALL" &&
              selectFoodCategory.Category == "ANY" ||
              Foodpacking.status == "ALL" &&
              under.length &&
              FoodStatusCheck.status == "SHOPPING LIST" &&
              selectFoodCategory.Category == "ANY"
            ) {
              return (
                checkfunction()
              )
            }
            else if (
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Boat" && boat.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Boat" && da4x42xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Boat" && da4x42xxx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
            ) {
              return (
                checkfunction()
              );
            }
        })}
        </div>
                      </AccordionDetails>
                      </Accordion>
          </> ) } ) }
          
        </>
      )
    } else if (country?.label == "HIKING" || country?.label == "BIKEPACKING") {
      return (
        <>
          {food_sorted.map((key, index) => {
        return ( <>
        <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
        {categorizedDataFoods[key]?.items.map((checkbox, index) => {
            const getselecteddata = foodValue.filter(el => el.id == checkbox.id ? true : false)
            const getalldata = foodValue.filter(el => el.id != checkbox.id ? true : false)
            const category = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packed == false ? true : false)
            const packed = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == false ? true : false)
            const under = getidFood.filter(el => el == checkbox.id ? true : false)
            const both = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == true ? true : false)

            const gaag = foodValue.filter(elaa => elaa.id == checkbox.id ? true : false)
            const boat = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Base Weight" ? true : false)
            const da4x4 = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Consumables" ? true : false)
            const da4x4x = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Wear" ? true : false)
            const da4x4xx = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Travel Bag" ? true : false)
            const da4x422 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Base Weight" ? true : false)
            const da4x42a = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Consumables" ? true : false)
            const da4x4223 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Wear" ? true : false)
            const da4x4a2 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Travel Bag" ? true : false)
            const dsa4x42x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Base Weight" ? true : false)
            const da4x4dc2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Consumables" ? true : false)
            const da4x4a2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Wear" ? true : false)
            const da4cx42x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Travel Bag" ? true : false)
            const checkfunct = () => (
              <div className="avoid selection-first">
              <FormControlLabel key={index + checkbox.Name}
                control={
                  <Checkbox
                    key={`cb-${index * 625}`}
                    name={checkbox.Name}
                    value={checkbox.Name}
                    id={checkbox.Category}
                    checked={under?.length ? true : false}
                    color="primary"
                    onChange={(e) => geFoodID(e, checkbox.id, checkbox)}
                    inputProps={{ "data-weight": checkbox.Weight }}
                  />
                }
                label={
                  <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {checkbox.Name}
                    </span>
                    </span>
                } />
                <div className="selection-second" style={{borderLeft: '0'}}>
                    {getidFood.map(el => {
                      if (el == checkbox.id) {
                        return (
                          <>
                              {FoodWeight.map(el => {
                                if (el.id == checkbox.id) {
                                  let number = el.Quantity;
                                  return (
                                    <div className="selection-wrapper">
                                      <TextField value={number} InputLabelProps={{ shrink: true, }}
                                      style={{pointerEvents: 'all'}}
                                        id="standard-basic"
                                        label="Qty"
                                        name="quantity" onWheel={(e) => e.target.blur()}
                                        className="second-selection-qty"
                                        onChange={e => {
                                          quantityfunctionFood(e.currentTarget.value, checkbox.id, checkbox)
                                        }}
                                        type="Number"
                                        variant="outlined"
                                      />
                                      {getselecteddata.map(els => {
                                        return (
                                        <>
                                      <RadioGroup value={els.packingLocation ? els.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={e => {
                                      const val = e.target.value
                                      const getd = getselecteddata.map(els => {
                                        return {
                                          ...els,
                                          packingLocation: val,
                                        }
                                      })
                                      const ar = [...getalldata, ...getd]
                                      setFoodValue(ar)
                                      }}>
                                      {tr?.map((item, i) => {
                                            return (
                                              <>
                                              
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                          </RadioGroup>
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
</>
                                        ) })}

                                      
                                    </div>
                                  )
                                }
                              })}
                          </>
                        )
                      }
                    })}</div>
              </div>
            )
            if (selectFoodCategory?.Category == checkbox.Category && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL") {
              return (
                checkfunct()
              );
            } else if (Foodpacking.status == "ALL" && category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory?.Category == checkbox.Category || packed.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == checkbox.Category || under.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
              return (
                checkfunct()
              )
            } else if (Foodpacking.status == "ALL" && category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory.Category == "ANY" || packed.length && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "ALL" && selectFoodCategory.Category == "ANY" || under.length && Foodpacking.status == "ALL" && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory.Category == "ANY") {
              return (
                checkfunct()
              )
            }
            else if (
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Base Weight" && boat.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Consumables" && da4x4.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Travel Bag" && da4x4xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Wear" && da4x4x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Base Weight" && da4x422.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Consumables" && da4x42a.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Wear" && da4x4223.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Travel Bag" && da4x4a2.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Base Weight" && dsa4x42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Consumables" && da4x4dc2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Wear" && da4x4a2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Travel Bag" && da4cx42x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
            ) {
              return (
                checkfunct()
                );
              }
          })}
          </div>
                      </AccordionDetails>
                      </Accordion>
        </> ) } ) }
            
          </>
        )
      } else if (country?.label == "4x4 TOWING CAMPER") {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
            <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel-items-content"
                          id="panel-items"
                          style={{marginTop:'0'}}
                        >
                        <Typography>
                        {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                        <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                        ) : null}
                        </Typography></AccordionSummary>
                        <AccordionDetails>
                        <div className="tpgrid">
          {categorizedDataFoods[key]?.items.map((checkbox, index) => {
              const getselecteddata = foodValue.filter(el => el.id == checkbox.id ? true : false)
              const getalldata = foodValue.filter(el => el.id != checkbox.id ? true : false)
              const category = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packed == false ? true : false)
              const packed = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == false ? true : false)
              const under = getidFood.filter(el => el == checkbox.id ? true : false)
              const both = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == true ? true : false)
              const gaag = foodValue.filter(elaa => elaa.id == checkbox.id ? true : false)
              const boat = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Boat" ? true : false)
              const da4x4 = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
              const da4x42 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
              const da4x42x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
              const da4x42xx = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
              const da4x42xxx = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
              const checkfunction = () => (
                <div className="avoid selection-first">
                <FormControlLabel key={index + checkbox.Name}
                  control={
                    <Checkbox
                      key={`cb-${index}`}
                      name={checkbox.Name}
                      value={checkbox.Name}
                      id={checkbox.Category}
                      checked={under?.length ? true : false}
                      color="primary"
                      onChange={(e) => geFoodID(e, checkbox.id, checkbox)}
                      inputProps={{ "data-weight": checkbox.Weight }}
                    />
                  }
                  label={
                    <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                      <span style={{ fontWeight: 'normal' }}>
                        {checkbox.Name}
                      </span>
                    </span>}
                />
                <div className="selection-second" style={{borderLeft: '0'}}>
                      {getidFood.map(el => {
                        if (el == checkbox.id) {
                          return (
                            <>
                                {FoodWeight.map(el => {
                                  if (el.id == checkbox.id) {
                                    let number = el.Quantity;
                                    return (
                                      <div className="selection-wrapper">
                                        <TextField value={number} InputLabelProps={{ shrink: true, }}
                                          id="standard-basic"
                                          label="Qty"
                                          name="quantity" onWheel={(e) => e.target.blur()}
                                          className="second-selection-qty"
                                          onChange={e => {
                                            quantityfunctionFood(e.currentTarget.value, checkbox.id, checkbox)
                                          }}
                                          type="Number"
                                          variant="outlined"
                                        />
                                        {getselecteddata.map(els => {
                                          return (
                                          <>
                                        <RadioGroup value={els.packingLocation ? els.packingLocation : "To Pack"} defaultValue="To Pack" row
                                        onChange={e => {
                                        const val = e.target.value
                                        const getd = getselecteddata.map(els => {
                                          return {
                                            ...els,
                                            packingLocation: val,
                                          }
                                        })
                                        const ar = [...getalldata, ...getd]
                                        setFoodValue(ar)
                                        }}>
                                        <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                        <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                                        </RadioGroup>
                                        </>
                                          )
                                        })}
                                      </div>
                                    )
                                  }
                                })}
                                         </>
                          )
                        }
                      })}
                      </div>
                </div>
              )
  
              if (
                selectFoodCategory?.Category == checkbox.Category &&
                FoodStatusCheck.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              } else if (
                category.length && FoodStatusCheck.status == "BOUGHT" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory?.Category == checkbox.Category ||
                Foodpacking.status == "ALL" && packed.length &&
                FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == checkbox.Category || under.length &&
                Foodpacking.status == "ALL" &&
                FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
                return (
                  checkfunction()
                )
              } else if (
                category.length &&
                FoodStatusCheck.status == "BOUGHT" &&
                selectFoodCategory.Category == "ANY" &&
                Foodpacking.status == "ALL" ||
                packed.length &&
                FoodStatusCheck.status == "PACKED" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory.Category == "ANY" ||
                Foodpacking.status == "ALL" &&
                under.length &&
                FoodStatusCheck.status == "SHOPPING LIST" &&
                selectFoodCategory.Category == "ANY"
              ) {
                return (
                  checkfunction()
                )
              }
              else if (
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Camper" && boat.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Camper" && da4x42xx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Camper" && da4x42xxx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              }
          })}
          </div>
                        </AccordionDetails>
                        </Accordion>
            </> ) } ) }
            
          </>
        )
      } else if (country?.label == "4x4 TOWING CARAVAN") {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
            <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel-items-content"
                          id="panel-items"
                          style={{marginTop:'0'}}
                        >
                        <Typography>
                        {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                        <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                        ) : null}
                        </Typography></AccordionSummary>
                        <AccordionDetails>
                        <div className="tpgrid">
          {categorizedDataFoods[key]?.items.map((checkbox, index) => {
              const getselecteddata = foodValue.filter(el => el.id == checkbox.id ? true : false)
              const getalldata = foodValue.filter(el => el.id != checkbox.id ? true : false)
              const category = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packed == false ? true : false)
              const packed = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == false ? true : false)
              const under = getidFood.filter(el => el == checkbox.id ? true : false)
              const both = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == true ? true : false)
              const gaag = foodValue.filter(elaa => elaa.id == checkbox.id ? true : false)
              const boat = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Boat" ? true : false)
              const da4x4 = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
              const da4x42 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
              const da4x42x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
              const da4x42xx = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
              const da4x42xxx = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
              const checkfunction = () => (
                <div className="avoid selection-first">
                <FormControlLabel key={index + checkbox.Name}
                  control={
                    <Checkbox
                      key={`cb-${index}`}
                      name={checkbox.Name}
                      value={checkbox.Name}
                      id={checkbox.Category}
                      checked={under?.length ? true : false}
                      color="primary"
                      onChange={(e) => geFoodID(e, checkbox.id, checkbox)}
                      inputProps={{ "data-weight": checkbox.Weight }}
                    />
                  }
                  label={
                    <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                      <span style={{ fontWeight: 'normal' }}>
                        {checkbox.Name}
                      </span>
                    </span>}
                />
                <div className="selection-second" style={{borderLeft: '0'}}>
                      {getidFood.map(el => {
                        if (el == checkbox.id) {
                          return (
                            <>
                                {FoodWeight.map(el => {
                                  if (el.id == checkbox.id) {
                                    let number = el.Quantity;
                                    return (
                                      <div className="selection-wrapper">
                                        <TextField value={number} InputLabelProps={{ shrink: true, }}
                                          id="standard-basic"
                                          label="Qty"
                                          name="quantity" onWheel={(e) => e.target.blur()}
                                          className="second-selection-qty"
                                          onChange={e => {
                                            quantityfunctionFood(e.currentTarget.value, checkbox.id, checkbox)
                                          }}
                                          type="Number"
                                          variant="outlined"
                                        />
                                        {getselecteddata.map(els => {
                                          return (
                                          <>
                                        <RadioGroup value={els.packingLocation ? els.packingLocation : "To Pack"} defaultValue="To Pack" row
                                        onChange={e => {
                                        const val = e.target.value
                                        const getd = getselecteddata.map(els => {
                                          return {
                                            ...els,
                                            packingLocation: val,
                                          }
                                        })
                                        const ar = [...getalldata, ...getd]
                                        setFoodValue(ar)
                                        }}>
                                        <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                        <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
                                        </RadioGroup>
                                        </>
                                          )
                                        })}
                                      </div>
                                    )
                                  }
                                })}
                                         </>
                          )
                        }
                      })}
                      </div>
                </div>
              )
  
              if (
                selectFoodCategory?.Category == checkbox.Category &&
                FoodStatusCheck.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              } else if (
                category.length && FoodStatusCheck.status == "BOUGHT" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory?.Category == checkbox.Category ||
                Foodpacking.status == "ALL" && packed.length &&
                FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == checkbox.Category || under.length &&
                Foodpacking.status == "ALL" &&
                FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
                return (
                  checkfunction()
                )
              } else if (
                category.length &&
                FoodStatusCheck.status == "BOUGHT" &&
                selectFoodCategory.Category == "ANY" &&
                Foodpacking.status == "ALL" ||
                packed.length &&
                FoodStatusCheck.status == "PACKED" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory.Category == "ANY" ||
                Foodpacking.status == "ALL" &&
                under.length &&
                FoodStatusCheck.status == "SHOPPING LIST" &&
                selectFoodCategory.Category == "ANY"
              ) {
                return (
                  checkfunction()
                )
              }
              else if (
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Camper" && boat.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Camper" && da4x42xx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Camper" && da4x42xxx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              }
          })}
          </div>
                        </AccordionDetails>
                        </Accordion>
            </> ) } ) }
            
          </>
        )
      } else if (country?.label == "AIR +") {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
          <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                      <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                      ) : null}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
          {categorizedDataFoods[key]?.items.map((checkbox, index) => {
            const getselecteddata = foodValue.filter(el => el.id == checkbox.id ? true : false)
            const getalldata = foodValue.filter(el => el.id != checkbox.id ? true : false)
            const category = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packed == false ? true : false)
            const packed = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == false ? true : false)
            const under = getidFood.filter(el => el == checkbox.id ? true : false)
            const both = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == true ? true : false)

            const gaag = foodValue.filter(elaa => elaa.id == checkbox.id ? true : false)
            const boat = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Carry On" ? true : false)
            const da4x4 = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Wear" ? true : false)
            const ddasa4x4x = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 1" ? true : false)
            const da4xdas4xx = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 2" ? true : false)
            const da4awdx4xx = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 3" ? true : false)
            const da4adsax4xx = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Bag 4" ? true : false)
            const dayyuu4x422 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Carry On" ? true : false)
            const da4yyx42a = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Wear" ? true : false)
            const da4x4ghh223 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Bag 1" ? true : false)
            const da4xnn4a2 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Bag 2" ? true : false)
            const da4xb4a2 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Bag 3" ? true : false)
            const da4x4va2 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Bag 4" ? true : false)
            const dsa4x4as2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Carry On" ? true : false)
            const da4x4dc2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Wear" ? true : false)
            const da4alx4a2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Bag 1" ? true : false)
            const da4x994a2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Bag 2" ? true : false)
            const da4xff4a2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Bag 3" ? true : false)
            const da4dasdx4a2x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Bag 4" ? true : false)
            
            const checkfunc = () => (
              <div className="avoid selection-first">
              <FormControlLabel key={index + checkbox.Name}
                control={
                  <Checkbox
                    key={`cb-${index}`}
                    name={checkbox.Name}
                    value={checkbox.Name}
                    id={checkbox.Category}
                    checked={under?.length ? true : false}
                    color="primary"
                    onChange={(e) => geFoodID(e, checkbox.id, checkbox)}
                    inputProps={{ "data-weight": checkbox.Weight }}
                  />
                }
                label={
                  <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                    <span style={{ fontWeight: 'normal' }}>
                      {checkbox.Name}
                    </span>
                    </span>} />
                    
                    <div className="selection-second">
                    {foodValue.map(el => {
                                  if (el.id == checkbox.id) {
                                    const number = el.Quantity
                                    const ids = el.id
                                    const getdear = foodValue.filter(el => el.id == checkbox.id ? true : false)
                                    const getdear2 = foodValue.filter(el => el.id != checkbox.id ? true : false)
                                    return (
                                      <>
                                        <div className="selection-third">
                                        <RadioGroup 
  style={{ flexDirection: 'row' }}
  onChange={(e) => {
    const selectedName = e.target.value;
    
    const updatedGear = getdear.map(els => {
      if (els.id === checkbox.id) {
        const newitem = tr.find(item => `${item.Name} ${item.Weight}` === selectedName);
        return {
          ...els,
          gearHiking: newitem
            ? [
                {
                  ...newitem,
                  Quantity: 1,
                  id: els.id,
                  packingLocation: `BAG1 - ${tr[0]?.Name}`,
                  Weight: newitem.Weight
                }
              ]
            : []
        };
      }
      return {
        ...els,
        gearHiking: []
      };
    });

    setFoodValue([...getdear2, ...updatedGear]);
  }}
>
  {tr?.map((item, i) => {
    const isSelected = el?.gearHiking?.some(
      a => a.Name === item.Name && a.id === checkbox.id
    );

    return (
      <FormControlLabel
        key={i}
        className="sub-select"
        control={
          <Radio
            key={`radio-${i}`}
            name="gearHiking"
            checked={isSelected}
            value={`${item.Name} ${item.Weight}`}
            color="primary"
          />
        }
        label={
          <span style={{ fontWeight: 'bold' }}>
            {item.Name}
          </span>
        }
      />
    );
  })}
</RadioGroup>

                                        </div>
                                        {el?.gearHiking?.map(it => {
                                          const numbers = it.id == checkbox.id && it.Quantity;
                                          return (
                                            <><div className="selection-wrapper">
                                              <TextField value={number}  InputLabelProps={{ shrink: true, }}
                                        id="standard-basic"
                                        label="Qty"
                                        name="quantity" onWheel={(e) => e.target.blur()}
                                        className="second-selection-qty"
                                        onChange={e => {
                                          quantityfunctionFood(e.currentTarget.value, checkbox.id, checkbox)
                                        }}
                                        type="Number"
                                        variant="outlined"
                                      />

<RadioGroup value={it.packingLocation ? it.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                                  const val = e.target.value
                                                  const getd = getdear.map(els => {
                                                    const getsingledata = els.gearHiking.filter(elm => elm.Name == it.Name && elm.id == it.id ? true : false)
                                                    getsingledata[0].packingLocation = val;
                                                    return {
                                                      ...els,
                                                      packingLocation: val
                                                    }
                                                  })
                                                  const ar = [...getdear2, ...getd]
                                                  setFoodValue(ar)
                                                }}>
                                                  <FormControlLabel value={"CARRY - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Carry On"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG1 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 1"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG2 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 2"}
                                                  
                                              />
                                              <FormControlLabel value={"BAG3 - " + it.Name}
                                                control={
                                                  <Radio
                                                    name={(it.Name) + ' ' + (it.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={"Bag 3"}
                                                  
                                              />
                                              </RadioGroup>
                                            </div></>
                                          )
                                        })}
                                      </>
                                    )
                                  }
                                })}

                    
                  </div></div>
            )
            if (selectFoodCategory?.Category == checkbox.Category && FoodStatusCheck.status == "ALL") {
              return (
                checkfunc()
              );
            } else if (category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory?.Category == checkbox.Category && Foodpacking.status == "ALL" || packed.length && FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == checkbox.Category && Foodpacking.status == "ALL" || under.length && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category && Foodpacking.status == "ALL") {
              return (
                checkfunc()
              )
            } else if (category.length && FoodStatusCheck.status == "BOUGHT" && selectFoodCategory.Category == "ANY" && Foodpacking.status == "ALL" || packed.length && FoodStatusCheck.status == "PACKED" && selectFoodCategory.Category == "ANY" && Foodpacking.status == "ALL" || under.length && FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory.Category == "ANY" && Foodpacking.status == "ALL") {
              return (
                checkfunc()
              )
            }
            else if (
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Carry On" && boat.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Wear" && da4x4.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Bag 1" && ddasa4x4x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Bag 2" && da4xdas4xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Bag 3" && da4awdx4xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Bag 4" && da4adsax4xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Bag 5" && da4dasdx4xx.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Carry On" && dayyuu4x422.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Wear" && da4yyx42a.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Bag 1" && da4x4ghh223.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Bag 2" && da4xnn4a2.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Bag 3" && da4xb4a2.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Bag 4" && da4x4va2.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Bag 5" && da4xdasd4a2.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Carry On" && dsa4x4as2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Wear" && da4x4dc2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Bag 1" && da4alx4a2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Bag 2" && da4x994a2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Bag 3" && da4xff4a2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Bag 4" && da4dasdx4a2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Bag 5" && da4x4dasa2x.length ||
              selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
            ) {
              return (
                checkfunc()
                );
              }
          })}
          </div>
                      </AccordionDetails>
                      </Accordion>
            </> ) } ) }
            
          </>
        )
      } else {
        return (
          <>
            {food_sorted.map((key, index) => {
          return ( <>
            <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel-items-content"
                          id="panel-items"
                          style={{marginTop:'0'}}
                        >
                        <Typography>
                        {selectGearCategory?.Category == key || selectGearCategory?.Category == "ANY" ? (
                        <><h3 style={{margin: '0', textTransform: 'uppercase'}}>{key ? key : 'Unassigned'}</h3></>
                        ) : null}
                        </Typography></AccordionSummary>
                        <AccordionDetails>
                        <div className="tpgrid">
          {categorizedDataFoods[key]?.items.map((checkbox, index) => {
              const getselecteddata = foodValue.filter(el => el.id == checkbox.id ? true : false)
              const getalldata = foodValue.filter(el => el.id != checkbox.id ? true : false)
              const category = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packed == false ? true : false)
              const packed = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == false ? true : false)
              const under = getidFood.filter(el => el == checkbox.id ? true : false)
              const both = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == true ? true : false)
              const gaag = foodValue.filter(elaa => elaa.id == checkbox.id ? true : false)
              const boat = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Boat" ? true : false)
              const da4x4 = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
              const da4x42 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
              const da4x42x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
              const da4x42xx = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
              const da4x42xxx = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
              const checkfunction = () => (
                <div className="avoid selection-first">
                <FormControlLabel key={index + checkbox.Name}
                  control={
                    <Checkbox
                      key={`cb-${index}`}
                      name={checkbox.Name}
                      value={checkbox.Name}
                      id={checkbox.Category}
                      checked={under?.length ? true : false}
                      color="primary"
                      onChange={(e) => geFoodID(e, checkbox.id, checkbox)}
                      inputProps={{ "data-weight": checkbox.Weight }}
                    />
                  }
                  label={
                    <span className="mobspanedit" style={{ fontWeight: 'bold' }}>
                      <span style={{ fontWeight: 'normal' }}>
                        {checkbox.Name}
                      </span>
                    </span>}
                />
                <div className="selection-second" style={{borderLeft: '0'}}>
                      {getidFood.map(el => {
                        if (el == checkbox.id) {
                          return (
                            <>
                                {FoodWeight.map(el => {
                                  if (el.id == checkbox.id) {
                                    let number = el.Quantity;
                                    return (
                                      <div className="selection-wrapper">
                                        <TextField value={number} InputLabelProps={{ shrink: true, }}
                                          id="standard-basic"
                                          label="Qty"
                                          name="quantity" onWheel={(e) => e.target.blur()}
                                          className="second-selection-qty"
                                          onChange={e => {
                                            quantityfunctionFood(e.currentTarget.value, checkbox.id, checkbox)
                                          }}
                                          type="Number"
                                          variant="outlined"
                                        />
                                        
                                      </div>
                                    )
                                  }
                                })}
                                         </>
                          )
                        }
                      })}
                      </div>
                </div>
              )
  
              if (
                selectFoodCategory?.Category == checkbox.Category &&
                FoodStatusCheck.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              } else if (
                category.length && FoodStatusCheck.status == "BOUGHT" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory?.Category == checkbox.Category ||
                Foodpacking.status == "ALL" && packed.length &&
                FoodStatusCheck.status == "PACKED" && selectFoodCategory?.Category == checkbox.Category || under.length &&
                Foodpacking.status == "ALL" &&
                FoodStatusCheck.status == "SHOPPING LIST" && selectFoodCategory?.Category == checkbox.Category) {
                return (
                  checkfunction()
                )
              } else if (
                category.length &&
                FoodStatusCheck.status == "BOUGHT" &&
                selectFoodCategory.Category == "ANY" &&
                Foodpacking.status == "ALL" ||
                packed.length &&
                FoodStatusCheck.status == "PACKED" &&
                Foodpacking.status == "ALL" &&
                selectFoodCategory.Category == "ANY" ||
                Foodpacking.status == "ALL" &&
                under.length &&
                FoodStatusCheck.status == "SHOPPING LIST" &&
                selectFoodCategory.Category == "ANY"
              ) {
                return (
                  checkfunction()
                )
              }
              else if (
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "Camper" && boat.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "4x4" && da4x4.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "4x4" && da4x42.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "4x4" && da4x42x.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "PACKED" && Foodpacking.status == "Camper" && da4x42xx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "BOUGHT" && Foodpacking.status == "Camper" && da4x42xxx.length ||
                selectFoodCategory.Category == "ANY" && FoodStatusCheck.status == "ALL" && Foodpacking.status == "ALL"
              ) {
                return (
                  checkfunction()
                );
              }
          })}
          </div>
                        </AccordionDetails>
                        </Accordion>
            </> ) } ) }
            
          </>
        ) } }

  const [selectMealCategory, setSelectMealCategory] = useState(null)
  const handleMealChange = (e) => {
    setSelectMealCategory(e)
  }

  const quantityfunction = (number, id, item) => {
    const filter = mealsValue.filter(el => el.id == id ? true : false)
    const filtersaa = mealsValue.filter(el => el.id != id ? true : false)
    let olddata;
    filter.map(el => {
      olddata = el
    })
    setMealsValue([...filtersaa, { id, ...olddata, Quantity: number, Weight: "0" }])
    const awaaaa = meaweig.map(el => {
      if (el.id == id) {
        return {
          ...el,
          Quantity: number
        }
      } else {
        return {
          ...el
        }
      }
    })
    setmealweightsss(awaaaa)
  }

  const checkboxMealFunctionRender = () => (
    <>
      { Object.keys(categorizedDataMeals).sort().map((key, index) => {
        return ( <>
        <Accordion elevation={1} defaultExpanded={false} className="filter-items-accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0'}}
                      >
                      <Typography>
                      {key == '1breakfast' ?  <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Breakfast</h3></> : null}
          {key == '2lunch' ?  <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Lunch</h3></> : null}
          {key == '3dinner' ?  <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Dinner</h3></> : null}
          {key ?  null : <><h3 style={{margin: '0', textTransform: 'uppercase'}}>Unassigned</h3></>}
                      </Typography></AccordionSummary>
                      <AccordionDetails>
                      <div className="tpgrid">
        {categorizedDataMeals[key].items.map((checkbox, index) => {
          const under = getid.filter(el => el == checkbox.id ? true : false)
          const getselecteddata = mealsValue.filter(el => el.id == checkbox.id ? true : false)
          const getalldata = mealsValue.filter(el => el.id != checkbox.id ? true : false)
          if (selectMealCategory?.Category == checkbox.Category) {
            let result = checkbox.Foods.reduce(function (acc, obj) { return acc + obj.Weight; }, 0);
            return (
              <>
              <div className="tpgrid">
                <FormControlLabel key={index + checkbox.Name} className="avoid sub-select-options" style={{pointerEvents: 'none'}}
                  control={
                    <Checkbox
                      key={`cb-${index}`}
                      name={checkbox.Name}
                      value={(checkbox.Name)}
                      checked={under?.length ? true : false}
                      id={checkbox.Category}
                      color="primary"
                      onChange={(e) => gecheckid(e, checkbox.id, checkbox)}
                      inputProps={{ "data-weight": result }}
                    />
                  }
                  label={
                    <span style={{ fontWeight: 'bold' }}>
                      {checkbox.Category}{" "}
                      <span style={{ fontWeight: 'normal' }}>
                        {checkbox.Name}{" "}
                      </span>
                      <br />
                      {mealsValue?.map(el => {
                        if (el.id == checkbox.id) {
                          return (
                            <>
                              <div>
                                {country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                  <>
                                    <div style={{ display: 'flex', width: '50%' }}>
                                      {tr?.map((item, i) => {
                                        const checka = el?.mealHiking?.filter(a => a.Name == item.Name && a.id == checkbox.id ? true : false)
                                        return (
                                          <FormControlLabel key={i} className="avoid sub-select-options" style={{pointerEvents: 'none'}}
                                            control={
                                              <Checkbox
                                                key={`cb-${i * 6 * 6 * 6}`}
                                                name={(item.Name) + ' ' + (item.Weight)}
                                                checked={checka?.length ? true : false}
                                                color="primary"
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    const getd = getselecteddata.map(els => {
                                                      const getoldda = els?.mealHiking || [];
                                                      const newitem = { Name: item.Name }
                                                      newitem.Quantity = 1
                                                      newitem.id = els.id
                                                      newitem.Foods = els.Foods
                                                      getoldda.push(newitem)
                                                      return {
                                                        ...els,
                                                        mealHiking: getoldda
                                                      }
                                                    })
                                                    const ar = [...getalldata, ...getd]
                                                    setMealsValue(ar)
                                                  } else {
                                                    const getd = getselecteddata.map(els => {
                                                      const fil = els?.mealHiking.filter(u => u.Name != item.Name)
                                                      return {
                                                        ...els,
                                                        mealHiking: fil
                                                      }
                                                    })
                                                    const ar = [...getalldata, ...getd]
                                                    setMealsValue(ar)
                                                  }
                                                }}
                                              />
                                            }
                                            label={
                                              <span style={{ fontWeight: 'bold' }}>
                                                {item.Name}
                                              </span>}
                                          />
                                        )
                                      })}
                                    </div>
                                    <br />
                                    <div>
                                      {el?.mealHiking?.map(it => {
                                        const numbers = it.id == checkbox.id && it.Quantity;
                                        return (
                                          <>
                                            <TextField value={numbers} required InputLabelProps={{ shrink: true, }}
                                              id="standard-basic"
                                              label={it.Name}
                                              name="quantity" onWheel={(e) => e.target.blur()}
                                              InputProps={{
                                                inputProps: { min: 1, inputMode: 'numeric' }
                                              }}
                                              style={{ marginLeft: '10px' }}
                                              onChange={e => {
                                                const getd = getselecteddata.map(els => {
                                                  const getsingledata = els.mealHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                  if (getsingledata?.length) {
                                                    getsingledata[0].Quantity = e.target.value;
                                                  }
                                                  return {
                                                    ...els,
                                                    Quantity: e.target.value
                                                  }
                                                })
                                                const ar = [...getalldata, ...getd]
                                                setMealsValue(ar)
                                              }}
                                              type="number"
                                              variant="outlined"
                                            />
                                            <br />
                                          </>
                                        )
                                      })}
                                    </div>
                                  </>
                                ) : (
                                    <>
                                      {getid.map(el => {
                                        if (el == checkbox.id) {
                                          return (
                                            <>
                                              <div style={{ display: 'flex' }}>
                                                {meaweig.map(el => {
                                                  if (el.id == checkbox.id) {
                                                    let number = el.Quantity;
                                                    return (
                                                      <>
                                                        <div>
                                                          <TextField value={number} required InputLabelProps={{ shrink: true, }}
                                                            fullWidth
                                                            id="standard-basic"
                                                            label="Qty"
                                                            name="quantity" onWheel={(e) => e.target.blur()}
                                                            InputProps={{
                                                              inputProps: { min: 1, inputMode: 'numeric' }
                                                            }}
                                                            onChange={e => {
                                                              quantityfunction(e.currentTarget.value, checkbox.id, checkbox)
                                                            }} type="number" variant="outlined"
                                                          />
                                                        </div>
                                                      </>
                                                    )
                                                  }
                                                })}
                                              </div>
                                            </>
                                          )
                                        }
                                      })}
                                    </>
                                  )}
                              </div>
  
                            </>
                          )
                        }
                      })}
                    </span>}
                />
                </div>
              </>
            );
          } else if (selectMealCategory?.Category == null || selectMealCategory?.Category == "ANY") {
            let result = checkbox.Foods.reduce(function (acc, obj) { return acc + obj.Weight; }, 0);
            return (
              <><div className="avoid">
                <FormControlLabel key={index + checkbox.Name} className="avoid sub-select-options" style={{pointerEvents: 'none'}}
                  control={
                    <Checkbox
                      key={`cb-${index}`}
                      name={checkbox.Name}
                      value={(checkbox.Name)}
                      checked={under?.length ? true : false}
                      id={checkbox.Category}
                      color="primary"
                      onChange={(e) => gecheckid(e, checkbox.id, checkbox)}
                      inputProps={{ "data-weight": result }}
                    />
                  }
                  label={
                    <span style={{ fontWeight: 'bold' }}>
                      <span style={{ fontWeight: 'normal' }}>
                        {checkbox.Name}
                      </span>
                      <br />
                      {mealsValue?.map(el => {
                        if (el.id == checkbox.id) {
                          return (
                            <>
  
                              <div className="foodadjust foodadjustodd" id={"meal-" + country?.value}>
                                {country?.label == "OLD AIR +" ? (
                                  <>
                                      {tr?.map((item, i) => {
                                        const checka = el?.mealHiking?.filter(a => a.Name == item.Name && a.id == checkbox.id ? true : false)
                                        return (
                                          <FormControlLabel key={i} className="avoid sub-select-options" style={{pointerEvents: 'none'}}
                                            control={
                                              <Checkbox
                                                key={`cb-${i * 6 * 6 * 6}`}
                                                name={(item.Name) + ' ' + (item.Weight)}
                                                checked={checka?.length ? true : false}
                                                color="primary"
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    const getd = getselecteddata.map(els => {
                                                      const getoldda = els?.mealHiking || [];
                                                      const newitem = { Name: item.Name }
                                                      newitem.Quantity = 1
                                                      newitem.id = els.id
                                                      newitem.Foods = els.Foods
                                                      getoldda.push(newitem)
                                                      return {
                                                        ...els,
                                                        mealHiking: getoldda
                                                      }
                                                    })
                                                    const ar = [...getalldata, ...getd]
                                                    setMealsValue(ar)
                                                  } else {
                                                    const getd = getselecteddata.map(els => {
                                                      const fil = els?.mealHiking.filter(u => u.Name != item.Name)
                                                      return {
                                                        ...els,
                                                        mealHiking: fil
                                                      }
                                                    })
                                                    const ar = [...getalldata, ...getd]
                                                    setMealsValue(ar)
                                                  }
                                                }}
                                              />
                                            }
                                            label={
                                              <span style={{ fontWeight: 'bold' }}>
                                                {item.Name}
                                              </span>}
                                          />
                                        )
                                      })}
                                    
                                    <div className="foodadjustsub">
                                      {el?.mealHiking?.map(it => {
                                        const numbers = it.id == checkbox.id && it.Quantity;
                                        return (
                                          <>
                                            <TextField value={numbers} required InputLabelProps={{ shrink: true, }}
                                              id="standard-basic"
                                              label={it.Name}
                                              name="quantity" onWheel={(e) => e.target.blur()}
                                              InputProps={{
                                                inputProps: { min: 1, inputMode: 'numeric' }
                                              }}
                                              onChange={e => {
                                                const getd = getselecteddata.map(els => {
                                                  const getsingledata = els.mealHiking.filter(elm => elm.Name == it.Name && elm.id == els.id ? true : false)
                                                  if (getsingledata?.length) {
                                                    getsingledata[0].Quantity = e.target.value;
                                                  }
                                                  return {
                                                    ...els,
                                                    Quantity: e.target.value
                                                  }
                                                })
                                                const ar = [...getalldata, ...getd]
                                                setMealsValue(ar)
                                              }}
                                              type="number"
                                              variant="outlined"
                                            />
                                          </>
                                        )
                                      })}
                                    </div>
                                  </>
                                ) : (
                                    <>
                                      {getid.map(el => {
                                        if (el == checkbox.id) {
                                          return (
                                            <>
                                              <div style={{ display: 'flex' }}>
                                                {meaweig.map(el => {
                                                  if (el.id == checkbox.id) {
                                                    let number = el.Quantity;
                                                    return (
                                                      <>
                                                        <div>
                                                          <TextField value={number} required InputLabelProps={{ shrink: true, }}
                                                            fullWidth
                                                            id="standard-basic"
                                                            label="Qty"
                                                            name="quantity" onWheel={(e) => e.target.blur()}
                                                            InputProps={{
                                                              inputProps: { min: 1, inputMode: 'numeric' }
                                                            }}
                                                            onChange={e => {
                                                              quantityfunction(e.currentTarget.value, checkbox.id, checkbox)
                                                            }} type="number" variant="outlined"
                                                          />
                                                        </div>
                                                      </>
                                                    )
                                                  }
                                                })}
                                              </div>
                                            </>
                                          )
                                        }
                                      })}
                                    </>
                                  )}
                              </div>
  
                            </>
                          )
                        }
                      })}
                    </span>}
                />
                </div>
              </>
            );
          }
        })}
</div>
                      </AccordionDetails>
                      </Accordion>
        
        </>
        )
      }) }

      
    </>

  )
  let [num, setnum] = useState(0)
  useEffect(() => {
    if (num == 0) {
      setnum(1)
    } else if (num == 1) {
      setnum(2)
    } else if (num == 2) {
      setnum(3)
    } else if (num == 3) {
      setnum(4)
      settogle(true)
      CombineeItinerary()
    } else if (num == 4) {
      setnum(5)
      settogle(true)
    } else if (num == 5) {
      setnum(6)
      settogle(true)
    } else {
      setnum(0)
    }
  }, [datefrom, dateto])

  const filteredArr = listItem.reduce((acc, current) => {
    const x = acc.find(item => item.Category === current.Category);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  filteredArr.sort(function (a, b) {
    if (a.Category < b.Category) {
      return -1;
    }
    if (a.Category > b.Category) {
      return 1;
    }
    return 0;
  })
  filteredArr?.unshift({
    Brand: "ANY",
    Category: "ANY",
    Name: "ANY",
    SubCategory: "ANY",
    Weight: "ANY",
    id: "909525454450-0c6b-11ec-90f3-ff0167255365",
    value: "ANY",
  })

  const filteredFood = listItemFood.reduce((acc, current) => {
    const x = acc.find(item => item.Category === current.Category);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  filteredFood.sort(function (a, b) {
    if (a.Category < b.Category) {
      return -1;
    }
    if (a.Category > b.Category) {
      return 1;
    }
    return 0;
  })
  filteredFood?.unshift({
    Brand: "ANY",
    Category: "ANY",
    Name: "ANY",
    SubCategory: "ANY",
    Weight: "ANY",
    id: "9095254554450-0c6b-11ec-90f3-ff0167255365",
    value: "ANY",
  })
  const filteredMeal = listItemMeals.reduce((acc, current) => {
    const x = acc.find(item => item.Category === current.Category);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
  filteredMeal.sort(function (a, b) {
    if (a.Category < b.Category) {
      return -1;
    }
    if (a.Category > b.Category) {
      return 1;
    }
    return 0;
  })
  filteredMeal?.unshift({
    Brand: "Any",
    Category: "ANY",
    Name: "Any",
    SubCategory: "Any",
    Weight: "Any",
    id: "90952545454450-0c6b-11ec-90f3-ff0167255365",
    value: "Any",
  })
  var todayDate = new Date().toISOString().slice(0, 10);
  const newDate = datefrom == '' ? '' : datefrom;


  let sum;
  const [n, setn] = useState(0)
  
  useMemo(() => {
    sum = meaweig.reduce(function (a, b) {
      b.Weight.forEach(function (c) {
        a += Number(c.Weight) * Number(b.Quantity);
      });
      return a;
    }, 0);
    setn(sum)
  }, [meaweig]);

  const getFoodweight = useMemo(() => {
    let initialValue = 0
    return FoodWeight?.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity)
      , initialValue
    )
  }, [FoodWeight]);

  const getdgear = useMemo(() => {
    let initialValue = 0
    return typeValue?.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.Weight) * Number(currentValue.Quantity)
      , initialValue     
    )
  }, [typeValue]);
 
  const [newdara, setNewdara] = useState([])

  const daataa = []
  const red = newdara?.map(el => {
    el?.Foods?.map(e => {
      const newDa = {
        ...e,
        inputQuantity: el.Quantity,
        Category: el.Category
      }
      daataa.push(newDa)
    })
    return {
      Foods: el?.Foods,
    }
  })

 
  function getUniqueDataCount(objArr, propName) {
    const c = [...objArr]
    var data = [];
    return objArr.reduce((c,data)=>{
         c[data[propName]] ??= {...data, inputQuantity:0, foodQuantity:0};
         c[data[propName]].inputQuantity += Number(data.inputQuantity);
         return c;
    },{});
  }

  useMemo(() => {
    setNewdara(mealsValue)
  }, [mealsValue])
  var mydata;
useMemo(() => {
    mydata = Object.values(getUniqueDataCount(daataa, 'label'));
}, [daataa])
  let result = mydata.reduce((c, v) => {
    c[v.label] = (c[v.label] || 0) + parseFloat(v.Quantity);
    return c;
  }, {});
  var newCheck;
  useMemo(() => {
    newCheck = Object.values(mydata?.reduce((acc, cur) => Object.assign(acc, { [cur?.label]: cur }), {}))
  }, [mydata])
  newCheck?.sort(function (a, b) {
    var textA = a.label;
    var textB = b.label;
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const tata = getdgear + getFoodweight + n;
  const tatata = addtraivler.reduce((a,v) =>  a = a + Number(v.Weight) , 0 );
 
  const arja = []
  const ata = mealsValue?.map(el => {
    el?.mealHiking?.map(e => {
      arja.push(e)
    })
  })

  const UpdateCHeckbox2 = (val, item) => {
    const getdataaa = [...meaweig]
    const onedataaaa = getdataaa.map(el => {
      return {
        ...el,
        Weight: el.Weight.map(e => {
          if (e.label == item.label) {
            return {
              ...e,
              packingLocation: val
            }
          } else {
            return {
              ...e,
            }
          }
        })
      }
    })
    setmealweightsss(onedataaaa)
    const getdata = [...mealsValue]
    const onedata = getdata.map(el => {
      return {
        ...el,
        Foods: el.Foods.map(e => {
          if (e.label == item.label) {
            return {
              ...e,
              packingLocation: val
            }
          } else {
            return {
              ...e,
            }
          }
        })
      }
    })
    setMealsValue(onedata)
  }

  const UpdateCHeckbox = (e, i) => {
    const getdata = [...mealsValue]
    if (e.target.checked) {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                packed: true
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setMealsValue(onedata)
    } else {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                packed: false
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setMealsValue(onedata)
    }
  }
  const UpdateCHeckboxboght = (e, i) => {
    const getdata = [...mealsValue]
    if (e.target.checked) {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                bought: true
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setMealsValue(onedata)
    } else {
      const onedata = getdata.map(el => {
        return {
          ...el,
          Foods: el.Foods.map(e => {
            if (e.label == i.label) {
              return {
                ...e,
                bought: false
              }
            } else {
              return {
                ...e,
              }
            }
          })
        }
      })
      setMealsValue(onedata)
    }
  }
  const anyaaafil = newCheck;
  const baseaa = newCheck?.filter(el => el.packingLocation == Foodpackingss.status ? true : false)
  const newawdadaaawrr = Foodpackingss.status == "ALL" ? anyaaafil : baseaa



  const categorizedDataFoods12 = newawdadaaawrr.reduce((acc, curr) => {
    const { id, Category, label, value, inputQuantity, Quantity, packingLocation, bought } = curr;
    const pm = 'abc';
    if (!acc[Category]) {
      acc[Category] = {
        items: [],
      };
    }
    acc[Category].items.push({ Category, label, value, inputQuantity, Quantity, packingLocation, bought, id });
    return acc;
  }, {});


  const diffInMs = new Date(dateto) - new Date(datefrom)
  const diffInDays = diffInMs == 60000 ? 0 : diffInMs / (1000 * 60 * 60 * 24) + 1;

  const gettotaldistance = useMemo(() => {
    let initialValue = 0
    return inputFields?.reduce(
      (previousValue, currentValue) => previousValue + Number(currentValue.Distance)
      , initialValue
    )
  });
  const lastnumberofdata = inputFields?.length - 1

  typeValue?.sort(function (a, b) {
    if (a.Name < b.Name) { return -1; }
    if (a.Name > b.Name) { return 1; }
    return 0;
  })
  const travawa = listItemUser?.length ? listItemUser[0]?.Travellers != undefined ? [...listItemUser[0]?.Travellers] : [] : []
  travawa?.sort(function (a, b) {
    var textA = a.Name.toUpperCase();
    var textB = b.Name.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  travawa?.unshift({
    Name: 'ANY'
  })
  travawa?.push({
    Name: 'Shared'
  })
  typeValue?.sort(function (a, b) {
    var textA = a.Category.toUpperCase();
    var textB = b.Category.toUpperCase();
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });
  const categorizedDatas = typeValue.reduce((acc, curr) => {
    const { id, Category, CategoryId, Quantity, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, packingLocation } = curr;
    if (!acc[CategoryId]) {
      acc[CategoryId] = {
        items: [],
      };
    }
    acc[CategoryId].items.push({ Category, CategoryId, Quantity, packingLocation, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  const combinedValues = [...typeValue, ...foodValue];

  const categorizedDatas3 = combinedValues.reduce((acc, curr) => {
    const { id, Category, CategoryId, Quantity, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, packingLocation } = curr;
    if (!acc[packingLocation]) {
      acc[packingLocation] = {
        items: [],
      };
    }
    acc[packingLocation].items.push({ Category, CategoryId, Quantity, packingLocation, SubCategory, SubCategoryId, Name, Brand, Qty, Owner, Status, Weight, RepairNotes, Kit, KitDetails, LoanedTo, StorageLocation, id });
    return acc;
  }, {});

  
  const getCategoryPacked = (key) => {
    const foundCategorykey = categorizedDatas[key].items[0].CategoryId
    const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
    if (foundCategory) {
    return foundCategory.label
    } else {
      return '-'
    }
}

const getCategorySortPacked = (key) => {
  const foundCategorykey = categorizedDatas[key].items[0].CategoryId
  const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
  if (foundCategory) {
  return foundCategory.catid
  } else {
    return '-'
  }
}

const getCategoryPackedAlt = (key) => {
  const foundCategorykey = categorizedDatas2[key].items[0].CategoryId
  const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
  if (foundCategory) {
  return foundCategory.label
  } else {
    return '-'
  }
}

const getCategorySortPackedAlt = (key) => {
const foundCategorykey = categorizedDatas2[key].items[0].CategoryId
const foundCategory = newata?.GearCategories?.find((element) => element.id == foundCategorykey)
if (foundCategory) {
return foundCategory.catid
} else {
  return '-'
}
}

  /* Calculate for each mode of travel */
  
  var resSet4x4=typeValue.filter(function(options){
    return options.packingLocation == "4x4";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSet4x4F=foodValue.filter(function(options){
    return options.packingLocation == "4x4";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSet4x4M = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
    Quantity: x.Quantity
  })) : x.Foods).filter(function(options) {
    return options.packingLocation == "4x4";
  }).reduce(function(a, b) {
    return a + parseFloat(b.Weight * b.Quantity);
  }, 0);

  var resSetCamper=typeValue.filter(function(options){
    return options.packingLocation == "Camper";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSetCamperF=foodValue.filter(function(options){
    return options.packingLocation == "Camper";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

  var resSetCamperM = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
    Quantity: x.Quantity
  })) : x.Foods).filter(function(options) {
    return options.packingLocation == "Camper";
  }).reduce(function(a, b) {
    return a + parseFloat(b.Weight * b.Quantity);
  }, 0);

  var resSetCaravan=typeValue.filter(function(options){
    return options.packingLocation == "Caravan";
    }).reduce(function(a,b){
    return a + parseFloat(b.Weight * b.Quantity);
  },0);

    var resSetCaravanF=foodValue.filter(function(options){
      return options.packingLocation == "Caravan";
      }).reduce(function(a,b){
      return a + parseFloat(b.Weight * b.Quantity);
    },0);

    var resSetCaravanM = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
      Quantity: x.Quantity
    })) : x.Foods).filter(function(options) {
      return options.packingLocation == "Caravan";
    }).reduce(function(a, b) {
      return a + parseFloat(b.Weight * b.Quantity);
    }, 0);

    var resSetBoat=typeValue.filter(function(options){
      return options.packingLocation == "Boat";
      }).reduce(function(a,b){
      return a + parseFloat(b.Weight * b.Quantity);
    },0);

      var resSetBoatF=foodValue.filter(function(options){
        return options.packingLocation == "Boat";
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetBike=typeValue.filter(function(options){
        return options.packingLocation == "Motorbike";
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
  
        var resSetBikeF=foodValue.filter(function(options){
          return options.packingLocation == "Motorbike";
          }).reduce(function(a,b){
          return a + parseFloat(b.Weight * b.Quantity);
        },0);

      /* HIKING CALCS */

      var resSetHikeF0=foodValue.filter(function(options){
        return options.packingLocation == "PACK - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF1=foodValue.filter(function(options){
        return options.packingLocation == "PACK - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF2=foodValue.filter(function(options){
        return options.packingLocation == "PACK - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      
      var resSetHikeF3=foodValue.filter(function(options){
        return options.packingLocation == "PACK - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF4=foodValue.filter(function(options){
        return options.packingLocation == "PACK - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeF5=foodValue.filter(function(options){
        return options.packingLocation == "PACK - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG0=typeValue.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG1=typeValue.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG2=typeValue.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG3=typeValue.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG4=typeValue.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeG5=typeValue.filter(function(options){
        return options.packingLocation == "Consumables" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW0=typeValue.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW1=typeValue.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW2=typeValue.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW3=typeValue.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW4=typeValue.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGBW5=typeValue.filter(function(options){
        return options.packingLocation == "Base Weight" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW0=typeValue.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW1=typeValue.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW2=typeValue.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW3=typeValue.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW4=typeValue.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGW5=typeValue.filter(function(options){
        return options.packingLocation == "Wear" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB0=typeValue.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB1=typeValue.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB2=typeValue.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB3=typeValue.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB4=typeValue.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHikeGTB5=typeValue.filter(function(options){
        return options.packingLocation == "Travel Bag" && options.Owner == tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetHike0 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "PACK - " + tr[0]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);
      var resSetHike1 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "PACK - " + tr[1]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);
      var resSetHike2 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "PACK - " + tr[2]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);
      var resSetHike3 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "PACK - " + tr[3]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);
      var resSetHike4 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "PACK - " + tr[4]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      /* END HIKING CALCS */

      /* AIR+ CALCS */
      var resSetAirGBW0=typeValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirGBW1=typeValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirGBW2=typeValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirGBW3=typeValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirGBW4=typeValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirGBW5=typeValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);


      var resSetAirWE0=typeValue.filter(function(options){
        return options.packingLocation == "WEAR - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirWE1=typeValue.filter(function(options){
        return options.packingLocation == "WEAR - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirWE2=typeValue.filter(function(options){
        return options.packingLocation == "WEAR - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirWE3=typeValue.filter(function(options){
        return options.packingLocation == "WEAR - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirWE4=typeValue.filter(function(options){
        return options.packingLocation == "WEAR - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirWE5=typeValue.filter(function(options){
        return options.packingLocation == "WEAR - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirBAG10=typeValue.filter(function(options){
        return options.packingLocation == "BAG1 - "  + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG20=typeValue.filter(function(options){
        return options.packingLocation == "BAG2 - "  + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG30=typeValue.filter(function(options){
        return options.packingLocation == "BAG3 - "  + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG40=typeValue.filter(function(options){
        return options.packingLocation == "BAG4 - "  + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG50=typeValue.filter(function(options){
        return options.packingLocation == "BAG5 - "  + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirBAG11=typeValue.filter(function(options){
        return options.packingLocation == "BAG1 - "  + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG21=typeValue.filter(function(options){
        return options.packingLocation == "BAG2 - "  + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG31=typeValue.filter(function(options){
        return options.packingLocation == "BAG3 - "  + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG41=typeValue.filter(function(options){
        return options.packingLocation == "BAG4 - "  + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG51=typeValue.filter(function(options){
        return options.packingLocation == "BAG5 - "  + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirBAG12=typeValue.filter(function(options){
        return options.packingLocation == "BAG1 - "  + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG22=typeValue.filter(function(options){
        return options.packingLocation == "BAG2 - "  + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG32=typeValue.filter(function(options){
        return options.packingLocation == "BAG3 - "  + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG42=typeValue.filter(function(options){
        return options.packingLocation == "BAG4 - "  + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG52=typeValue.filter(function(options){
        return options.packingLocation == "BAG5 - "  + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirBAG13=typeValue.filter(function(options){
        return options.packingLocation == "BAG1 - "  + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG23=typeValue.filter(function(options){
        return options.packingLocation == "BAG2 - "  + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG33=typeValue.filter(function(options){
        return options.packingLocation == "BAG3 - "  + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG43=typeValue.filter(function(options){
        return options.packingLocation == "BAG4 - "  + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG53=typeValue.filter(function(options){
        return options.packingLocation == "BAG5 - "  + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirBAG14=typeValue.filter(function(options){
        return options.packingLocation == "BAG1 - "  + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG24=typeValue.filter(function(options){
        return options.packingLocation == "BAG2 - "  + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG34=typeValue.filter(function(options){
        return options.packingLocation == "BAG3 - "  + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG44=typeValue.filter(function(options){
        return options.packingLocation == "BAG4 - "  + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG54=typeValue.filter(function(options){
        return options.packingLocation == "BAG5 - "  + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirBAG15=typeValue.filter(function(options){
        return options.packingLocation == "BAG1 - "  + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG25=typeValue.filter(function(options){
        return options.packingLocation == "BAG2 - "  + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG35=typeValue.filter(function(options){
        return options.packingLocation == "BAG3 - "  + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG45=typeValue.filter(function(options){
        return options.packingLocation == "BAG4 - "  + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
      var resSetAirBAG55=typeValue.filter(function(options){
        return options.packingLocation == "BAG5 - "  + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);
     
      var resSetAirFCO0=foodValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFCO1=foodValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFCO2=foodValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFCO3=foodValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFCO4=foodValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFCO5=foodValue.filter(function(options){
        return options.packingLocation == "CARRY - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG10=foodValue.filter(function(options){
        return options.packingLocation == "BAG1 - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG11=foodValue.filter(function(options){
        return options.packingLocation == "BAG1 - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG12=foodValue.filter(function(options){
        return options.packingLocation == "BAG1 - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG13=foodValue.filter(function(options){
        return options.packingLocation == "BAG1 - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG14=foodValue.filter(function(options){
        return options.packingLocation == "BAG1 - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG15=foodValue.filter(function(options){
        return options.packingLocation == "BAG1 - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG20=foodValue.filter(function(options){
        return options.packingLocation == "BAG2 - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG21=foodValue.filter(function(options){
        return options.packingLocation == "BAG2 - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG22=foodValue.filter(function(options){
        return options.packingLocation == "BAG2 - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG23=foodValue.filter(function(options){
        return options.packingLocation == "BAG2 - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG24=foodValue.filter(function(options){
        return options.packingLocation == "BAG2 - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG25=foodValue.filter(function(options){
        return options.packingLocation == "BAG2 - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG30=foodValue.filter(function(options){
        return options.packingLocation == "BAG3 - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG31=foodValue.filter(function(options){
        return options.packingLocation == "BAG3 - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG32=foodValue.filter(function(options){
        return options.packingLocation == "BAG3 - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG33=foodValue.filter(function(options){
        return options.packingLocation == "BAG3 - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG34=foodValue.filter(function(options){
        return options.packingLocation == "BAG3 - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG35=foodValue.filter(function(options){
        return options.packingLocation == "BAG3 - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG40=foodValue.filter(function(options){
        return options.packingLocation == "BAG4 - " + tr[0]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG41=foodValue.filter(function(options){
        return options.packingLocation == "BAG4 - " + tr[1]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG42=foodValue.filter(function(options){
        return options.packingLocation == "BAG4 - " + tr[2]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG43=foodValue.filter(function(options){
        return options.packingLocation == "BAG4 - " + tr[3]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG44=foodValue.filter(function(options){
        return options.packingLocation == "BAG4 - " + tr[4]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirFBAG45=foodValue.filter(function(options){
        return options.packingLocation == "BAG4 - " + tr[5]?.Name;
        }).reduce(function(a,b){
        return a + parseFloat(b.Weight * b.Quantity);
      },0);

      var resSetAirMC0 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "CARRY - " + tr[0]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG10 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG1 - " + tr[0]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG20 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG2 - " + tr[0]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG30 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG3 - " + tr[0]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG40 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG4 - " + tr[0]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMC1 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "CARRY - " + tr[1]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG11 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG1 - " + tr[1]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG21 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG2 - " + tr[1]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG31 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG3 - " + tr[1]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG41 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG4 - " + tr[1]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMC2 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "CARRY - " + tr[2]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG12 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG1 - " + tr[2]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG22 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG2 - " + tr[2]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG32 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG3 - " + tr[2]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG42 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG4 - " + tr[2]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMC3 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "CARRY - " + tr[3]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG13 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG1 - " + tr[3]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG23 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG2 - " + tr[3]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG33 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG3 - " + tr[3]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG43 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG4 - " + tr[3]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMC4 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "CARRY - " + tr[4]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG14 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG1 - " + tr[4]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG24 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG2 - " + tr[4]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG34 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG3 - " + tr[4]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      var resSetAirMBAG44 = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "BAG4 - " + tr[4]?.Name;
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);

      /* END AIR+ CALCS */

      var resSetBoatM = mealsValue.flatMap(x => x.Quantity ? x.Foods.map(food => ({ ...food,
        Quantity: x.Quantity
      })) : x.Foods).filter(function(options) {
        return options.packingLocation == "Boat";
      }).reduce(function(a, b) {
        return a + parseFloat(b.Weight * b.Quantity);
      }, 0);
  

      const maam = []
      typeValue?.map(el => {
        el?.gearHiking?.map(els => {
          const data = {
            Quantity: els.Quantity,
            packingLocation: els.packingLocation,
            Name: el.Name,
            Owner: els.Name,
            RealOwner: el.Owner,
            Brand: el.Brand,
            id: el.id,
            Category: el.Category,
            CategoryId: el.CategoryId,
            SubCategory: el.SubCategory,
            SubCategoryId: el.SubCategoryId,
            Weight: el.Weight,
            Status: el.Status
          }
          maam.push(data)
        })
      });
      
      maam?.sort(function (a, b) {
        var textA = a?.Category?.toUpperCase();
        var textB = b?.Category?.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      
      const categorizedDatas2 = maam.reduce((acc, curr) => {
        const { id, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Owner, RealOwner, packingLocation, Quantity, Weight, Status } = curr;
        const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === CategoryId)
        const realcat = categorymatch[0].label
        if (!acc[Owner]) {
          acc[Owner] = {
            items: [],
          };
        }
        acc[Owner].items.push({ id, realcat, Category, CategoryId, SubCategory, SubCategoryId, Name, Brand, Owner, RealOwner, packingLocation, Quantity, Weight, Status });
        return acc;
      }, {});

  const handleChangeFrom = (event) => {
    const getdate = format(event, 'MM/dd/yyyy')
    setDateFrom(event.target.value);
  };

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;
  
    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 0,
              top: 8
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  const [open, setOpen] = React.useState(false);
  
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const noop = () => {};
  
  const elementRef = React.createRef();

  const savePDF = () => {
    // See https://rawgit.com/MrRio/jsPDF/master/docs/jsPDF.html
    
    var randomno = Math.random()

    const doc = new jsPDF({
      orientation: "p",
      unit: "pt",
      format: "letter"
    });
    // doc.addImage('/logo_black.png', 'PNG', 40, 10, 183, 20)
    autoTable(doc, {
      html: '.table-packing-pdf',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf0',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf1',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf2',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf3',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    autoTable(doc, {
      html: '.table-packing-pdf4',
      includeHiddenHtml: false,
      theme: 'grid',
      headStyles: { fontSize: '8', fillColor: [0, 0, 0] },
      bodyStyles: {fontSize: '8'},
    })
    doc.setFontSize(7)
    doc.text('ADVENTURE CURATED TRIP PLANNER',40,15)
    doc.output('bloburl');
    let pdfOutput = doc.output();
    if (isPlatform("ios")) {
      // for iOS device
      const directory = File.tempDirectory;
      const fileName = `Trip_Planner`+randomno+`.pdf`
      File.writeFile(directory, fileName, pdfOutput, true).then(success => {
        FileOpener.showOpenWithDialog(directory + fileName, 'application/pdf')
          .then(() => console.log('File opened'))
          .catch(e => console.log('Error opening file', e));
      },
        err => {
          console.log(" writing File error : ", err)
        })
    } else {
      // for desktop
      doc.save('Trip_Planner'+randomno+'.pdf');
    }
    
  };

  { /* function handleCRClick(event) {
    if (event.target.value === value) {
      console.log(this)
      setValue("");
    } else {
      setValue(event.target.value);
    }
  } */ }

  const [value, setValue] = React.useState("");

  function handleClick(event) {
    if (event.target.value === value) {
      setValue("");
    } else {
      setValue(event.target.value);
    }
  }

  function onDragEnd(result) {
    const newItems = [...inputFields];
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setInputFields(newItems)
  }

  if (getdataT?.DistancePref == true || getdataT?.DistancePref == undefined) {
    var distancei = 'km';
  } else {
    var distancei = 'mi';
  }

  return (

    <div className="additem addtrip additemmain">
      <Accordion elevation={0} expanded={expanded} defaultExpanded={false} onChange={handleChangeA("panel")}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{display:'none'}}
        >
        </AccordionSummary>
        <AccordionDetails className="addtripacc">
          <form id="component-form" onSubmit={onSubmit} onChange={()=>(setDirty(true), bottomnavtarget.classList.add('cf'))}>
            <AppBar className="trip-nav" position="static" color="default">
              <Tabs value={selectedTab} onChange={handleTabChange} variant="fullWidth" aria-label="" scrollButtons="off">
                <Tab icon={<FontAwesomeIcon icon={faInfoCircle} size="lg" />} onClick={() => setSaveState(true)} label={<><b>INFORMATION</b></>} wrapped />
                <Tab onClick={() => {
                  if (!inputFields?.length) {
                    setInputFields([
                      { Date: '', From: '', To: '', Distance: '', Stay: '', Notes: '', Activities: '', Document: [] }
                    ])
                  }
                }} icon={<FontAwesomeIcon icon={faCalendarDays} size="lg" />} label={<><b>ITINERARY</b></>} wrapped />
                { /*
                <Tooltip leaveTouchDelay={1000} TransitionComponent={Zoom} enterTouchDelay={0} title="Budget" placement="top" arrow>
                <Tab icon={<FontAwesomeIcon icon={faSackDollar} size="lg" />} onClick={() => setSaveState(true)} />
              </Tooltip> */ }
                <Tab icon={<FontAwesomeIcon icon={faCampground} size="lg" />} onClick={() => setSaveState(true)} label={<><b>TRIP GEAR</b></>} wrapped />
                { /*
                <Tooltip leaveTouchDelay={1000} TransitionComponent={Zoom} enterTouchDelay={0} title="Meal Plan" placement="top" arrow>
                <Tab icon={<FontAwesomeIcon icon={faUtensils} size="lg" />} onClick={() => setSaveState(true)} />
                </Tooltip> */ }
                <Tab icon={<FontAwesomeIcon icon={faUtensils} size="lg" />} onClick={() => setSaveState(true)} label={<><b>TRIP FOOD</b></>} wrapped />
                <Tab icon={<FontAwesomeIcon icon={faWeightScale} size="lg" />} onClick={() => setSaveState(true)} label={<><b>WEIGHTS</b></>} wrapped />
                <Tab icon={<FontAwesomeIcon icon={faListCheck} size="lg" />} onClick={() => setSaveState(true)} label={<><b>PACKING LIST</b></>} wrapped />
                { /* country ? 
                <Tooltip>
                {country.label == '4x4' || country.label == '4x4 TOWING CAMPER' || country.label == '4x4 TOWING CARAVAN' || country.label == '4x4 TOWING BOAT' || country.label == 'MOTORBIKE' ? 
                <Tab className="stackedicons" icon={<><FontAwesomeIcon icon={faWeightScale} size="lg" /><FontAwesomeIcon className="stackicon" icon={faGauge} size="lg" /></>}  onClick={() => setSaveState(true)} label={<><b>WEIGHTS</b></>} wrapped />
                :
                <Tab icon={<FontAwesomeIcon icon={faWeightScale} size="lg" />} onClick={() => setSaveState(true)} label={<><b>WEIGHTS</b></>} wrapped /> }
                </Tooltip>
                :
                <Tooltip>
                <Tab icon={<FontAwesomeIcon icon={faWeightScale} size="lg" />} onClick={() => setSaveState(true)} label={<><b>WEIGHTS</b></>} wrapped />
              </Tooltip> */ }
              </Tabs>
            </AppBar>
            {selectedTab === 0 && (
              <>
                <div style={{ position: 'relative' }}>
                  <TextField required InputLabelProps={{ shrink: true, }} inputProps={{ required: true }} fullWidth id="standard-basic" label="Trip Name" value={name} name="name" onChange={e => setName(e.currentTarget.value)} type="text" variant="outlined" />
                  <FormControlLabel className="bucketlist-toggle" control={<Switch checked={state.checkedA} onChange={handleChange} name="checkedA" color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }} />} label={bucket ? <StarIcon fontSize="large" style={{ color: 'gold' }} /> : <StarBorderIcon fontSize="large" style={{ color: 'gold' }} /> } />
                </div>
                
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                  <div style={{ width: '100%' }}>
                    <Select menuPortalTarget={document.body} fullWidth isSearchable={ false } placeholder="Mode of Travel" value={country}
                      options={data_meals}
                      onChange={handleCountryChange}
                      getOptionLabel={x => x.label}
                      getOptionValue={x => x.value}
                      className="select indent selectalternate"
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: '#f1f1f1',
                          primary: '#202020',
                        },
                      })}
                      components={{
                        ValueContainer: CustomValueContainer
                      }}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          marginTop: 50,
                          paddingLeft: "11px"
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                          borderColor: "#fff !important",
                          padding: "0 !important"
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: "#fff !important",
                          boxShadow: "none"
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                          left: "-4px",
                          background: "#fff",
                          padding: "0 5px",
                          transition: "top 0.5s, font-size 0.1s",
                          color: "#A2A2A2",
                          fontSize: (state.hasValue || state.selectProps.inputValue) && 12
                        })
                      }} />
                  </div>
                  <div className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'tooltip-select' : 'notooltip'} style={{ top: '25px', right: '50px', position: 'absolute' }}>
                    <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="Select your mode of travel to utilise the customised feature sets." placement="top-start" arrow>
                      <IconButton className="tooltip" size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div className="indent" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Date From"}
                  inputFormat="dd/MM/yyyy"
                  disableCloseOnSelect={false}
                  disablePast={false}
                  value={datefrom == new Date("2099-12-31T00:00:00Z").toUTCString() ? null : datefrom}
                  // minDate={new Date()}
                  onChange={(newValue) => {
                    setDirty(true);
                    setDateFrom(format(newValue, 'MM/dd/yyyy'));
                    setDateTo(format(newValue, 'MM/dd/yyyy'));
                    if (dateto == '') {
                    setDateTo(format(newValue, 'MM/dd/yyyy'));
                    }
                  }}
                  renderInput={(params) => <TextField variant="outlined" {...params} helperText={null} fullWidth />}
                />
              </LocalizationProvider>
                  <div style={{ top: '28px', right: '50px', position: 'absolute' }}>
                    <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="When changing dates you will need to manually adjust the itinerary. This is to ensure existing planning is not lost." placement="top-start" arrow>
                      <IconButton className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'tooltip' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                    </Tooltip>
                  </div>
                </div>
                <div className="indent" style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                  label={"Date To"}
                  inputFormat="dd/MM/yyyy"
                  disablePast={false}
                  value={dateto == new Date("2099-12-31T00:01:00Z").toUTCString() ? null : dateto}
                  // minDate={new Date(datefrom)}
                  onChange={(newValue) => {
                    setDirty(true);
                    setDateTo(format(newValue, 'MM/dd/yyyy'));
                  }}
                  renderInput={(params) => <TextField variant="outlined" {...params} helperText={null} fullWidth />}
                />
              </LocalizationProvider>
                  <div style={{ top: '28px', right: '50px', position: 'absolute' }}>
                    <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} title="When changing dates you will need to manually adjust the itinerary. This is to ensure existing planning is not lost." placement="top-start" arrow>
                      <IconButton className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'tooltip' : 'notooltip'} size="small" aria-label="edit"><NotListedLocationIcon /></IconButton>
                    </Tooltip>
                  </div>
                </div>
                <Button variant="outlined" onClick={(newValue) => {
                    setDateFrom(new Date("2099-12-31T00:00:00Z").toUTCString());
                    setDateTo(new Date("2099-12-31T00:01:00Z").toUTCString());
                  }}>CLEAR DATES</Button>&nbsp;
                  { /* <Button variant="outlined" onClick={(newValue) => {
                    setDateFrom(date.setDate(date.getDate() + 1));
                    setDateTo(date.setDate(date.getDate() + 6));
                  }}>RESET DATES</Button> */ }
                  <div style={{ height: '5px' }}></div>
                {dateto != '' && datefrom != 'Thu, 31 Dec 2099 00:00:00 GMT' ? (
                  
                  <TextField disabled InputLabelProps={{ shrink: true, }} fullWidth id="standard-basic" label="Number of Days" value={Math.ceil(diffInDays)} name="name" type="number" variant="outlined" />
                ) : (
                    <>
                        <TextField onKeyPress={(event) => { if (event?.key === '-' || event?.key === '+') { event.preventDefault(); } }} InputLabelProps={{ shrink: true, }} inputProps={{ min: 1 }} fullWidth id="standard-basic" label="Number of Days" value={noofdays} name="name" onChange={e => setnoofdays(e.currentTarget.value)} type="number" variant="outlined" />
                    </>
                  )}
                  <div className="travellerscontainer">
                    <label className="travellerslabel">Travellers</label>
                {ListOfTraivlers(listItemUser, addtraivler, setaddTraverler)}
                <Tooltip className={getdataT?.TipsPref == true || getdataT?.TipsPref == undefined ? 'travellerstooltip' : 'notooltip'} leaveTouchDelay={3000} enterTouchDelay={50} placement="top-start" arrow title="You can save Travellers in your Profile. Access Profile via the right icon in the header menu.">
          <IconButton size="small">
          <NotListedLocationIcon />
          </IconButton>
          </Tooltip>
                </div>
              </>
            )}
            {selectedTab === 1 && (
              <>    
              <div className="imain">
              {uploadingImage == true ? null : <div className="loadingcontainer loadingcontaineralt"><div className="loading"><CircularProgress style={{'color': '#ef4923'}} /></div></div>}
                 { /* <TextField disabled value={gettotaldistance} InputLabelProps={{ shrink: true, }} id="standard-basic" label="Total Distance" name="Date" type="number" variant="outlined" />&nbsp;&nbsp;<br /><br /> */ }
                <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div className="droppable" {...provided.droppableProps} ref={provided.innerRef}>
                {inputFields.map((inputField, index) => {
                  return (
                      <Draggable key={'item-'+index} draggableId={'item-'+index} index={index}>
                      {(provided) => (
                        <Accordion disableGutters={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <Typography><DragIndicatorIcon className="mobilehide"></DragIndicatorIcon> <span className="iheader"><b>{'DAY' + ' ' + (index + 1)}</b> {new Date(inputField.Date).toLocaleDateString()} <b style={{paddingLeft: '10px'}}>DISTANCE: </b> {inputField.Distance ? inputField.Distance + distancei : 'n/a' }</span> <span className="itineraryquick"><b>STAY: </b> {inputField.Stay ? inputField.Stay : 'n/a'}</span></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        
                      <div className="itineraryflex">
                        <div style={{maxWidth:'150px', position:'relative'}}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                      
                  <MobileDatePicker
                  label={'Day' + ' ' + (index + 1)}
                  inputFormat="dd/MM/yyyy"
                  closeOnSelect={true}
                  value={inputField.Date}
                  minDate={new Date()}
                  views={['day']}
                  onChange={(newValue) => {
                    handleInputChange(index, event, newValue);
                  }}
                  renderInput={(params) => <TextField value={inputField.Date} variant="outlined" {...params} />}
                />
              </LocalizationProvider>
              <br/><span className="mobilehide">{index !== 0 ? (
                        <>
                        <Button color="primary" component="span" style={{color: '#757575'}} onClick={() => handleRemoveFields(index)}>
                        <RemoveCircleIcon />&nbsp;&nbsp;DELETE DAY
                        </Button>
                      </>
                      ) : <><IconButton style={{display: 'none'}} size="small" aria-label="edit" onClick={() => handleRemoveFields(index)}><RemoveCircleIcon /></IconButton></> }
                      </span>
                      </div>
                      { /* <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label="From" value={inputField.From} name="From" onChange={event => handleInputChange(index, event)} type="text" variant="outlined" />&nbsp;&nbsp;
                      <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label="To" value={inputField.To} name="To" onChange={event => handleInputChange(index, event)} type="text" variant="outlined" />&nbsp;&nbsp; */ }
                      <div style={{maxWidth:'250px'}}>
                      <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label={'Distance (' + distancei + ')'} value={inputField.Distance} name="Distance" onChange={event => handleInputChange(index, event)} type="text" variant="outlined" />
                      <TextField InputLabelProps={{ shrink: true, }} id="standard-basic" label="Stay" value={inputField.Stay} name="Stay" onChange={event => handleInputChange(index, event)} type="text" variant="outlined" />
                      </div>
                      <div className="alternatelabel">
                      {provided.placeholder}
                      <label>Travel Details</label>
                      <TextareaAutosize value={inputField.Notes} name="Notes" onChange={event => handleInputChange(index, event)} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0', minHeight: '132px' }}></TextareaAutosize>
                      </div>
                      <div className="alternatelabel">
                      {provided.placeholder}
                      <label>Sights &amp; Activities</label>
                      <TextareaAutosize value={inputField.Activities} name="Activities" onChange={event => handleInputChange(index, event)} style={{ width: '100%', border: '1px solid #c4c4c4', borderRadius: '3px', resize: 'none', fontFamily: 'inherit', fontSize: 'inherit', color: 'inherit', padding: '18.5px 14px', boxSizing: 'border-box', outline: 'none', margin: '10px 0', minHeight: '132px' }}></TextareaAutosize>
                      </div>
                <div className="downloadcontainer" style={{maxWidth:'250px'}}>
                <span className="mobilehide" style={{position:'relative', display:'block', height:'35px'}}>
                      <input className="documentupload" name="Document" filename={inputField.Document} accept="image/*,.pdf,.docx,.xlsx,.gpx,text/xml,text/gpsxml,application/gpsxml" style={{top:0}} id="icon-button-file" type="file" onChange={event => handleInputChange(index, event)} />
  <label htmlFor="icon-button-file" style={{left:'0'}}>
    <Button color="primary" component="span" style={{color: '#757575', paddingLeft: '0', position:'relative'}}>
      <CloudUploadIcon />&nbsp;&nbsp;Upload File
    </Button>
  </label></span>
  {inputField?.Document?.length ? (
                <>
                <div className="downloadcontainer">
                  {inputField.Document.map(el => {
                    const deleteFromFirebases = (url) => {
                      //1.
                      let pictureRef = storage.refFromURL(url);
                      //2.
                      pictureRef.delete()
                        .then(() => {
                          alert("File has been removed");
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    };
                  return ( <div className="directdownload btndownload">
                    <div className="downloadbuttons btndownload">
                  <Button className="btndownload" variant="outlined"><a target="_blank" href={el.url}>{el.name}</a> { /* <IconButton size="small" aria-label="delete" onClick={() => deleteFromFirebases(el.url)}><DeleteIcon /></IconButton> */ }</Button>
                  </div></div> )
                  })}</div></>) : null }
  </div>

                      { /* MOBILE DISPLAY */ }
                      <div className="desktophide mobileitinerarybtns">
                      <div style={{textAlign:'right'}}>
                      {index !== 0 ? (
                        <>
                        <Button color="primary" component="span" style={{color: '#757575'}} onClick={() => handleRemoveFields(index)}>
                        <RemoveCircleIcon />&nbsp;&nbsp;DELETE DAY
                        </Button>
                      </>
                      ) : <><IconButton style={{display: 'none'}} size="small" aria-label="edit" onClick={() => handleRemoveFields(index)}><RemoveCircleIcon /></IconButton></> }
                      </div>
                      <div style={{position:'relative'}}>
                      <input className="documentupload" name="Document" filename={inputField.Document} accept="image/*,.pdf,.docx,.xlsx,.gpx,text/xml,text/gpsxml,application/gpsxml" style={{top:0}} id="icon-button-file" type="file" onChange={event => handleInputChange(index, event)} />
  <label htmlFor="icon-button-file">
    <Button color="primary" component="span" style={{color: '#757575'}}>
      <CloudUploadIcon />&nbsp;&nbsp;Upload File
    </Button>
  </label>
  </div>
                      </div>
                    </div></AccordionDetails></Accordion>)}
                    </Draggable>
                  )
                })}
                {provided.placeholder}
                </div>)}
                
          </Droppable>
        </DragDropContext>
        </div>
        <br/>
                <Button variant="outlined" style={{width:'120px', fontSize:'0.8rem'}} onClick={() => handleAddFields()}><AddCircleIcon />&nbsp;&nbsp;ADD DAY</Button><br/>
                <br/>
                <table className="table-packing-pdf" style={{display: 'none'}}>
                <thead>
                      <tr>
                          <th>Date</th>
                          <th>Stay</th>
                          <th>Activities</th>
                          <th>Notes</th>
                          </tr>
                        </thead>
                {inputFields.map((inputField, index) => {
                  return (
                    <>
                <tr>
                  <td>{inputField.Date.split("-").reverse().join("/")}</td>
                  <td>{inputField.Stay}</td>
                  <td>{inputField.Activities}</td>
                  <td>{inputField.Notes}</td>
                </tr>
                </>
                  )})}
                </table>
                { /* <Button variant="outlined" onClick={savePDF}>Save as PDF</Button> <br/><br/> */ }
              </>
            )}
            {selectedTab === 2 && (
              <>
              <div className="select4filter tripbuttons" style={{height:'auto', marginBottom:'20px !important'}}>
              <Button onClick={() => setSelectedTab(4)} className="btntripw" variant="outlined" size="large"><FontAwesomeIcon icon={faWeightScale} size="sm" /> {getdgear.toFixed(2)}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}</Button>
            </div>
             <div style={{ clear: 'both'}}></div>
                 <div style={{display: 'flex', flexDirection: 'column'}}>
                {packeditem == false ? (
                  <>
                    {checkboxGearFunctionRender()}
                  </>
                ) : null }
                  </div>
                  {packeditem == true ? <>
                  <br/><Button variant="outlined" onClick={savePDF}>Save as PDF</Button></> : null }
                <br /><br />
              </>
            )}
            {selectedTab === 3 && (
              <>
                <div className="select4filter tripbuttons" style={{height:'auto', marginBottom:'20px !important'}}>
                <Button onClick={() => setSelectedTab(4)} className="btntripw" variant="outlined" size="large"><FontAwesomeIcon icon={faWeightScale} size="sm" /> {getFoodweight.toFixed(2)}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}</Button>
                </div>
                {packeditem2 == false ? (
                <> { /* <Select menuPortalTarget={document.body} isSearchable={ false } placeholder="Category" value={selectFoodCategory}
                  options={filteredFood}
                  onChange={handleFoodChange}
                  getOptionLabel={x => x.Category}
                  getOptionValue={x => x.Category}
                  className="select select4filter selectalternate"
                  theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                      ...theme.colors,
                      primary25: '#f1f1f1',
                      primary: '#202020',
                    },
                  })}
                  components={{
                    ValueContainer: CustomValueContainer
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      marginTop: 50,
                      paddingLeft: "11px"
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                      borderColor: "#fff !important",
                      padding: "0 !important"
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: "#fff !important",
                      boxShadow: "none"
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                      left: "-4px",
                      background: "#fff",
                      padding: "0 5px",
                      transition: "top 0.5s, font-size 0.1s",
                      color: "#A2A2A2",
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                    })
                  }}
                /> */ }
                { /* <Select menuPortalTarget={document.body} placeholder="Status (Packed/Bought)" value={FoodStatusCheck}
                  options={stat}
                  onChange={(e) => setFoodStatusCheck(e)}
                  getOptionLabel={x => x.status}
                  getOptionValue={x => x.status}
                  className="select select4filter"
                  components={{
                    ValueContainer: CustomValueContainer
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      marginTop: 50,
                      paddingLeft: "11px"
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                      borderColor: "#fff !important",
                      padding: "0 !important"
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: "#fff !important",
                      boxShadow: "none"
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                      left: "-4px",
                      background: "#fff",
                      padding: "0 5px",
                      transition: "top 0.5s, font-size 0.1s",
                      color: "#A2A2A2",
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                    })
                  }}
                /> */ }
                {country?.label == "AIR +" || country?.label == "4x4 TOWING CAMPER" || country?.label == "HIKING" || country?.label == "BIKEPACKING" || country?.label == "4x4 TOWING CARAVAN" || country?.label == "4x4 TOWING BOAT" ? (
                  <>
                    { /* <Select menuPortalTarget={document.body} placeholder="Packing Location" value={Foodpacking}
                      options={slectdata}
                      onChange={(e) => setfoodpacking(e)}
                      getOptionLabel={x => x.status}
                      getOptionValue={x => x.status}
                      className="select select4filter select4filterover"
                      components={{
                        ValueContainer: CustomValueContainer
                      }}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          marginTop: 50,
                          paddingLeft: "11px"
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                          borderColor: "#fff !important",
                          padding: "0 !important"
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: "#fff !important",
                          boxShadow: "none"
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                          left: "-4px",
                          background: "#fff",
                          padding: "0 5px",
                          transition: "top 0.5s, font-size 0.1s",
                          color: "#A2A2A2",
                          fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                        })
                      }}
                    /> */ }
                  </>
                ) : null}
                <div style={{ clear: 'both' }}></div>
                  {checkboxFoodFunctionRender()}
                <br /><br /></>
                ) : (
                  <>{foodValue.length >= 1 ? 
                <><div style={{ clear: 'both' }}></div>
                {food_sorted.map((key, index) => {
                          return (
                            <>
                            <div className="packed_gear__">
                            {categorizedDataFoods1[key]?.items.length >= 1 ?
                            <>
                            <Accordion style={{borderRadius:'0'}} elevation={1} defaultExpanded={false} className="filter-items-accordion-packing">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0.5px'}}
                      >
                      <Typography>
                                    <h3 style={{margin:'0',textTransform:'uppercase'}}>{`${key == 'undefined' || key == '' || key == 'To Pack' ? "To Pack" : key}`}</h3>
                                    </Typography></AccordionSummary>
                      <AccordionDetails>
                      <TableContainer component={Paper} elevation={0}>
                            <div className="table-responsive-vertical-meals">
                            <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell className="fourhundred"><TableSortLabel>
                                  INGREDIENT
                                </TableSortLabel></TableCell>
                                { /* <TableCell className="onehundredtwenty"><TableSortLabel>
                                  QUANTITY
                            </TableSortLabel></TableCell> */ }
                                <TableCell className="twohundred"><TableSortLabel>
                                  SHOPPING
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  PACKING
                                </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {categorizedDataFoods1[key]?.items
    .sort(function (aa, ab) {
        
      var categoryA = aa.Category.toUpperCase();
      var categoryB = ab.Category.toUpperCase();
      if (categoryA !== categoryB) {
        // Sort by category
        return categoryA < categoryB ? -1 : 1;
      }
           
      var nameA = aa.Name.toUpperCase();
      var nameB = ab.Name.toUpperCase();
      if (nameA !== nameB) {
        // Sort by owner
        return nameA < nameB ? -1 : 1;
      }

    })
      .map((checkbox, index) => {
                              const getselecteddata = foodValue.filter(el => el.id == checkbox.id ? true : false)
                              const getalldata = foodValue.filter(el => el.id != checkbox.id ? true : false)
                              const category = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packed == false ? true : false)
                              const packed = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == false ? true : false)
                              const under = getidFood.filter(el => el == checkbox.id ? true : false)
                              const both = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.bought == true ? true : false)
                              const gaag = foodValue.filter(elaa => elaa.id == checkbox.id ? true : false)
                              const boat = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "Boat" ? true : false)
                              const da4x4 = foodValue.filter(el => el.id == checkbox.id && el.packingLocation == "4x4" ? true : false)
                              const da4x42 = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "4x4" ? true : false)
                              const da4x42x = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "4x4" ? true : false)
                              const da4x42xx = foodValue.filter(el => el.id == checkbox.id && el.packed == true && el.packingLocation == "Boat" ? true : false)
                              const da4x42xxx = foodValue.filter(el => el.id == checkbox.id && el.bought == true && el.packingLocation == "Boat" ? true : false)
                              return (
                                    <TableRow key={checkbox.Name} id={checkbox.id}>
                                      <TableCell data-title="MEAL - NAME">
                                      <span className="qtybold">{checkbox.Quantity} x </span> {checkbox.Name}
                                      </TableCell>
                                      { /* <TableCell className="eighty" data-title="MEAL - QUANTITY">
                                        <div>{checkbox.Quantity}</div>
                              </TableCell> */ }
                                      <TableCell className="onehundredtwenty" data-title="MEAL - SHOPPING">
                                      <FormControlLabel key={index + checkbox.Name} style={{display: 'inline-block', width: 'auto', pointerEvents: 'none'}} className="avoid sub-select-options"
                              control={
                                <Checkbox
                                  key={`cb-${index}`}
                                  name={checkbox.Name}
                                  value={(checkbox.Name)}
                                  checked={category.length || both.length ? true : false}
                                  id={checkbox.Category}
                                  color="primary"
                                  onChange={(e) => handleTypeCheckboxChangeFood(e, checkbox.id, checkbox, true, false, "bought")}
                                  inputProps={{ "data-weight": checkbox.Weight }}
                                />
                              }
                              label={<span style={{ fontWeight: 'normal' }}>Bought</span>}
                            />
                                      </TableCell>
                                      <TableCell>
                                      <RadioGroup value={checkbox.packingLocation ? checkbox.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={e => {
                                      const val = e.target.value
                                      const getd = getselecteddata.map(els => {
                                        return {
                                          ...els,
                                          packingLocation: val,
                                        }
                                      })
                                      const ar = [...getalldata, ...getd]
                                      setFoodValue(ar)
                                      }}>
                                      { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                      {country?.label == "4x4 TOWING CARAVAN" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
                                      </>
                                      ) : country?.label == "4x4 TOWING BOAT" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                      </>
                                      ) : country?.label == "MOTORBIKE" ? (
                                        <>
                                      <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Motorbike" />
                                      </>
                                      ) : country?.label == "4x4" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      </>
                                      ) : country?.label == "4x4 TOWING CAMPER" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                                      </>
                                      ) : country?.label == "AIR +" ? (
                                        <>
                                        {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              <FormControlLabel key={i} value={"CARRY - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Carry On"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG1 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 1"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG2 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 2"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG3 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 3"}
                                                  
                                              />
                                              { /* <FormControlLabel key={i} value={"BAG4 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 4"}
                                                  
                                              /> */ }
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                            { /* <FormControlLabel value="Carry On" control={<Radio color="primary" />} label="Packed Carry On" />
                                            <FormControlLabel value="Bag 1" control={<Radio color="primary" />} label="Packed Bag 1" />
                                            <FormControlLabel value="Bag 2" control={<Radio color="primary" />} label="Packed Bag 2" />
                                            <FormControlLabel value="Bag 3" control={<Radio color="primary" />} label="Packed Bag 3" />
                                            <FormControlLabel value="Bag 4" control={<Radio color="primary" />} label="Packed Bag 4" /> */ }
                                      </>
                                      ) : country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                        <>
                                      {tr?.map((item, i) => {
                                            return (
                                              <>
                                              
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                      </>
                                      ) : <>n/a</>
                                      
                                      }
                                    </RadioGroup>
                                      </TableCell>
                                      </TableRow>
                              )
                            })}
                                      </TableBody>
                            </Table></div></TableContainer>
                      </AccordionDetails></Accordion>
                            </> : null}
                            </div></>)})}
                { /*
                { Object.keys(categorizedDataFoods1).sort().map((key, index) => {
        return ( <>
          <><h3 style={{margin: '20px 0 10px 0', color: '#ef4723', textTransform: 'uppercase'}}><ChevronRightIcon fontSize="small" /> {key ? key : 'Unassigned'}</h3></>
          <TableContainer component={Paper} elevation={0}>
                        <div className="table-responsive-vertical-meals">
                          <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  INGREDIENT
                                </TableCell>
                                <TableCell className="onehundredtwenty">
                                  QUANTITY
                                </TableCell>
                                <TableCell>
                                  SHOPPING
                                </TableCell>
                                <TableCell>
                                  PACKING
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
          {categorizedDataFoods1[key].items.map((checkbox, index) => {
            console.log(checkbox);
            console.log(foodValue);
            return (
            <>
            <TableRow key={checkbox.Name} id={checkbox.id}>
            <TableCell data-title="MEAL - NAME">
                                        {checkbox.Name}
                                      </TableCell>
                                      <TableCell data-title="MEAL - QUANTITY">
                                        <div>{checkbox.Quantity}</div>
                                      </TableCell>
                                      <TableCell data-title="MEAL - SHOPPING">
                                      <FormControlLabel key={index + checkbox.Name} style={{display: 'inline-block', width: 'auto'}} className="avoid sub-select-options"
                              control={
                                <Checkbox
                                  key={`cb-${index}`}
                                  name={checkbox.Name}
                                  value={(checkbox.Name)}
                                  checked={checkbox.packed}
                                  id={checkbox.Category}
                                  color="primary"
                                  onChange={(e) => handleTypeCheckboxChangeFood1(e, checkbox.id, checkbox, true, false, "bought")}
                                  inputProps={{ "data-weight": checkbox.Weight }}
                                />
                              }
                              label={<span style={{ fontWeight: 'normal' }}>Bought</span>}
                            />
                                      </TableCell>
                                      <TableCell>
                                      <RadioGroup value={checkbox.packingLocation ? checkbox.packingLocation : "To Pack"} defaultValue="To Pack" row
                                      onChange={e => {
                                      const val = e.target.value
                                      const getd = getselecteddata.map(els => {
                                        return {
                                          ...els,
                                          packingLocation: val,
                                        }
                                      })
                                      const ar = [...getalldata, ...getd]
                                      setFoodValue(ar)
                                      console.log(ar)
                                      }}>
                                      {country?.label == "4x4 TOWING CARAVAN" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="Packed 4x4" />
                                      <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Packed Caravan" />
                                      </>
                                      ) : country?.label == "4x4 TOWING BOAT" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                      <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                      </>
                                      ) : country?.label == "BIKEPACKING" ? (
                                        <>
                                      <FormControlLabel value="Bike" control={<Radio color="primary" />} label="Packed Mountain Bike" />
                                      </>
                                      ) : country?.label == "MOTORBIKE" ? (
                                        <>
                                      <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Packed Motorbike" />
                                      </>
                                      ) : country?.label == "4x4" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="Packed 4x4" />
                                      </>
                                      ) : country?.label == "4x4 TOWING CAMPER" ? (
                                        <>
                                      <FormControlLabel value="4x4" control={<Radio color="primary" />} label="Packed 4x4" />
                                      <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Packed Camper" />
                                      </>
                                      ) : country?.label == "AIR +" ? (
                                        <>
                                            <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="To Pack" />
                                            <FormControlLabel value="Carry On" control={<Radio color="primary" />} label="Packed Carry On" />
                                            <FormControlLabel value="Bag 1" control={<Radio color="primary" />} label="Packed Bag 1" />
                                            <FormControlLabel value="Bag 2" control={<Radio color="primary" />} label="Packed Bag 2" />
                                            <FormControlLabel value="Bag 3" control={<Radio color="primary" />} label="Packed Bag 3" />
                                            <FormControlLabel value="Bag 4" control={<Radio color="primary" />} label="Packed Bag 4" />
                                            <FormControlLabel value="Bag 5" control={<Radio color="primary" />} label="Packed Bag 5" />
                                      </>
                                      ) : country?.label == "HIKING" ? (
                                        <>
                                      {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="To Pack" />
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Pack Consumables"}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                      </>
                                      ) : <>n/a</>
                                      
                                      }
                                      
                                      
                                    </RadioGroup>
                                      </TableCell>
                                      </TableRow>
            </>
            )
          })}
          </TableBody></Table></div></TableContainer>
        </> ) })} */}
                <br/><br/>
                                      <div style={{display:'none'}}>
                    <table className="table-packing-pdf">
                    <thead>
                      <tr>
                          <th>Ingredient</th>
                          <th>Quantity</th>
                          <th>Shopping</th>
                          <th>Packing Location</th>
                          </tr>
                        </thead>
                        {foodValue.map((checkbox, index) => {
        return (
                      <tr><td>{checkbox.Name}</td><td>{checkbox.Quantity}</td><td>{checkbox.bought == true ? 'Bought' : '-'}</td><td>
                    {checkbox.packingLocation ? checkbox.packingLocation : '-'}
                    </td></tr>
        )})}</table>
                  </div>
                  <Button variant="outlined" onClick={savePDF}>Save as PDF</Button><br/><br/>
                </>
                : <><div style={{ clear: 'both', height: '20px' }}></div>Your list is currently empty.<div style={{ clear: 'both', height: '40px' }}></div></>}</>)}
                
              </>
            )}
            {selectedTab === 99 && (
              <>
              <h4>MEAL PLAN <span className="h4weights"><FontAwesomeIcon icon={faWeightScale} size="sm" /> {n.toFixed(2)}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}</span></h4>
              <div className="select4filter" style={{height:'auto', marginBottom:'20px !important'}}>
                <Button className={(packeditem3 == true) ? "btntoggle" : "btntoggle btntoggled"} variant="outlined" size="large" onClick={e => setpackeditem3(false)}><FontAwesomeIcon icon={faPencil} size="lg" /> PLANNING</Button> <Button className={(packeditem3 == false) ? "btntoggle" : "btntoggle btntoggled"} variant="outlined" size="large" onClick={e => setpackeditem3(true)}><FontAwesomeIcon icon={faListCheck} size="lg" /> PACKING</Button>
                </div>
                { /* <Select menuPortalTarget={document.body} placeholder="Category" value={selectMealCategory}
                  options={filteredMeal}
                  onChange={handleMealChange}
                  getOptionLabel={x => x.Category}
                  getOptionValue={x => x.Category}
                  className="select select4filter"
                  components={{
                    ValueContainer: CustomValueContainer
                  }}
                  styles={{
                    container: (provided, state) => ({
                      ...provided,
                      marginTop: 50,
                      paddingLeft: "11px"
                    }),
                    valueContainer: (provided, state) => ({
                      ...provided,
                      overflow: "visible",
                      borderColor: "#fff !important",
                      padding: "0 !important"
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      borderColor: "#fff !important",
                      boxShadow: "none"
                    }),
                    placeholder: (provided, state) => ({
                      ...provided,
                      position: "absolute",
                      top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                      left: "-4px",
                      background: "#fff",
                      padding: "0 5px",
                      transition: "top 0.5s, font-size 0.1s",
                      color: "#A2A2A2",
                      fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                    })
                  }}
                /> */ }
                <div style={{clear:'both'}}></div>
                {packeditem3 == false ? (
                  <>
                {country?.label == "AIR +" || country?.label == "4x4 TOWING CAMPER" || country?.label == "HIKING" || country?.label == "BIKEPACKING" || country?.label == "4x4 TOWING CARAVAN" || country?.label == "4x4 TOWING BOAT" ? (
                  <>
                    { /* <Select menuPortalTarget={document.body} placeholder="Packing Location" value={Foodpackingss}
                      options={slectdata}
                      onChange={(e) => setfoodpackingss(e)}
                      getOptionLabel={x => x.status}
                      getOptionValue={x => x.status}
                      className="select select4filter select4filterover"
                      components={{
                        ValueContainer: CustomValueContainer
                      }}
                      styles={{
                        container: (provided, state) => ({
                          ...provided,
                          marginTop: 50,
                          paddingLeft: "11px"
                        }),
                        valueContainer: (provided, state) => ({
                          ...provided,
                          overflow: "visible",
                          borderColor: "#fff !important",
                          padding: "0 !important"
                        }),
                        control: (provided, state) => ({
                          ...provided,
                          borderColor: "#fff !important",
                          boxShadow: "none"
                        }),
                        placeholder: (provided, state) => ({
                          ...provided,
                          position: "absolute",
                          top: state.hasValue || state.selectProps.inputValue ? -13 : "50%",
                          left: "-4px",
                          background: "#fff",
                          padding: "0 5px",
                          transition: "top 0.5s, font-size 0.1s",
                          color: "#A2A2A2",
                          fontSize: (state.hasValue || state.selectProps.inputValue) && 13
                        })
                      }}
                    /> */ }
                    <div style={{clear: 'both'}}></div>
                  </>
                ) : null}
                
                  {checkboxMealFunctionRender()}
                <br /><br />
                  </>
                ) : (
                  <>
                  {country?.label == "OLD AIR +" ? (
                  <>
                    {arja?.length ? (
                      <TableContainer component={Paper} elevation={0}>
                        <div className="table-responsive-vertical-meals">
                        <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell><TableSortLabel>
                                  INGREDIENT
                                </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel>
                                  QUANTITY
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  SHOPPING
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  PACKING
                                </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                            {arja?.map((item, i) => {
                              let it = item.Quantity
                              const anyfil = item.Foods;
                              const base = item.Foods.filter(el => el.packingLocation == Foodpackingss.status ? true : false)
                              const newawdadawrr = Foodpackingss.status == "ALL" ? anyfil : base
                              return (
                                <>
                                  {newawdadawrr?.map(el => {
                                    return (
                                      <TableBody key={i}>
                                        <TableRow hover key={item.label} id={item.label}>
                                          <TableCell data-title="MEAL - NAME">
                                            <div>
                                              <b>{item.Name}</b><br/>
                                              <span>{el.label}</span>
                                            </div>
                                          </TableCell>
                                          <TableCell data-title="MEAL - QUANTITY">
                                          <div><span>{Number(el.Quantity).toFixed(0)}</span></div>
                                      </TableCell>
                                          <TableCell data-title="MEAL - SHOPPING">
                                            <FormControlLabel style={{display: 'inline-block', width: 'auto', pointerEvents: 'none'}} className="avoid sub-select-options"
                                              control={
                                                <Checkbox
                                                  checked={el.bought == true ? true : false}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      const newdataswa = mealsValue.map(o => {
                                                        if (o.id == item.id) {
                                                          return {
                                                            ...o,
                                                            mealHiking: o.mealHiking.map(es => {
                                                              if (es.Name == item.Name) {
                                                                return {
                                                                  ...es,
                                                                  Foods: es.Foods.map(ea => {
                                                                    if (ea.label == el.label) {
                                                                      return {
                                                                        ...ea,
                                                                        bought: true
                                                                      }
                                                                    } else {
                                                                      return {
                                                                        ...ea
                                                                      }
                                                                    }
                                                                  })
                                                                }
                                                              } else {
                                                                return {
                                                                  ...es
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...o
                                                          }
                                                        }
                                                      })
                                                      setMealsValue(newdataswa)
                                                    } else {
                                                      const newdataswa = mealsValue.map(o => {
                                                        if (o.id == item.id) {
                                                          return {
                                                            ...o,
                                                            mealHiking: o.mealHiking.map(es => {
                                                              if (es.Name == item.Name) {
                                                                return {
                                                                  ...es,
                                                                  Foods: es.Foods.map(ea => {
                                                                    if (ea.label == el.label) {
                                                                      return {
                                                                        ...ea,
                                                                        bought: false
                                                                      }
                                                                    } else {
                                                                      return {
                                                                        ...ea
                                                                      }
                                                                    }
                                                                  })
                                                                }
                                                              } else {
                                                                return {
                                                                  ...es
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...o
                                                          }
                                                        }
                                                      })
                                                      setMealsValue(newdataswa)
                                                    }
                                                  }}
                                                />
                                              }
                                              label={<span>Bought</span>}
                                            />
                                            { /* <FormControlLabel style={{display: 'inline-block', width: 'auto'}} className="avoid sub-select-options"
                                              control={
                                                <Checkbox
                                                  checked={el.packed == true ? true : false}
                                                  onChange={(e) => {
                                                    if (e.target.checked) {
                                                      const newdataswa = mealsValue.map(o => {
                                                        if (o.id == item.id) {
                                                          return {
                                                            ...o,
                                                            mealHiking: o.mealHiking.map(es => {
                                                              if (es.Name == item.Name) {
                                                                return {
                                                                  ...es,
                                                                  Foods: es.Foods.map(ea => {
                                                                    if (ea.label == el.label) {
                                                                      return {
                                                                        ...ea,
                                                                        packed: true
                                                                      }
                                                                    } else {
                                                                      return {
                                                                        ...ea
                                                                      }
                                                                    }
                                                                  })
                                                                }
                                                              } else {
                                                                return {
                                                                  ...es
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...o
                                                          }
                                                        }
                                                      })
                                                      setMealsValue(newdataswa)
                                                    } else {
                                                      const newdataswa = mealsValue.map(o => {
                                                        if (o.id == item.id) {
                                                          return {
                                                            ...o,
                                                            mealHiking: o.mealHiking.map(es => {
                                                              if (es.Name == item.Name) {
                                                                return {
                                                                  ...es,
                                                                  Foods: es.Foods.map(ea => {
                                                                    if (ea.label == el.label) {
                                                                      return {
                                                                        ...ea,
                                                                        packed: false
                                                                      }
                                                                    } else {
                                                                      return {
                                                                        ...ea
                                                                      }
                                                                    }
                                                                  })
                                                                }
                                                              } else {
                                                                return {
                                                                  ...es
                                                                }
                                                              }
                                                            })
                                                          }
                                                        } else {
                                                          return {
                                                            ...o
                                                          }
                                                        }
                                                      })
                                                      setMealsValue(newdataswa)
                                                    }
                                                  }}
                                                />
                                              }
                                              label={<span>Pack</span>}
                                            /> */ }
                                          </TableCell>
                                          
                                          <TableCell data-title="MEAL - PACKING">
                                            
                                                <RadioGroup value={el.packingLocation ? el.packingLocation : "To Pack"} defaultValue="To Pack" row  onChange={e => {
                                                  const val = e.target.value
                                                  const newdataswa = mealsValue.map(o => {
                                                    if (o.id == item.id) {
                                                      return {
                                                        ...o,
                                                        mealHiking: o.mealHiking.map(es => {
                                                          if (es.Name == item.Name) {
                                                            return {
                                                              ...es,
                                                              Foods: es.Foods.map(ea => {
                                                                if (ea.label == el.label) {
                                                                  return {
                                                                    ...ea,
                                                                    packingLocation: val
                                                                  }
                                                                } else {
                                                                  return {
                                                                    ...ea
                                                                  }
                                                                }
                                                              })
                                                            }
                                                          } else {
                                                            return {
                                                              ...es
                                                            }
                                                          }
                                                        })
                                                      }
                                                    } else {
                                                      return {
                                                        ...o
                                                      }
                                                    }
                                                  })
                                                  setMealsValue(newdataswa)
                                                }}>
                                                  { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                                  <FormControlLabel value="Carry On" control={<Radio color="primary" />} label="Packed Carry On" />
                                                  <FormControlLabel value="Wear" control={<Radio color="primary" />} label="Wear" />
                                                  <FormControlLabel value="Bag 1" control={<Radio color="primary" />} label="Packed Bag 1" />
                                                  <FormControlLabel value="Bag 2" control={<Radio color="primary" />} label="Packed Bag 2" />
                                                  <FormControlLabel value="Bag 3" control={<Radio color="primary" />} label="Packed Bag 3" />
                                                  { /* <FormControlLabel value="Bag 4" control={<Radio color="primary" />} label="Packed Bag 4" /> */ }
                                                </RadioGroup>

                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    )
                                  })}
                                </>
                              )
                            })}
                          </Table>
                        </div>
                      </TableContainer>
                    ) : null}
                  <br/><br/>
                  </>
                ) : null}
                {country?.label != "" ? (
                  <>
                  {newCheck?.length ? (
                      <>
                      {meal_sorted.map((key, index) => {
                          return (
                            <>
                            {categorizedDataFoods12[key]?.items.length >= 1 ?
                            <>
                            <Accordion style={{borderRadius:'0'}} elevation={1} defaultExpanded={false} className="filter-items-accordion-packing">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-items-content"
                        id="panel-items"
                        style={{marginTop:'0.5px'}}
                      >
                      <Typography>
                                    <h3 style={{margin:'0',textTransform:'uppercase'}}>{`${key == 'undefined' || key == '' || key == 'To Pack' ? "To Pack" : key}`}</h3>
                                    </Typography></AccordionSummary>
                      <AccordionDetails>
                      <TableContainer component={Paper} elevation={0}>
                        <div className="table-responsive-vertical-meals">
                          <Table className="table-packing" size="small">
                            <TableHead>
                              <TableRow>
                                <TableCell className="fourhundred"><TableSortLabel>
                                  INGREDIENT
                                </TableSortLabel></TableCell>
                                <TableCell className="onehundredtwenty"><TableSortLabel>
                                  QUANTITY
                                </TableSortLabel></TableCell>
                                <TableCell className="twohundred"><TableSortLabel>
                                  SHOPPING
                                </TableSortLabel></TableCell>
                                <TableCell><TableSortLabel>
                                  PACKING
                                </TableSortLabel></TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                            {categorizedDataFoods12[key]?.items
    .map((checkbox, index) => {
        return ( <>
        <TableRow>
        <TableCell data-title="MEAL - NAME">{checkbox.label}</TableCell>
        <TableCell className="eighty" data-title="MEAL - QUANTITY"><div>{Number(checkbox.inputQuantity * checkbox.Quantity)}</div></TableCell>
        <TableCell className="onehundredtwenty" data-title="MEAL - SHOPPING">
                                        <FormControlLabel style={{display: 'inline-block', width: 'auto', pointerEvents: 'none'}} className="avoid sub-select-options"
                                          control={
                                            <Checkbox
                                              checked={checkbox.bought == true ? true : false}
                                              onChange={(e) => UpdateCHeckboxboght(e, checkbox)}
                                            />
                                          }
                                          label={<span>Bought</span>}
                                        />
                                      </TableCell>
                                      <TableCell data-title="MEAL - PACKING">
                                        {country?.label == "BIKEPACKING" ? (
                                          <RadioGroup value={checkbox.packingLocation} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                            <FormControlLabel value="To Pack" control={<Radio color="primary" selected />} label="No" />
                                            <FormControlLabel value="Bike" control={<Radio color="primary" />} label="Mountain Bike" />
                                          </RadioGroup>
                                        ) : country?.label == "MOTORBIKE" ? (
                                          <RadioGroup value={checkbox.packingLocation} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                            <FormControlLabel value="To Pack" control={<Radio color="primary" selected />} label="No" />
                                            <FormControlLabel value="Motorbike" control={<Radio color="primary" />} label="Motorbike" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4" ? (
                                          <RadioGroup value={checkbox.packingLocation} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                            <FormControlLabel value="To Pack" control={<Radio color="primary" selected />} label="No" />
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4 TOWING CAMPER" ? (
                                          <>
                                          <RadioGroup value={checkbox.packingLocation ? checkbox.packingLocation : "To Pack"} defaultValue="To Pack" row onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                            <FormControlLabel value="To Pack" control={<Radio color="primary" selected />} label="No" />
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Camper" control={<Radio color="primary" />} label="Camper" />
                                          </RadioGroup>
                                          </>
                                        ) : country?.label == "4x4 TOWING CARAVAN" ? (
                                          <RadioGroup value={checkbox.packingLocation} defaultValue="To Pack" row onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Caravan" control={<Radio color="primary" />} label="Caravan" />
                                          </RadioGroup>
                                        ) : country?.label == "4x4 TOWING BOAT" ? (
                                          <RadioGroup value={checkbox.packingLocation} defaultValue="To Pack" row onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                            { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                            <FormControlLabel value="4x4" control={<Radio color="primary" />} label="4x4" />
                                            <FormControlLabel value="Boat" control={<Radio color="primary" />} label="Boat" />
                                          </RadioGroup>
                                        ) : country?.label == "AIR +" ? (
                                          <RadioGroup value={checkbox.packingLocation ? checkbox.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                          <>
                                          { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                          {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              <FormControlLabel key={i} value={"CARRY - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Carry On"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG1 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 1"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG2 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 2"}
                                                  
                                              />
                                              <FormControlLabel key={i} value={"BAG3 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 3"}
                                                  
                                              />
                                              { /* <FormControlLabel key={i} value={"BAG4 - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name + "'s Bag 4"}
                                                  
                                              /> */ }
                                              <div style={{flexBasis: '100%', height: '0'}}></div>
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                          </>
</RadioGroup> 
                                        ) : country?.label == "HIKING" || country?.label == "BIKEPACKING" ? (
                                          <RadioGroup value={checkbox.packingLocation ? checkbox.packingLocation : "To Pack"} defaultValue="To Pack" row
                                          onChange={e => {
                                            const val = e.target.value
                                            UpdateCHeckbox2(val, checkbox)
                                          }}>
                                          <>
                                          { /* <FormControlLabel value="To Pack" control={<Radio color="primary" />} label="No" /> */ }
                                          {tr?.map((item, i) => {
                                            return (
                                              <>
                                              <FormControlLabel key={i} value={"PACK - " + item.Name}
                                                control={
                                                  <Radio
                                                    key={`cb-${i * 6 * 6 * 6}`}
                                                    name={(item.Name) + ' ' + (item.Weight)}
                                                    color="primary"
                                                  />
                                                }
                                                label={item.Name}
                                                  
                                              />
                                              </>
                                            )
                                          })}
                                          {tr.length >= 1 ? null : <><em>You need to add Travellers to assign a packing location</em></>}
                                          </>
</RadioGroup>
                                        ) : <>n/a</>}
                                      </TableCell>
        </TableRow>
        </> ) })}
        </TableBody></Table></div></TableContainer>
                      </AccordionDetails>
                      </Accordion></> : null }
                            
                            </>
                          )})}
                      </>
                    ) : <><div style={{ clear: 'both', height: '20px' }}></div>Your list is currently empty.<div style={{ clear: 'both', height: '40px' }}></div></>}
                  
                    
                  <br/><Button variant="outlined" onClick={savePDF}>Save as PDF</Button><br/><br/>
                  </>
                ) : null}
                  <div style={{display:'none'}}>
                    <table className="table-packing-pdf">
                    <thead>
                      <tr>
                          <th>Ingredient</th>
                          <th>Quantity</th>
                          <th>Shopping</th>
                          <th>Packing Location</th>
                          </tr>
                        </thead>
                    {newawdadaaawrr?.map((item, i) => {
                      let it = item.inputQuantity
                      return (
                        <>
                      <tr><td>{Object.keys(result).map(function (key) {
                                            if (key == item.label) {
                                              return (
                                                <>
                                                  <span>{key}</span>
                                                  <span></span>
                                                </>
                                              )
                                            }
                                          })}</td><td>{Object.keys(result).map(function (key) {
                                            if (key == item.label) {
                                              return (
                                                <>
                                                  <span>{Number(result[key]) * Number(it)}</span>
                                                  <span></span>
                                                </>
                                              )
                                            }
                                          })}</td><td>{item.bought == true ? 'Bought' : '-'}</td><td>
                    {item.packingLocation == undefined ? '-' : <>{item.packingLocation}</>}
                    </td></tr>
                    </> )
                    })}</table>
                  </div>
                  </>
                )}
                
              </>
            )}
            {selectedTab === 4 && (      
              <>
                <div className="cleantextfield cleantextfortrip" id={country?.label == "HIKING" || country?.label == "AIR +" || country?.label == "BIKEPACKING" ? "hiking-weights-overview" : "weights-overview"}>
                {country?.label == "HIKING" ? (
                <>
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0 + resSetHikeGBW0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[1]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[2]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[3]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[4]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[5]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= PACK TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                </div>
                </>
                ) : null}

                {country?.label == "BIKEPACKING" ? (
                <>
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW0.toFixed(2)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF0 + resSetHikeG0 + resSetHike0 + resSetHikeGBW0).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[1]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[2]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[3]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[4]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[5]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BASE" value={resSetHikeGBW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="+ CONSUMABLES" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= BIKE TOTAL" value={(resSetHikeF1 + resSetHikeG1 + resSetHike1 + resSetHikeGBW1).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetHikeGW1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TRAVEL BAG" value={resSetHikeGTB1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                </div>
                </>
                : null}
                </div>
                </>
                ) : null}

{country?.label == "AIR +" ? (
                <>
                <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW0 + resSetAirFCO0 + resSetAirMC0).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG10 + resSetAirFBAG10 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG20 + resSetAirFBAG20 + resSetAirMBAG20).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG30 + resSetAirFBAG30 + resSetAirMBAG30).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG40 + resSetAirFBAG40 + resSetAirMBAG40).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE0.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
                </>
                : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[1]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW1 + resSetAirFCO1 + resSetAirMC1).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG11 + resSetAirFBAG11 + resSetAirMBAG11).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG21 + resSetAirFBAG21 + resSetAirMBAG21).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG31 + resSetAirFBAG31 + resSetAirMBAG31).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG41 + resSetAirFBAG41 + resSetAirMBAG41).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE1.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
                </>
                : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[2]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW2 + resSetAirFCO2 + resSetAirMC2).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG12 + resSetAirFBAG12 + resSetAirMBAG12).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG22 + resSetAirFBAG22 + resSetAirMBAG22).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG32 + resSetAirFBAG32 + resSetAirMBAG32).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG42 + resSetAirFBAG42 + resSetAirMBAG42).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE2.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[3]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW3 + resSetAirFCO3 + resSetAirMC3).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG13 + resSetAirFBAG13 + resSetAirMBAG13).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG23 + resSetAirFBAG23 + resSetAirMBAG23).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG33 + resSetAirFBAG33 + resSetAirMBAG33).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG43 + resSetAirFBAG43 + resSetAirMBAG43).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE3.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[4]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW4 + resSetAirFCO4 + resSetAirMC4).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG14 + resSetAirFBAG14 + resSetAirMBAG14).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG24 + resSetAirFBAG24 + resSetAirMBAG24).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG34 + resSetAirFBAG34 + resSetAirMBAG34).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG44 + resSetAirFBAG44 + resSetAirMBAG44).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE4.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[5]?.Name}'s Weights</b><br/>
                <div className="weightsubcontainer">
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARRY ON (GEAR + FOOD)" value={(resSetAirGBW5 + resSetAirFCO5 + resSetAirMC0).toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 1 (GEAR + FOOD)" value={(resSetAirBAG15 + resSetAirFBAG15 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 2 (GEAR + FOOD)" value={(resSetAirBAG25 + resSetAirFBAG25 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 3 (GEAR + FOOD)" value={(resSetAirBAG35 + resSetAirFBAG35 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} />
                { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG 4 (GEAR + FOOD)" value={(resSetAirBAG45 + resSetAirFBAG45 + resSetAirMBAG10).toFixed(3)} variant="outlined" style={{textTransform: 'uppercase'}} /> */ }
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="WEAR" value={resSetAirWE5.toFixed(3)} type="number" variant="outlined" style={{textTransform: 'uppercase'}} />
                </div>
               </>
                : null}
                </div>
                </>
                ) : null}

                  {country?.label == "4x4 TOWING CAMPER" || country?.label == "4x4 TOWING CARAVAN" || country?.label == "4x4 TOWING BOAT" ? (
                    <>
                    <div className="travellerscontainer" style={{marginTop:'30px'}}>
                <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                    <div className="weightscontainer">
                      <div className="weightsummary">
                      <b>GEAR { /* ({country?.label}) */ }</b><br />
                      <Weights listItemUser={listItemUser} data={typeValue} country={country} gear={true} />
                      </div>
                      { /*
                      <div className="weightsummary">
                      <b>MEALS</b><br />
                      <div className="weightsubcontainer">
                {country?.label == '4x4 TOWING CAMPER' ?
                <>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={resSet4x4M.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CAMPER (kg)" value={resSetCamperM.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={(resSet4x4M + resSetCamperM).toFixed(0)} type="number" variant="outlined" />   
                </>
                : country?.label == '4x4 TOWING CARAVAN' ?
                <>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={resSet4x4M.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="CARAVAN (kg)" value={resSetCaravanM.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={(resSet4x4M + resSetCaravanM).toFixed(0)} type="number" variant="outlined" />   
                </>
                : country?.label == '4x4 TOWING BOAT' ?
                <>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 (kg)" value={resSet4x4M.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BOAT (kg)" value={resSetBoatM.toFixed(0)} type="number" variant="outlined" />
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={(resSet4x4M + resSetCaravanM).toFixed(0)} type="number" variant="outlined" />   
                </>
                : country?.label == 'AIR +' ?
                <>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="BAG WEIGHT" value={resSetHike0.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />
                </> : null}
                </>
                : country?.label == 'HIKING' ?
                <>
                {tr[0]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[0]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike0.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />
                </> : null}
                {tr[1]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[1]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike1.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />
                </> : null}
                {tr[2]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[2]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike2.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />
                </> : null}
                {tr[3]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[3]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike3.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />
                </> : null}
                {tr[4]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[4]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike4.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />
                </> : null}
                {tr[5]?.Name !== undefined ?
                <>
                <b style={{fontSize: '0.8rem'}}>{tr[5]?.Name}'s Weights</b><br/>
                <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PACK CONSUMABLES" value={resSetHike5.toFixed(0)} type="number" variant="outlined" style={{textTransform: 'uppercase', width:'23%',minWidth:'125px'}} />
                </> : null}
                </>
                : null }  */}
                {/* <FWeight listItemUser={listItemUser} data={listUserM} country={country} /> */}
                { /* </div>
                </div> */ }
                      <div className="weightsummary">
                      <b>FOOD</b><br />
                      {country?.label !== 'HIKING' && country?.label !== 'AIR +' ?
                      <Weights listItemUser={listItemUser} data={foodValue} country={country} gear={false} />
                      : null }
                      </div>
                      </div>
                      </div>
                    </>
                  ) : (
                      <>
                      {country?.label !== 'HIKING' && country?.label !== 'AIR +' ?
                      <div className="travellerscontainer" style={{marginTop:'30px'}}>
                      <label class="travellerslabel">GEAR + FOOD WEIGHTS</label>
                      <div className="weightsubcontainer">
                        <TextField  disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'GEAR (kg)' : 'GEAR (lb)'} value={getdgear.toFixed(0)} name="name" type="number" variant="outlined" />
                        <TextField  disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'FOOD (kg)' : 'FOOD (lb)'} value={getFoodweight.toFixed(0)} name="name" type="number" variant="outlined" />
                        { /* <TextField  disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'MEALS (kg)' : 'MEALS (lb)'} value={n.toFixed(0)} name="name" type="number" variant="outlined" /> */ }
                        <TextField  disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'GEAR + FOOD (kg)' : 'GEAR + FOOD (lb)'} value={tata.toFixed(0)} name="name" type="number" variant="outlined" /><br /><br />
                      </div></div> : null}
                      </>
                    )}
                  {gearcat == "4x4" && (
                    <>
                      {listItemUser[0]?.FourbyFourPlusCaravan !== undefined ?
                      <><h3>4x4</h3>
                      {listItemUser?.map(item => {
                        const fourbyfourpayload = Number(item?.FourbyFourPlusCaravan.FourbyFour_GVM) - Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty);
                        const fourbyfourremaining = Number(item?.FourbyFourPlusCaravan.FourbyFour_GVM) - ((Number(item?.FourbyFourPlusCaravan.FourbyFour_TOTAL + tata + tatata)).toFixed(0));
                        const fourfourcalctotal = Number((item?.FourbyFourPlusCaravan.FourbyFour_TOTAL) + tata + tatata).toFixed(0);
                        return (
                          <>
                          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                          <label className="travellerslabel">ALL WEIGHTS</label>
                          <div className="garage-wrapper">
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                          { /* <Tooltip leaveTouchDelay={3000} enterTouchDelay={50} className="hastooltip" title="The maximum legal limit of your 4x4 plus all loads. This is the manufacturer limit unless your vehicle has a GVM upgrade." placement="top-start" arrow><TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" /></Tooltip>&nbsp; */ }
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={fourbyfourpayload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={tata.toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="= TOTAL" value={((item?.FourbyFourPlusCaravan.FourbyFour_TOTAL) + tata + tatata).toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={fourbyfourremaining.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                          </div></div>
                          {listItemUser[0]?.TorquePower !== undefined ? <>
              <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={fourfourcalctotal && item?.TorquePower.Torque ? '1 : ' +  (fourfourcalctotal / item?.TorquePower.Torque).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={fourfourcalctotal && item?.TorquePower.PowerKW ? '1 : ' +  (fourfourcalctotal / item?.TorquePower.PowerKW).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={fourfourcalctotal && item?.TorquePower.PowerHP ? '1 : ' +  (fourfourcalctotal / item?.TorquePower.PowerHP).toFixed(0) : 0} />
              </div>
              </div></> : null}
                        <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                          </>
                        )
                      })}
                      </> : <>No Weights Defined</>}
                    </>
                  )}
                    {gearcat == "4x4 TOWING CAMPER" && (
                      <>
                      {listItemUser[0]?.FourbyFourPlusCaravan !== undefined ?
                      <>
                        {listItemUser?.map(item => {
                          const forbyfortotalplusball = Number(item?.FourbyFourPlusCaravan.FourbyFour_TOTAL) + Number(item?.Camper?.Camper_CamperBall) + Number(resSet4x4) + Number(tatata.toFixed(0));
                          const camper_Total = (Number(item?.Camper?.Camper_Empty) + Number(item?.Camper?.Camper_Water) + Number(resSetCamper) + Number(resSetCamperF)).toFixed(0) ;
                          const campernoballweight = Number(camper_Total) - Number(item?.Camper?.Camper_CamperBall);
                          const camperremaining = Number(item?.Camper?.Camper_ATM) - Number(campernoballweight) - Number(item?.Camper?.Camper_CamperBall);
                          const fourbyfourremaining = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - Number(forbyfortotalplusball);
                          const forbyfortotal = Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty) + Number(item.FourbyFourPlusCaravan.FourbyFour_FUEL) + Number(item.FourbyFourPlusCaravan.FourbyFour_Water);
                          const camper4x4_Total = Number(forbyfortotal) + Number(item?.Camper?.Camper_CamperBall) + Number(resSet4x4) + Number(tatata.toFixed(0));
                          const camperminusball_Total = Number(camper_Total) - Number(item?.Camper?.Camper_CamperBall);
                          const campertotal = Number(camper4x4_Total) + Number(camperminusball_Total);
                          const camperremaining_Total = Number(item?.Camper?.camperforbyfor_GCM) - Number(campertotal);
                          return (
                            <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                          <label className="travellerslabel">ALL WEIGHTS</label>
                          <b>CAMPER</b><br />
                          <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.Camper?.Camper_Empty} name="Camper_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="ATM (kg)" value={item?.Camper?.Camper_ATM} name="Camper_ATM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item?.Camper?.Camper_Payload} name="Camper_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.Camper?.Camper_Water} name="Camper_Water" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSetCamper + resSetCamperF + resSetCamperM).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={camperremaining.toFixed(0)} name="Camper_CampSerBall" type="number" variant="outlined" className={(camperremaining < 0 ) ? "negative" : "positive"} />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'BALL WEIGHT (kg)' : 'BALL WEIGHT (lb)'} value={item?.Camper?.Camper_CamperBall} name="Camper_CamperBall" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'CAMPER TOTAL (kg)' : 'CAMPER TOTAL (lb)'} value={campernoballweight} name="Camper_CampSerBall" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={item?.Camper?.Camper_Total} name="Total" type="number" variant="outlined" />&nbsp; */ }
                            </div>
                            <b>4x4</b><br />
                            <div className="garage-wrapper">
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_Payload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSet4x4 + resSet4x4M + resSet4x4F).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ BALL WEIGHT (kg)' : '+ BALL WEIGHT (lb)'} value={item?.Camper?.Camper_CamperBall} name="BALL WEIGHT" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= 4x4 TOTAL (kg)' : '= 4x4 TOTAL (lb)'} value={forbyfortotalplusball.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={fourbyfourremaining.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                            </div>
                            <b>4x4 + CAMPER</b><br />
                            <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '4x4 GCM (kg)' : '4x4 GCM (lb)'} value={item?.Camper?.camperforbyfor_GCM} name="Camper_Empty" type="number" variant="outlined" />
                              { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 TOTAL (kg)" value={campertotal} name="camperTotal" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- 4x4 TOTAL (kg)' : '- 4x4 TOTAL (lb)'} value={camper4x4_Total.toFixed(0)} name="4x4Total" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- CAMPER TOTAL (kg)' : '- CAMPER TOTAL (lb)'} value={camperminusball_Total} name="camperTotal" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={camperremaining_Total.toFixed(0)} name="Total" type="number" variant="outlined" className={(camperremaining_Total < 0 ) ? "negative" : "positive"} />
                            </div></div>
                            {listItemUser[0]?.TorquePower !== undefined ? <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
              <label className="travellerslabel">PERFORMANCE</label>
              <div className="garage-wrapper">
              <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
              <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={camperminusball_Total && item?.TorquePower.Torque ? '1 : ' +  ((Number(camperminusball_Total) + Number(camper4x4_Total)) / item?.TorquePower.Torque).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={camperminusball_Total && item?.TorquePower.PowerKW ? '1 : ' +  ((Number(camperminusball_Total) + Number(camper4x4_Total)) / item?.TorquePower.PowerKW).toFixed(0) : 0} />
              <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
              inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
              <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={camperminusball_Total && item?.TorquePower.PowerHP ? '1 : ' +  ((Number(camperminusball_Total) + Number(camper4x4_Total)) / item?.TorquePower.PowerHP).toFixed(0) : 0} />
              </div>
              </div></> : null}
                            <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                          )
                        })}
                        <br />
                        </> : <>No Weights Defined</>}
                      </>
                    )}
                    {gearcat == "4x4 TOWING CARAVAN" && (
                      <>
                      {listItemUser[0]?.Caravan !== undefined ?
                      <>
                        {listItemUser?.map(item => {
                          const caravanpayload = Number(item?.Caravan?.Caravan_ATM) - Number(item?.Caravan?.Caravan_Empty);
                          const caravan_Total = (Number(item?.Caravan?.Caravan_Empty) + Number(item?.Caravan?.Caravan_Water) + Number(resSetCaravan) + Number(resSetCaravanF)).toFixed(0);
                          const caravanremaining = Number(item?.Caravan?.Caravan_ATM) - Number(caravan_Total);
                          const caravannoballweight = Number(caravan_Total) - Number(item?.Caravan?.caravan_forbyfor_caravanBall);
                          const forbyfortotal = Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty) + Number(item.FourbyFourPlusCaravan.FourbyFour_FUEL) + Number(item.FourbyFourPlusCaravan.FourbyFour_Water);
                          const forbyfortotalcaraplusball = (Number(forbyfortotal) + Number(item?.Caravan?.caravan_forbyfor_caravanBall) + Number(tatata) + Number(resSet4x4) + Number(resSet4x4F)).toFixed(0);
                          const fourbyfourremaining = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - (Number(forbyfortotal) + tata + tatata + resSet4x4 + resSet4x4F);
                          const caravanminusball_Total = Number(caravan_Total) - Number(item?.Caravan?.caravan_forbyfor_caravanBall);
                          const caravantotal = Number(forbyfortotalcaraplusball) + Number(caravannoballweight) + Number(resSetCaravan) + Number(resSetCaravanF);
                          const caravanremaining_Total = Number(item?.Caravan?.caravan_GCM) - Number(caravantotal);
                          return (
                            <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">ALL WEIGHTS</label>
                              <b>CARAVAN</b><br />
                              <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.Caravan?.Caravan_Empty} name="Caravan_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="ATM (kg)" value={item?.Caravan?.Caravan_ATM} name="Caravan_ATM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={caravanpayload} name="Caravan_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.Caravan?.Caravan_Water} name="Caravan_Water" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GREYWATER (kg)" value={item?.Caravan?.Caravan_Greywater} name="Caravan_Greywater" type="number" variant="outlined" />&nbsp;+&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSetCaravan + resSetCaravanF + resSetCaravanM).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- BALL WEIGHT (kg)' : '- BALL WEIGHT (lb)'} value={item?.Caravan?.caravan_forbyfor_caravanBall} name="Caravan_Ball" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= CARAVAN TOTAL (kg)' : '= CARAVAN TOTAL (lb)'} value={caravannoballweight} name="Caravan_Ball" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={item?.Caravan?.Caravan_Total} name="Caravan_total" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={caravanremaining.toFixed(0)} name="Caravan_Ball" type="number" variant="outlined" className={(caravanremaining < 0 ) ? "negative" : "positive"} />
                            </div>
                            <b>4x4</b><br />
                            <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" />&nbsp;
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_Payload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : 'FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : 'WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : 'GEAR + FOOD (lb)'} value={(resSet4x4 + resSet4x4F + resSet4x4M).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : 'TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ BALL WEIGHT (kg)' : 'BALL WEIGHT (lb)'} value={item?.Caravan?.caravan_forbyfor_caravanBall} name="Caravan_Ball" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= 4x4 TOTAL (kg)' : '= 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'REMAINING (kg)' : 'REMAINING (lb)'} value={fourbyfourremaining.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                            </div>
                            <b>4x4 + CARAVAN</b><br />
                            <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '4x4 GCM (kg)' : '4x4 GCM (lb)'} value={item?.Caravan?.caravan_GCM} name="Caravan_Empty" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- 4x4 TOTAL (kg)' : '- 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="Caravan_4x4 total" type="number" variant="outlined" />
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- CARAVAN TOTAL (kg)' : '- CARAVAN TOTAL (lb)'} value={caravanminusball_Total} name="Caravan_Total" type="number" variant="outlined" />
                            { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 CARAVAN TOTAL (kg)" value={item?.Caravan?.Caravan_forbyfor_Total} name="Total" type="number" variant="outlined" />&nbsp; */ }
                            <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={caravanremaining_Total.toFixed(0)} name="Total" type="number" variant="outlined" className={(caravanremaining_Total < 0 ) ? "negative" : "positive"} />
                            </div></div>
                            {listItemUser[0]?.TorquePower !== undefined ? <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">PERFORMANCE</label>
                            <div className="garage-wrapper">
                            <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
                            <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.Torque ? '1 : ' + ((Number(forbyfortotalcaraplusball) + Number(caravanminusball_Total)) / item?.TorquePower.Torque).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerKW ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(caravanminusball_Total)) / item?.TorquePower.PowerKW).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerHP ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(caravanminusball_Total)) / item?.TorquePower.PowerHP).toFixed(0) : 0} />
                            </div>
                            </div></> : null}
                            <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                          )
                        })}
                        <br /></> : <>No Weights Defined</>}</>)}
                    {gearcat == "4x4 TOWING BOAT" && (
                      <>
                      {listItemUser[0]?.BoatWeight !== undefined ?
                      <>
                        {listItemUser?.map(item => {
                          const boatremaining = Number(item?.BoatWeight?.BoatWeight_ATM) - Number(item?.BoatWeight?.BoatTotal);
                          const Boat_Total = (Number(item?.BoatWeight?.BoatWeight_Empty) + Number(item?.BoatWeight?.BoatWeight_Water) + Number(item?.BoatWeight?.BoatWeight_Fuel) + resSetBoat + resSetBoatF).toFixed(0);
                          const boatnoballweight = Number(Boat_Total) - Number(item?.BoatWeight?.BoatWeight_forbyfor_BoatBall);
                          const forbyfortotal = Number(item?.FourbyFourPlusCaravan.FourbyFour_Empty) + Number(item.FourbyFourPlusCaravan.FourbyFour_FUEL) + Number(item.FourbyFourPlusCaravan.FourbyFour_Water);
                          const forbyfortotalcaraplusball = (Number(forbyfortotal) + Number(item?.BoatWeight?.BoatWeight_forbyfor_BoatBall) + Number(tatata) + Number(resSet4x4) + Number(resSet4x4F)).toFixed(0);
                          const fourbyfourremaining = Number(item.FourbyFourPlusCaravan.FourbyFour_GVM) - (Number(forbyfortotal) + tata + tatata);
                          const boatminusball_Total = Number(Boat_Total) - Number(item?.BoatWeight?.BoatWeight_forbyfor_BoatBall);
                          const boattotal = Number(forbyfortotalcaraplusball) + Number(boatminusball_Total);
                          const boatremaining_Total = Number(item?.BoatWeight?.BoatWeight_GCM) - Number(boattotal);
                          return (
                            <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">ALL WEIGHTS</label>
                              <b>BOAT</b><br />
                              <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.BoatWeight?.BoatWeight_Empty} name="BoatWeight_Empty" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="ATM (kg)" value={item?.BoatWeight?.BoatWeight_ATM} name="BoatWeight_ATM" type="number" variant="outlined" />&nbsp;
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item?.BoatWeight?.BoatWeight_Payload} name="BoatWeight_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item?.BoatWeight?.BoatWeight_Fuel} name="BoatWeight_Payload" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.BoatWeight?.BoatWeight_Water} name="BoatWeight_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSetBoat + resSetBoatF + resSetBoatM).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- BALL WEIGHT (kg)' : '- BALL WEIGHT (lb)'} value={item?.BoatWeight?.BoatWeight_forbyfor_BoatBall} name="BoatWeight_BoatBall" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="TOTAL (kg)" value={item?.BoatWeight?.BoatTotal} name="Total" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= BOAT TOTAL (kg)' : '= BOAT TOTAL (lb)'} value={boatnoballweight} name="BoatWeight_BoatBall" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={boatremaining.toFixed(0)} name="BoatWeight_BoatBall" type="number" variant="outlined" className={(boatremaining < 0 ) ? "negative" : "positive"} />
                          </div>
                          <b>4x4</b><br />
                          <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.FourbyFourPlusCaravan.FourbyFour_Empty} name="FourbyFour_Empty" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_GVM} name="FourbyFour_GVM" type="number" variant="outlined" />&nbsp;
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={item.FourbyFourPlusCaravan.FourbyFour_Payload} name="FourbyFour_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_FUEL} name="FourbyFour_FUEL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item.FourbyFourPlusCaravan.FourbyFour_Water} name="FourbyFour_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={(resSet4x4 + resSet4x4F + resSet4x4M).toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ BALL WEIGHT (kg)' : '+ BALL WEIGHT (lb)'} value={item?.BoatWeight?.BoatWeight_forbyfor_BoatBall} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= 4x4 TOTAL (kg)' : '= 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="FourbyFour_TOTAL" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={fourbyfourremaining.toFixed(0)} name="FourbyFour_TOTAL" type="number" variant="outlined" className={(fourbyfourremaining < 0 ) ? "negative" : "positive"} />
                          </div>
                          <b>4x4 + BOAT</b><br />
                          <div className="garage-wrapper">
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '4x4 GCM (kg)' : '4x4 GCM (lb)'} value={item?.BoatWeight?.BoatWeight_GCM} name="BoatWeight_Empty" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '- 4x4 TOTAL (kg)' : '- 4x4 TOTAL (lb)'} value={forbyfortotalcaraplusball} name="BoatWeight_4x4 Total" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= BOAT TOTAL (kg)' : '= BOAT TOTAL (lb)'} value={boatminusball_Total} name="BoatWeightttal" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="4x4 BOAT TOTAL (kg)" value={boattotal} name="Total" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={boatremaining_Total.toFixed(0)} name="Total" type="number" variant="outlined" className={(boatremaining_Total < 0 ) ? "negative" : "positive"} />
                          </div></div>
                          {listItemUser[0]?.TorquePower !== undefined ? <>
                          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">PERFORMANCE</label>
                            <div className="garage-wrapper">
                            <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
                            <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.Torque ? '1 : ' + ((Number(forbyfortotalcaraplusball) + Number(boatminusball_Total)) / item?.TorquePower.Torque).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerKW ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(boatminusball_Total)) / item?.TorquePower.PowerKW).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={forbyfortotalcaraplusball && item?.TorquePower.PowerHP ? '1 : ' +  ((Number(forbyfortotalcaraplusball) + Number(boatminusball_Total)) / item?.TorquePower.PowerHP).toFixed(0) : 0} />
                            </div>
                            </div></> : null}
                          <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                          )
                        })}
                        <br />
                        </> : <>No Weights Defined</>}
                      </>)}
                    {gearcat == "MOTORBIKE" && (
                      <>
                      {listItemUser[0]?.AdventureMotorBike !== undefined ?
                      <>
                        {listItemUser?.map(item => {
                          const adventureMotorbile_Payload = Number(item?.AdventureMotorBike?.AdventureMotorBike_GVM) - Number(item?.AdventureMotorBike?.AdventureMotorBike_Empty);
                          const adventure_Total = Number(item?.AdventureMotorBike?.AdventureMotorBike_Empty) + Number(item?.AdventureMotorBike?.AdventureMotorBike_Water) + Number(item?.AdventureMotorBike?.AdventureMotorBike_Fuel);
                          const advmotoremaining = Number(item?.AdventureMotorBike?.AdventureMotorBike_GVM) - ((Number(adventure_Total + tata + tatata)).toFixed(0));
                          const advmotototal = Number(adventure_Total) + Number(tata) + Number(tatata);
                          return (
                            <>
                            <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">ALL WEIGHTS</label>
                            <div className="garage-wrapper">
                              <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'EMPTY (kg)' : 'EMPTY (lb)'} value={item?.AdventureMotorBike?.AdventureMotorBike_Empty} name="AdventureMotorBike_Empty" type="number" variant="outlined" />
                          { /* <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="GVM (kg)" value={item?.AdventureMotorBike?.AdventureMotorBike_GVM} name="AdventureMotorBike_GVM" type="number" variant="outlined" />&nbsp;
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label="PAYLOAD (kg)" value={adventureMotorbile_Payload} name="AdventureMotorBike_Payload" type="number" variant="outlined" />&nbsp; */ }
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ FUEL (kg)' : '+ FUEL (lb)'} value={item?.AdventureMotorBike?.AdventureMotorBike_Fuel} name="AdventureMotorBike_Fuel" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ WATER (kg)' : '+ WATER (lb)'} value={item?.AdventureMotorBike?.AdventureMotorBike_Water} name="AdventureMotorBike_Water" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ GEAR + FOOD (kg)' : '+ GEAR + FOOD (lb)'} value={tata.toFixed(0)} name="FourbyFour_GplusF" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '+ TRAVELLERS (kg)' : '+ TRAVELLERS (lb)'} value={tatata.toFixed(0)} name="FourbyFour_GplusT" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '= TOTAL (kg)' : '= TOTAL (lb)'} value={(adventure_Total + tata + tatata).toFixed(0)} name="total" type="number" variant="outlined" />
                          <TextField disabled InputLabelProps={{ shrink: true, }} id="standard-basic" label={getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? '~ REMAINING CAPACITY (kg)' : '~ REMAINING CAPACITY (lb)'} value={advmotoremaining.toFixed(0)} name="total" type="number" variant="outlined" className={(advmotoremaining < 0 ) ? "negative" : "positive"} />
                          </div></div>
                          {listItemUser[0]?.TorquePower !== undefined ? <>
                          <div className="travellerscontainer" style={{paddingTop:'18px'}}>
                            <label className="travellerslabel">PERFORMANCE</label>
                            <div className="garage-wrapper">
                            <TextField disabled type="number" label="TORQUE (Nm)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.Torque} onChange={(e) => setTorque(e.target.value)} name="Torque" variant="outlined" />
                            <TextField disabled label="TORQUE : WEIGHT RATIO (Nm)" type="text" defaultValue="0" variant="outlined" value={advmotototal && item?.TorquePower.Torque ? '1 : ' + (advmotototal / item?.TorquePower.Torque).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (kW)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerKW} onChange={(e) => setPower(e.target.value)} name="Power" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (kW)" type="text" defaultValue="0" variant="outlined" value={advmotototal && item?.TorquePower.PowerKW ? '1 : ' +  (advmotototal / item?.TorquePower.PowerKW).toFixed(0) : 0} />
                            <TextField disabled type="number" label="POWER (HP)" defaultValue="0" InputLabelProps={{ shrink: true, }} InputProps={{
                            inputMode: 'numeric' }} id="standard-basic" value={item?.TorquePower.PowerHP} onChange={(e) => setPowerHP(e.target.value)} name="PowerHP" variant="outlined" />
                            <TextField disabled label="POWER : WEIGHT RATIO (HP)" type="text" defaultValue="0" variant="outlined" value={advmotototal && item?.TorquePower.PowerHP ? '1 : ' +  (advmotototal / item?.TorquePower.PowerHP).toFixed(0) : 0} />
                            </div>
                            </div></> : null}
                          <em>Weight calculators are purely a guide. To ensure safety, legal, and insurance compliance, check your total weights on a weighbridge.</em><br /><br />
                            </>
                          )
                        })}
                        <br /></> : <>No Weights Defined</>}
                      </>
                    )}
                    {gearcat == "HIKING" && (<><br /><br /></>)}
                    {gearcat == "BIKEPACKING" && (<><br /><br /></>)}
                    {gearcat == "SEA KAYAKING" && (<><br /><br /></>)}
                    {gearcat == "WHITEWATER KAYAKING" && (<><br /><br /></>)}
                    {gearcat == "CANOEING" && (<><br /><br /></>)}
                    {gearcat == "SAILING" && (<><br /><br /></>)}
                    {gearcat == "AIR +" && (<><br /><br /></>)}
                </div>
              </>
            )}
            { /* selectedTab === 6 && (
              <>
                {allImages.map(image => {
                  const dataname = image.Test2.split('.')
                  return (
                    <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>FILENAME</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow key={image}>
                      <TableCell>
                        <div onClick={() => openImage(image.Test)}>
                          <p>{image.Test2}</p>
                          {dataname.length && dataname[1] == "pdf" ? (
                            <>
                              {togles == true && image.Test == url && (
                                <PDF />
                              )}
                            </>
                          ) : (
                              <>
                                {togles == true && image.Test == url && (
                                  <>
                                    <img style={{ width: '100%' }} src={url} alt="no image" />
                                  </>
                                )}
                              </>
                            )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <IconButton size="small" aria-label="delete" onClick={() => deleteFromFirebase(image.Test)}><DeleteIcon /></IconButton>
                      </TableCell>
                    </TableRow>
                    </TableBody>
                    </Table>
                    </TableContainer>
                  )
                })}
                <div className="travellerscontainer">
                <input style={{fontSize:'16px', lineHeight:'36px'}} type="file" accept="image/*,.pdf,.docx,.xlsx,.gpx,text/xml,text/gpsxml,application/gpsxml" onChange={(e) => { onImageChange(e); }} />
                <Button style={{float:'right'}} variant="outlined" onClick={uploadToFirebase}>UPLOAD FILE</Button>
                <div style={{clear:'both'}}></div>
                </div>
              </>
              ) */}
            {selectedTab === 7 && (
              <>
              <h4>JOURNAL</h4>
                <TextField InputLabelProps={{ shrink: true, }} fullWidth id="standard-basic" value={notes} name="dateto" onChange={e => setNotes(e.currentTarget.value)} multiline rows={12} variant="outlined" />
                { /* <br/>
                <span className="renderedtext"><Linkify>{notes}</Linkify></span> */ }
                <br/><br/> 
              </>
            )}
            {selectedTab === 5 && (
              
              <>
              
              {country?.label == "HIKING" || country?.label == "BIKEPACKING" ?
              <div className="final-list-container">
              {Object.keys(categorizedDatas2).length !== 0 ? <>
              {Object.keys(categorizedDatas2)?.map((key, index) => {
                // HIKE - WITH GEAR
                const uniquePackingLocations = [...new Set(categorizedDatas2[key]?.items.map(item => item.packingLocation))];
                const filteredItemsF = categorizedDataFoods2['PACK - ' + key]?.items
                              return (
                                <>
                                <Card>
                                <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                                <CardContent>
                                <Typography component="div" className="typocol">
                                  {uniquePackingLocations.map((location) => {
  // Filter items for the current packingLocation
  const filteredItems = categorizedDatas2[key]?.items.filter(item => item.packingLocation === location);
  const filteredItemsF = categorizedDataFoods2[key]?.items.filter(item => item.packingLocation === location);
  if (filteredItems?.length || filteredItemsF?.length) {
    return (
      <Table className="finallist" id={'finaltbl' + location.replace(' ', '-')} key={location}>
        <TableHead>
          <TableRow>
            <TableCell><b style={{textTransform:'uppercase'}}>{location == 'Base Weight' ? 'BASE' : location}</b></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
          {filteredItems?.map((item, index) => {
            const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === item.CategoryId)
            return (
            <TableRow key={index}>
              <TableCell>{categorymatch[0].label} &raquo; {item.SubCategory}</TableCell>
              <TableCell>{item.Quantity} x {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</TableCell>
            </TableRow>
            )}
          )}

          </TableBody>
          <TableFooter>
          <TableRow className="totalrow">
          <TableCell>
          TOTAL
          </TableCell>
          <TableCell className="totalcell">
          {location == 'Base Weight' ? key == tr[0]?.Name ? resSetHikeGBW0.toFixed(3) : key == tr[1]?.Name ? resSetHikeGBW1.toFixed(3) : key == tr[2]?.Name ? resSetHikeGBW2.toFixed(3) : key == tr[3]?.Name ? resSetHikeGBW3.toFixed(3) : null : null}
          {location == 'Consumables' ? key == tr[0]?.Name ? resSetHikeG0.toFixed(3) : key == tr[1]?.Name ? resSetHikeG1.toFixed(3) : key == tr[2]?.Name ? resSetHikeG2.toFixed(3) : key == tr[3]?.Name ? resSetHikeG3.toFixed(3) : null : null}
          {location == 'Wear' ? key == tr[0]?.Name ? resSetHikeGW0.toFixed(3) : key == tr[1]?.Name ? resSetHikeGW1.toFixed(3) : key == tr[2]?.Name ? resSetHikeGW2.toFixed(3) : key == tr[3]?.Name ? resSetHikeGW3.toFixed(3) : null : null}
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
          </TableCell>
          </TableRow>
          {location == 'Consumables' ?
          <TableRow className="packtotal">
            <TableCell>
              <b>PACK TOTAL</b>
            </TableCell>
            <TableCell className="totalcell">
            {key == tr[0]?.Name ? (resSetHikeG0 + resSetHikeGBW0).toFixed(3) : key == tr[1]?.Name ? (resSetHikeG1 + resSetHikeGBW1).toFixed(3) : key == tr[2]?.Name ? (resSetHikeG2 + resSetHikeGBW2).toFixed(3) : key == tr[3]?.Name ? (resSetHikeG3 + resSetHikeGBW3).toFixed(3) : null}
            </TableCell>
          </TableRow> : null }
          </TableFooter>
      </Table>
    );
  }
  })}
  <Table className="finallist" id="finaltblConsumables">
  <TableHead>
          <TableRow>
            <TableCell><b style={{textTransform:'uppercase'}}>CONSUMABLES</b></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {filteredItemsF?.map((item, index) => {
            return (
            <TableRow key={index}>
              <TableCell>{item.Category}</TableCell>
              <TableCell>{item.Quantity} x {item.Name}</TableCell>
            </TableRow>
            )
          }
          )}
          </TableBody>
          <TableFooter>
          <TableRow className="totalrow">
          <TableCell>
          TOTAL
          </TableCell>
          <TableCell className="totalcell">
          {key == tr[0]?.Name ? (resSetHikeF0).toFixed(3) : key == tr[1]?.Name ? (resSetHikeF1).toFixed(3) : key == tr[2]?.Name ? (resSetHikeF2).toFixed(3) : key == tr[3]?.Name ? (resSetHikeF3).toFixed(3) : null}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
          </TableCell>
          </TableRow>
          </TableFooter>
  </Table>
  <Table id="finaltblPackTotal">
  <TableFooter>
  <TableRow className="packtotal">
            <TableCell>
              {country?.label == "BIKEPACKING" ? <b>BIKE TOTAL</b> : <b>PACK TOTAL</b>}
            </TableCell>
            <TableCell className="totalcell">
            {key == tr[0]?.Name ? (resSetHikeF0 + resSetHikeGBW0).toFixed(3) : key == tr[1]?.Name ? (resSetHikeF1 + resSetHikeGBW1).toFixed(3) : key == tr[2]?.Name ? (resSetHikeF2 + resSetHikeGBW2).toFixed(3) : key == tr[3]?.Name ? (resSetHikeF3 + resSetHikeGBW3).toFixed(3) : null}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
            </TableCell>
          </TableRow>
          </TableFooter>
  </Table>
                                </Typography>
                                </CardContent>
                                </Card>
                                </>
                              )
              })}
     </> : 
     <>
     {Object.keys(categorizedDataFoods2).map((key, index) => {
      // HIKE - WITHOUT GEAR
                const uniquePackingLocations = [...new Set(categorizedDataFoods2[key]?.items.sort((a, b) => a.packingLocation.localeCompare(b.packingLocation)).map(item => item.packingLocation))];
                const filteredItemsF = categorizedDataFoods2[key]?.items
                              return (
                                <>
                                <Card>
                                <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                                <CardContent>
                                <Typography component="div" className="typocol">
  <Table className="finallist" id="finaltblConsumables">
        <TableBody>
  {filteredItemsF?.map((item, index) => {
            return (
            <TableRow key={index}>
              <TableCell>{item.Category}</TableCell>
              <TableCell>{item.Quantity} x {item.Name}</TableCell>
            </TableRow>
            )
          }
          )}
          </TableBody>
          <TableFooter>
          <TableRow className="totalrow">
          <TableCell>
          TOTAL
          </TableCell>
          <TableCell className="totalcell">
          {key.includes(tr[0]?.Name) ? (resSetHikeF0).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetHikeF1).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetHikeF2).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetHikeF3).toFixed(3) : null}{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
          </TableCell>
          </TableRow>
          </TableFooter>
  </Table>
                                </Typography>
                                </CardContent>
                                </Card>
                                </>
                              )
              })}
     </> }
    </div> : country?.label == "AIR +" ? 
    <div className="final-list-container">
    {Object.keys(categorizedDatas3).length !== 0 ? <>
      {Object.keys(categorizedDatas3).sort().map((key, index) => {
      // AIR - WITH GEAR
      const uniquePackingLocations = [...new Set(categorizedDatas3[key]?.items.map(item => item.packingLocation))];
                    return (
                      <>
                      <Card>
                      <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                      <CardContent>
                      <Typography component="div" className="typocol">
                        {uniquePackingLocations.map((location) => {
const filteredItems = categorizedDatasG2[key]?.items.filter(item => item.packingLocation === location);
const filteredItemsF = categorizedDataFoods2[key]?.items.filter(item => item.packingLocation === location);
const locationclean = location.split(' - ')[0];
const travellerclean = location.split(' - ')[1];
if (filteredItems?.length || filteredItemsF?.length) {
return (
<Table className="finallist" id={'finaltbl' + locationclean.replace(' ', '-')} key={locationclean}>
<TableBody>
{filteredItems ? 
        <>
<TableRow>
  <TableCell><b style={{textTransform:'uppercase'}}>GEAR</b></TableCell>
  <TableCell></TableCell>
</TableRow>

{filteredItems?.map((item, index) => {
  const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === item.CategoryId)
  return (
  <TableRow key={index}>
    <TableCell>{categorymatch[0].label} &raquo; {item.SubCategory}</TableCell>
    <TableCell>{item.Quantity} x {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</TableCell>
  </TableRow>
  )}
)}
</> : null }

{filteredItemsF ?
<>
<TableRow>
  <TableCell><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
  <TableCell></TableCell>
</TableRow>

{filteredItemsF?.map((item, index) => {
  return (
  <TableRow key={index}>
    <TableCell>{item.Category}</TableCell>
    <TableCell>{item.Name}</TableCell>
  </TableRow>
  )}
)}
</>
: null}
</TableBody>
<TableFooter>
<TableRow className="totalrow">
<TableCell>
TOTAL
</TableCell>
<TableCell className="totalcell">
{locationclean == 'WEAR' ? travellerclean == tr[0]?.Name ? resSetAirWE0.toFixed(3) : travellerclean == tr[1]?.Name ? resSetAirWE1.toFixed(3) : travellerclean == tr[2]?.Name ? resSetAirWE2.toFixed(3) : travellerclean == tr[3]?.Name ? resSetAirWE3.toFixed(3) : null : null}
{locationclean == 'CARRY' ? travellerclean == tr[0]?.Name ? (resSetAirGBW0 + resSetAirFCO0).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirGBW1 + resSetAirFCO1).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirGBW2 + resSetAirFCO2).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirGBW3 + resSetAirFCO3).toFixed(3) : null : null}
{locationclean == 'BAG1' ? travellerclean == tr[0]?.Name ? (resSetAirBAG10 + resSetAirFBAG10).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirBAG11 + resSetAirFBAG11).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirBAG12 + resSetAirFBAG12).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirBAG13 + resSetAirFBAG13).toFixed(3) : null : null}
{locationclean == 'BAG2' ? travellerclean == tr[0]?.Name ? (resSetAirBAG20 + resSetAirFBAG20).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirBAG21 + resSetAirFBAG21).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirBAG22 + resSetAirFBAG22).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirBAG23 + resSetAirFBAG23).toFixed(3) : null : null}
{locationclean == 'BAG3' ? travellerclean == tr[0]?.Name ? (resSetAirBAG30 + resSetAirFBAG30).toFixed(3) : travellerclean == tr[1]?.Name ? (resSetAirBAG31 + resSetAirFBAG31).toFixed(3) : travellerclean == tr[2]?.Name ? (resSetAirBAG32 + resSetAirFBAG32).toFixed(3) : travellerclean == tr[3]?.Name ? (resSetAirBAG33 + resSetAirFBAG33).toFixed(3) : null : null}
{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
</TableCell>
</TableRow>
</TableFooter>
</Table>
);
}
})}
                      </Typography>
                      </CardContent>
                      </Card>
                      </>
                    )
    })}</> : 
    <>
    {Object.keys(categorizedDataFoods2).sort().map((key, index) => {
      // AIR - WITHOUT GEAR
      const uniquePackingLocations = [...new Set(categorizedDataFoods2[key]?.items.sort((a, b) => a.packingLocation.localeCompare(b.packingLocation)).map(item => item.packingLocation))];
                    return (
                      <>
                      <Card>
                      <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key.replace("BAG1", "BAG 1").replace("BAG2", "BAG 2").replace("BAG3", "BAG 3").replace("CARRY", "CARRY ON")}></CardHeader>
                      <CardContent>
                      <Typography component="div" className="typocol">
                        {uniquePackingLocations.map((location) => {
const filteredItemsF = categorizedDataFoods2[location]?.items.filter(item => item.packingLocation === location);
const locationclean = location.split(' - ')[0];
if (filteredItemsF?.length) {
return (
<Table className="finallist" id={'finaltbl' + locationclean.replace(' ', '-')} key={locationclean}>
<TableBody>

<>
<TableRow>
  <TableCell><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
  <TableCell></TableCell>
</TableRow>

{filteredItemsF?.map((item, index) => {
  return (
  <TableRow key={index}>
    <TableCell>{item.Category}</TableCell>
    <TableCell>{item.Name}</TableCell>
  </TableRow>
  )}
)}
</>

</TableBody>
<TableFooter>
<TableRow className="totalrow">
<TableCell>
TOTAL
</TableCell>
<TableCell className="totalcell">
{locationclean == 'CARRY' ? key.includes(tr[0]?.Name) ? (resSetAirGBW0 + resSetAirFCO0).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirGBW1 + resSetAirFCO1).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirGBW2 + resSetAirFCO2).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirGBW3 + resSetAirFCO3).toFixed(3) : null : null}
{locationclean == 'BAG1' ? key.includes(tr[0]?.Name) ? (resSetAirBAG10 + resSetAirFBAG10).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirBAG11 + resSetAirFBAG11).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirBAG12 + resSetAirFBAG12).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirBAG13 + resSetAirFBAG13).toFixed(3) : null : null}
{locationclean == 'BAG2' ? key.includes(tr[0]?.Name) ? (resSetAirBAG20 + resSetAirFBAG20).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirBAG21 + resSetAirFBAG21).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirBAG22 + resSetAirFBAG22).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirBAG23 + resSetAirFBAG23).toFixed(3) : null : null}
{locationclean == 'BAG3' ? key.includes(tr[0]?.Name) ? (resSetAirBAG30 + resSetAirFBAG30).toFixed(3) : key.includes(tr[1]?.Name) ? (resSetAirBAG31 + resSetAirFBAG31).toFixed(3) : key.includes(tr[2]?.Name) ? (resSetAirBAG32 + resSetAirFBAG32).toFixed(3) : key.includes(tr[3]?.Name) ? (resSetAirBAG33 + resSetAirFBAG33).toFixed(3) : null : null}
{getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}
</TableCell>
</TableRow>
</TableFooter>
</Table>
);
}
})}
                      </Typography>
                      </CardContent>
                      </Card>
                      </>
                    )
    })}
    </>
    }
</div>
              : 
              <div className="final-list-container"> 
              {Object.keys(categorizedDatas3).length !== 0 ? <>
                {Object.keys(categorizedDatas3).sort().map((key, index) => {
                // ALL OTHER TRIPS - WITH GEAR
                const uniquePackingLocations = [...new Set(categorizedDatas3[key]?.items.map(item => item.packingLocation))];
                              return (
                                <>
                                <Card>
                                <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                                <CardContent>
                                <Typography component="div">
                                  {uniquePackingLocations.map((location) => {
  const filteredItems = categorizedDatasG2[key]?.items.filter(item => item.packingLocation === location);
  const filteredItemsF = categorizedDataFoods2[key]?.items.filter(item => item.packingLocation === location);
  if (filteredItems?.length || filteredItemsF?.length) {
    return (
      <Table className="finallist" key={location}>
        <TableBody>
        {filteredItems ? 
        <>
          <TableRow>
            <TableCell><b style={{textTransform:'uppercase'}}>GEAR</b></TableCell>
            <TableCell></TableCell>
          </TableRow>
        
        
          {filteredItems?.map((item, index) => {
            const categorymatch = Object.values(newata?.GearCategories).filter(chunk => chunk.id === item.CategoryId)
            return (
            <TableRow key={index}>
              <TableCell>{categorymatch[0]?.label} &raquo; {item.SubCategory}</TableCell>
              <TableCell>{item.Quantity} x {item.Status == 'Ready' ? <div className="item-ready"></div> : null} {item.Status == 'Repair' ? <div className="item-repair"></div> : null} {item.Status == 'Buy' ? <div className="item-buy"></div> : null} {item.Status == 'Sell' ? <div className="item-sell"></div> : null} {item.Brand == "- NO BRAND" || item.Brand == "" || item.Brand == "-"  ? '-' : item.Brand} {item.Name}</TableCell>
            </TableRow>
            )
          }
          )}
          

          <TableRow className="totalrow MuiTableRow-footer">
          <TableCell className="MuiTableCell-footer">
          TOTAL
          </TableCell>
          <TableCell className="MuiTableCell-footer totalcell">
          {location == '4x4' ? resSet4x4.toFixed(3) : null}
          {location == 'Camper' ? resSetCamper.toFixed(3) : null}
          {location == 'Caravan' ? resSetCaravan.toFixed(3) : null}
          {location == 'Boat' ? resSetBoat.toFixed(3) : null}  
          {location == 'Motorbike' ? resSetBike.toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>

          </> : null }
          
          {filteredItemsF ? 
          <>
          <TableRow>
            <TableCell style={{borderBottom:'1px solid #202020'}}><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
            <TableCell style={{borderBottom:'1px solid #202020'}}></TableCell>
          </TableRow>

          {filteredItemsF?.map((item, index) => {
            return (
            <TableRow key={index}>
              <TableCell>{item.Category}</TableCell>
              <TableCell>{item.Quantity} x {item.Name}</TableCell>
            </TableRow>
            )
          }
          )}
          
          <TableRow className="totalrow MuiTableRow-footer">
          <TableCell className="MuiTableCell-footer">
          TOTAL
          </TableCell>
          <TableCell className="MuiTableCell-footer totalcell">
          {location == '4x4' ? resSet4x4F.toFixed(3) : null}
          {location == 'Camper' ? resSetCamperF.toFixed(3) : null}
          {location == 'Caravan' ? resSetCaravanF.toFixed(3) : null}
          {location == 'Boat' ? resSetBoatF.toFixed(3) : null}  
          {location == 'Motorbike' ? resSetBikeF.toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>
          </>
          : null }
          </TableBody>
          <TableFooter>
          <TableRow className="packtotal">
          <TableCell style={{textTransform:'uppercase'}}>
          {location} TOTAL
          </TableCell>
          <TableCell className="totalcell">
          {location == '4x4' ? (resSet4x4 + resSet4x4F).toFixed(3) : null}
          {location == 'Camper' ? (resSetCamper + resSetCamperF).toFixed(3) : null}
          {location == 'Caravan' ? (resSetCaravan + resSetCaravanF).toFixed(3) : null}
          {location == 'Boat' ? (resSetBoat + resSetBoatF).toFixed(3) : null}  
          {location == 'Motorbike' ? (resSetBike + resSetBikeF).toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>
          </TableFooter>
      </Table>
    );
  }
  })}
                                </Typography>
                                </CardContent>
                                </Card>
                                </>
                              )
              })}
              </> : 
              <>
              {Object.keys(categorizedDataFoods2).sort().map((key, index) => {
                // ALL OTHER TRIPS - WITHOUT GEAR
                const uniquePackingLocations = [...new Set(categorizedDataFoods2[key]?.items.map(item => item.packingLocation))];
                              return (
                                <>
                                <Card>
                                <CardHeader sx={{ background: '#000', color: '#fff', fontWeight: '900' }} title={key}></CardHeader>
                                <CardContent>
                                <Typography component="div">
                                  {uniquePackingLocations.map((location) => {
  // Filter items for the current packingLocation
  const filteredItemsF = categorizedDataFoods2[key]?.items.filter(item => item.packingLocation === location);
  if (filteredItemsF?.length) {
    return (
      <Table className="finallist" key={location}>
        <TableHead>
          <TableRow>
            <TableCell><b style={{textTransform:'uppercase'}}>FOOD</b></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>

          <>
          {filteredItemsF?.map((item, index) => {
            return (
            <TableRow key={index}>
              <TableCell>{item.Category}</TableCell>
              <TableCell>{item.Quantity} x {item.Name}</TableCell>
            </TableRow>
            )
          }
          )}
          
          <TableRow className="totalrow MuiTableRow-footer">
          <TableCell className="MuiTableCell-footer">
          TOTAL
          </TableCell>
          <TableCell className="MuiTableCell-footer totalcell">
          {location == '4x4' ? resSet4x4F.toFixed(3) : null}
          {location == 'Camper' ? resSetCamperF.toFixed(3) : null}
          {location == 'Caravan' ? resSetCaravanF.toFixed(3) : null}
          {location == 'Boat' ? resSetBoatF.toFixed(3) : null}  
          {location == 'Motorbike' ? resSetBikeF.toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>
          </>
          </TableBody>
          <TableFooter>
          <TableRow className="packtotal">
          <TableCell style={{textTransform:'uppercase'}}>
          {location} TOTAL
          </TableCell>
          <TableCell className="totalcell">
          {location == '4x4' ? (resSet4x4 + resSet4x4F).toFixed(3) : null}
          {location == 'Camper' ? (resSetCamper + resSetCamperF).toFixed(3) : null}
          {location == 'Caravan' ? (resSetCaravan + resSetCaravanF).toFixed(3) : null}
          {location == 'Boat' ? (resSetBoat + resSetBoatF).toFixed(3) : null}  
          {location == 'Motorbike' ? (resSetBike + resSetBikeF).toFixed(3) : null} 
          {getdataT?.WeightPref == true || getdataT?.WeightPref == undefined ? 'kg' : 'lb'}        
          </TableCell>
          </TableRow>
          </TableFooter>
      </Table>
    );
  }
  })}
                                </Typography>
                                </CardContent>
                                </Card>
                                </>
                              )
              })}
              </>
              }
              
    
    </div>
    
    }</>
             )}

            {selectedTab === 999 && (
              <>
              <h4>BUDGET</h4>
              <b style={{fontSize: '14px'}}>GENERAL</b><br/>
              <div className="fields-space">
                <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Camping Fees" value={budgetcamp} name="budgetcamp" onChange={e => setBudgetCamp(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Accommodation" value={budgetaccommodation} name="budgetaccommodation" onChange={e => setBudgetAccommodation(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Food Shopping" value={budgetfood} name="budgetfood" onChange={e => setBudgetFood(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Eating Out" value={budgeteatingout} name="budgeteatingout" onChange={e => setBudgetEatingOut(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Tours" value={budgettours} name="budgettours" onChange={e => setBudgetTours(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Additional Costs" value={budgetadditional} name="budgetadditional" onChange={e => setBudgetAdditional(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Airfares" value={budgetairfares} name="budgetairfares" onChange={e => setBudgetAirfares(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Hire Car" value={budgethirecar} name="budgethirecar" onChange={e => setBudgetHireCar(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" /><br /><br />
          </div> 
          <b style={{fontSize: '14px'}}>FUEL</b><br/>
          <div className="fields-space">
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label={getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? 'Kilometers' : 'Miles'} value={budgetkm} name="budgetkm" onChange={e => setBudgetKM(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          {getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? <>
            <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="L/100km" value={budgetl100} name="budgetl100" onChange={e => setBudgetL100KM(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          </> : <>
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Miles per gallon" value={budgetmpg} name="budgetmpg" onChange={e => setBudgetMPG(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" />
          </>}
          { /* <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label={getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? 'L/100km' : 'M/pg'} value={budgetl100} name="budgetl100" onChange={e => setBudgetL100KM(e.currentTarget.value)} type="number" variant="outlined" /> */ }
          <TextField InputLabelProps={{ shrink: true, }} inputProps={{ inputMode: 'decimal' }} id="standard-basic" label="Estimate Fuel Price" value={budgetfuel} name="budgetfuel" onChange={e => setBudgetFuel(e.currentTarget.value)} onWheel={(e) => e.target.blur()} type="number" variant="outlined" /><br /><br />
          </div>
          <span className="total">General: ${(Number(budgetcamp) + Number(budgetaccommodation) + Number(budgetfood) + Number(budgeteatingout) + Number(budgettours) + Number(budgetadditional) + Number(budgetairfares) + Number(budgethirecar)).toLocaleString("en-US")}</span><br /><br />
          {getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? <>
          <span className="total">Fuel: ${(budgetkm / 100 * budgetl100 * budgetfuel).toLocaleString("en-US")}</span><br /><br /></>
          : <>
          <span className="total">Fuel: ${isNaN(budgetkm / budgetmpg * budgetfuel) ? 0 : (budgetkm / budgetmpg * budgetfuel).toLocaleString("en-US")}</span><br /><br /></>
          }
          
          {getdataT?.DistancePref == true || getdataT?.DistancePref == undefined ? <>
          <span className="total">Total: ${(Number(budgetkm / 100 * budgetl100 * budgetfuel) + Number(budgetcamp) + Number(budgetaccommodation) + Number(budgetfood) + Number(budgeteatingout) + Number(budgettours) + Number(budgetadditional) + Number(budgetairfares) + Number(budgethirecar)).toLocaleString("en-US")}</span><br /><br />
          </> : <>
          <span className="total">Total: ${(isNaN(budgetkm / budgetmpg * budgetfuel) ? 0 : (budgetkm / budgetmpg * budgetfuel) + Number(budgetcamp) + Number(budgetaccommodation) + Number(budgetfood) + Number(budgeteatingout) + Number(budgettours) + Number(budgetadditional) + Number(budgetairfares) + Number(budgethirecar)).toLocaleString("en-US")}</span><br /><br />
              </>
          }
              </>
            )}
            <div className="additem-btn">
            <Button
              disableElevation
              variant="outlined"
              color="default"
              size="large"
              type="submit"
              className="btn blackbtn btnadditem"
              // startIcon={<AddCircle />}
            >
              Add Trip
             </Button>
             </div>
          </form>
        </AccordionDetails >
      </Accordion >
    </div >
  );
};
export default AddItemFormTrips;

const bottomnavtarget = document.querySelector(".MuiBottomNavigation-root");

const ListOfTraivlers = (userinfo, addtraivler, setaddTraverler) => {
  const AddTraiverler = (e, item) => {
    if (e.target.checked) {
      setaddTraverler([...addtraivler, item])
    } else {
      const filter = addtraivler.filter(el => el.Name != item.Name)
      setaddTraverler(filter)
    }   
  }
  return (

    <>
      {userinfo && userinfo?.map((e, index) => (
        <>
          {e?.Travellers?.map(el => {
            const getdata = addtraivler.filter(ele => ele.Name == el.Name ? true : false)
            return (
              <FormControlLabel key={index + e.id} className="avoid mobadjust"
                control={
                  <Checkbox
                    key={`cb-${index}dada`}
                    name={(el.Name) + ' ' + (el.Weight)}
                    value={el.Name}
                    checked={getdata.length ? true : false}
                    id={el.Name}
                    color="primary"
                    onChange={(e) => AddTraiverler(e, el)}
                  />
                }
                label={<span className="mob-radio" style={{ fontWeight: 'bold' }}>{el.Name} { /* <span style={{ fontWeight: 'normal', display: 'inline-block' }}>{el.Weight}kg</span> */ }</span>}
              />
            )
          })}
        </>
      ))}
    </>
  )
}
